import { memoize } from "lodash";
import { CSSProperties } from "react";
import { IColorStyle, RgbRepresentation } from "../types/editor";

export const PROFILE_COLORS = [
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#795548",
  "#607D8B",
];

const MAX_RGBA_VALUE: number = Math.pow(2, 8) - 1;
const convertPercentToRgbaRelative = (percentage: number): number =>
  Math.round((percentage * MAX_RGBA_VALUE) / 100);

export function createColorStyle(
  rgba: RgbRepresentation,
  hex: string,
  fillOpacity = 0.6
): IColorStyle {
  return {
    color: rgba,
    fill: rgba,
    hex,
    fillOpacity,
  };
}

// https://www.30secondsofcode.org/js/s/rgb-to-hsl
export const rgbToHsl = (
  r: number,
  g: number,
  b: number
): [number, number, number] => {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s
    ? l === r
      ? (g - b) / s
      : l === g
      ? 2 + (b - r) / s
      : 4 + (r - g) / s
    : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
};

// https://rgbacolorpicker.com/hex-to-rgba, multiply the A * 100, eg for rgba(1,2,3, 0.31) provide opacity 31
export const addOpacity = (color: string, opacity: number): string =>
  `${color}${Math.round(convertPercentToRgbaRelative(opacity)).toString(16)}`;

function rawHexToRgb(hex: CSSProperties["color"]): RgbRepresentation {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.toLowerCase()
  );

  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return [r, g, b];
  }
  return [0, 0, 0];
}

export const hexToRgb = memoize(rawHexToRgb);

export const hexToCssRgba = (hex: string, opacity: number): string =>
  `rgba(${[...hexToRgb(hex), opacity].join(", ")})`;

// https://www.30secondsofcode.org/js/s/hsl-to-rgb
export const hslToRgb = (
  h: number,
  s: number,
  l: number
): [number, number, number] => {
  s /= 100;
  l /= 100;
  const k = (n: number): number => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number): number =>
    l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return [255 * f(0), 255 * f(8), 255 * f(4)];
};

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
const rgbToHex = (r: number, g: number, b: number): string =>
  "#" +
  [r, g, b]
    .map(Math.round)
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");

export const adjustColorStyleLightness = (
  colorStyle: IColorStyle,
  lightnessChange: number
): IColorStyle => {
  const { hex, fillOpacity } = colorStyle;

  const rgb: [number, number, number] = hexToRgb(hex);
  const hsl = rgbToHsl(...rgb);

  hsl[2] = Math.min(Math.max(hsl[2] + lightnessChange, 0), 100);

  const adjustedRgb = hslToRgb(...hsl);
  const adjustedHex = rgbToHex(...adjustedRgb);

  return createColorStyle(adjustedRgb, adjustedHex, fillOpacity);
};

// https://stackoverflow.com/a/7616484/4722895
export const hashString = (str: string) => {
  if (!str?.length) return 0;

  return str.split("").reduce((hash, char) => {
    return ((hash << 5) - hash + char.charCodeAt(0)) | 0;
  }, 0);
};

const _hashToRgb = (str: string) => {
  const hash = hashString(str);

  const colorIndex = Math.abs(hash) % PROFILE_COLORS.length;
  return PROFILE_COLORS[colorIndex];
};

export const hashToRgb = memoize(_hashToRgb);
