import kinks from "@turf/kinks";
import turfIntersect from "@turf/intersect";
import turfUnion from "@turf/union";

import { generateId } from "../../utils/string";
import { head } from "../../utils/functional/list";
import { GEOJSON_TYPES } from "../../consts/editor";
import { CommonDrawMode } from "../draw/common-draw";
import ImmutableLayersData, { makeFeature } from "../base/ImmutableLayersData";
import { getCleanedShapes, getIntersectFeatures } from "./utils";

export class MergeMode extends CommonDrawMode {
  handleAddFeature = (_event: any, props: any) => {
    const clickSequence = this.getClickSequence();

    if (clickSequence?.length > 2) {
      const mainCoordinates = this.mainFeature?.properties?.closed
        ? this.mainFeature.geometry.coordinates[0]
        : clickSequence;
      const coordinates: any = [[...mainCoordinates, head(mainCoordinates)]];

      const feature: any = {
        type: GEOJSON_TYPES.Feature,
        properties: {
          arcs: this._arcs,
        },
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates,
        },
      };

      const featureKinks = kinks(feature);
      const isEdgeIntersection = featureKinks?.features?.length > 1;

      if (
        (isEdgeIntersection && !this.mainFeature?.properties?.closed) ||
        this._lock
      ) {
        return;
      }

      this.applyMergeAction(feature, props);

      this._resetState();
    }
  };

  applyMergeAction = (feature: any, props: any): any => {
    const { geoJson, view, colorMap } = props.modeConfig;

    const selectedFeatures = props.data.features;
    const intersectedFeatures = getIntersectFeatures(feature, props);

    if (!intersectedFeatures?.length) return null;

    const properties = intersectedFeatures[0].properties;

    let leftFeatures = [];
    let mergedFeature = feature;

    for (const selectedFeature of intersectedFeatures) {
      this.deleteFeatures([selectedFeature]);

      if (selectedFeature.geometry.type === GEOJSON_TYPES.MultiPolygon) {
        const cleanedShapes = getCleanedShapes([selectedFeature]);
        for (const cleanedShape of cleanedShapes) {
          const isIntersect = turfIntersect(
            cleanedShape as any,
            mergedFeature as any
          );

          if (isIntersect) {
            const union = turfUnion(feature, cleanedShape);

            if (union) {
              if (union.geometry.type === GEOJSON_TYPES.MultiPolygon) {
                mergedFeature.geometry.coordinates =
                  union.geometry.coordinates.flat();
              } else {
                mergedFeature.geometry.coordinates = union.geometry.coordinates;
              }
            }
          } else {
            leftFeatures.push({
              ...cleanedShape,
              properties: {
                ...selectedFeature.properties,
                id: generateId(),
              },
            });
          }
        }
      }

      if (selectedFeature.geometry.type === GEOJSON_TYPES.Polygon) {
        const union = turfUnion(feature, selectedFeature);

        if (union) {
          if (union.geometry.type === GEOJSON_TYPES.MultiPolygon) {
            mergedFeature.geometry.coordinates =
              union.geometry.coordinates.flat();
          } else {
            mergedFeature.geometry.coordinates = union.geometry.coordinates;
          }
        }
      }
    }

    let newFeatures = [
      ...leftFeatures,
      {
        ...mergedFeature,
        properties: {
          ...properties,
          id: generateId(),
        },
      },
    ];

    newFeatures = newFeatures.map((newFeature) =>
      makeFeature({
        geometry: newFeature.geometry,
        id: newFeature.properties.id,
        name: newFeature.properties.name,
        view,
        clMap: colorMap,
        className: newFeature.properties.className,
        featureProperties: newFeature.properties,
      })
    );
    // add fix holes

    this.addFeatures(newFeatures);
    this.applyActions(props, true, {
      ignoreHover: true,
    });
  };
}
