import { generateId } from "../../utils/string";
import { CommonDrawMode } from "./common-draw";
import { head } from "../../utils/functional/list";
import { GEOJSON_TYPES } from "../../consts/editor";
import { makeMarkupFeature } from "../base/ImmutableLayersData";
import { getMarkupFeatureContextProps } from "../../utils/geometry";

export class DrawLineMarkupMode extends CommonDrawMode {
  handleAddFeature = (_event: any, props: any) => {
    const features = props.data.features;
    const clickSequence = this.getClickSequence();

    if (clickSequence.length > 0) {
      const coordinates: any = this?.mainFeature?.geometry?.coordinates;
      const cleanedCoords =
        coordinates[0]?.length && Array.isArray(head(coordinates[0]))
          ? coordinates[0]
          : coordinates;

      const markupFeatures = [];

      for (const idx in cleanedCoords) {
        const index = Number(idx);
        if (index > 0) {
          const newId = generateId();

          const options = props?.modeConfig?.options;

          const feature = {
            type: GEOJSON_TYPES.Feature,
            properties: {
              text: options.text,
              size: options.size.default,
              color: options.color.default,
              border: options.border.default,
              thinkness: options?.thinkness?.default || 0,
              opacity: options.color.default.fillOpacity === 0 ? 0 : 50,
            },
            geometry: {
              type: GEOJSON_TYPES.LineString,
              coordinates: [cleanedCoords[index - 1], cleanedCoords[index]],
            },
          };

          const markupFeature = makeMarkupFeature(
            feature,
            "",
            props.modeConfig.modeId
          );

          markupFeatures.push(markupFeature);
        }
      }

      const contextProps = getMarkupFeatureContextProps(
        markupFeatures[0],
        props.modeConfig.deck.current.deck
      );
      if (contextProps) {
        props.modeConfig.setDefaultMode();
        props.modeConfig.setSelected(contextProps.selections);
      }

      this.addFeatures(markupFeatures);
      this.applyActions(props, true, {
        contextProps,
      });

      this._resetState();
    }
  };
}
