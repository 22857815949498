import React from "react";

export const SEARCH_ICONS_IDS = {
  arrow: "search_arrow",
  delete: "search_delete",
  chevron: "search_chevron",
};

export const SEARCH_ICONS = {
  [SEARCH_ICONS_IDS.arrow]: {
    width: "25",
    height: "24",
    color: "#1C2931",
    viewBox: "0 0 25 24",
    icon: ArrowIcon,
  },

  [SEARCH_ICONS_IDS.chevron]: {
    width: "16",
    height: "16",
    color: "#1C2931",
    viewBox: "0 0 16 16",
    icon: ChevronIcon,
  },
  [SEARCH_ICONS_IDS.delete]: {
    width: "20",
    height: "20",
    color: "#1C2931",
    viewBox: "0 0 20 20",
    icon: DeleteIcon,
  },
};

export function ArrowIcon({ color = "#1C2931" }) {
  return (
    <>
      <path
        d="M4.75683 11.9805H19.7468"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7598 5.98828L19.7968 12.0003L13.7598 18.0123"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function ChevronIcon({ color = "#1C2931" }) {
  return (
    <>
      <path
        d="M5.00333 2L11 8.018L5 14"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function DeleteIcon({ color = "#1C2931" }) {
  return (
    <>
      <path
        d="M15 5.44401H15.8333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50065 8.72656V13.7766"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0007 7.75781V14.6495"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5007 8.72656V13.7766"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1625 17.0614H6.8375C5.8225 17.0614 5 16.2389 5 15.2239V5.44141H15V15.2239C15 16.2389 14.1775 17.0614 13.1625 17.0614Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3337 5.44141L12.7187 3.52057C12.6078 3.17557 12.287 2.94141 11.9253 2.94141H8.07533C7.71283 2.94141 7.39199 3.17557 7.28199 3.52057L6.66699 5.44141"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16699 5.44401H5.00033"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}
