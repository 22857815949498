import _ from "lodash";
import bbox from "@turf/bbox";
import { Viewport } from "@deck.gl/core";
import bboxPolygon from "@turf/bbox-polygon";
import nearestPointOnLine from "@turf/nearest-point-on-line";
import {
  point,
  featureCollection,
  lineString as toLineString,
} from "@turf/helpers";

import {
  MODE_SPHERE,
  MODE_RECTANGLE,
  DEFAULT_PADDING,
} from "../../consts/coordinates";
import polygonToLine from "../../utils/turf/polygon-to-line";
import { GEOJSON_TYPES, MODES_IDS } from "../../consts/editor";

import {
  Point,
  Feature,
  Polygon,
  LineString,
  MultiPolygon,
  MultiLineString,
} from "geojson";

import {
  getEditHandlesForFeature,
  getPickedExistingEditHandle,
  nearestPointOnProjectedLine,
  recursivelyTraverseNestedArrays,
} from "../../utils/modes";

import {
  calculateDistance,
  makeArrowFromLine,
  getMarkupLineCoords,
  getCloudPolygonCoordinates,
  getHessianLineIntersection,
  getHessianLineFromLineAndPoint,
  getHessianLineDefinitionFromPoints,
} from "../../utils/coordinates";

export function calculateTranslationDataForDefaultMode(
  event: any,
  properties: any
) {
  const isShiftKey = event?.sourceEvent?.shiftKey;

  let diffX = event.mapCoords[0] - event.pointerDownMapCoords[0];
  let diffY = event.mapCoords[1] - event.pointerDownMapCoords[1];

  if (isShiftKey) {
    if (Math.abs(diffX) > Math.abs(diffY)) {
      diffY = 0;
    }
    if (Math.abs(diffY) > Math.abs(diffX)) {
      diffX = 0;
    }
  }

  const beforeX = properties?.beforePGeo?.length ? properties.beforePGeo[0] : 0;
  const beforeY = properties?.beforePGeo?.length ? properties.beforePGeo[1] : 0;

  const afterX = properties?.afterPGeo?.length ? properties.afterPGeo[0] : 0;
  const afterY = properties?.afterPGeo?.length ? properties.afterPGeo[1] : 0;

  const beforePTranslation = [diffX + beforeX, diffY + beforeY];
  const afterPTranslation = [diffX + afterX, diffY + afterY];

  return { beforePTranslation, afterPTranslation };
}

export function calculateTranslationDataForLinePreserve(
  event: any,
  properties: any
) {
  const { beforePGeo, afterPGeo, beforeBeforePGeo, afterAfterPGeo } =
    properties;

  const beforeLine = getHessianLineDefinitionFromPoints(
    beforeBeforePGeo,
    beforePGeo
  );
  const afterLine = getHessianLineDefinitionFromPoints(
    afterAfterPGeo,
    afterPGeo
  );
  const edgeLine = getHessianLineDefinitionFromPoints(beforePGeo, afterPGeo);
  const newEdgeLine = getHessianLineFromLineAndPoint(edgeLine, event.mapCoords);

  const beforePTranslation = getHessianLineIntersection(
    beforeLine,
    newEdgeLine
  );
  const afterPTranslation = getHessianLineIntersection(afterLine, newEdgeLine);
  return { beforePTranslation, afterPTranslation };
}

export function prepareIntermediateHandleDraggingData(
  event: any,
  intermediateHandle: any,
  features: Feature<MultiLineString | Polygon | MultiPolygon>[],
  shouldPreserveLines: boolean
) {
  const { positionIndexes, featureIndex } = intermediateHandle.properties;

  const feature = features[featureIndex];

  const beforePoint = [positionIndexes[0], positionIndexes[1] - 1] || [0, 0];
  let afterPoint = [positionIndexes[0], positionIndexes[1]] || [0, 0];

  const beforePointGeo =
    feature?.geometry?.coordinates?.[beforePoint[0]][beforePoint[1]];
  let afterPointGeo =
    feature?.geometry?.coordinates?.[afterPoint[0]][afterPoint[1]];

  if (!afterPointGeo) {
    afterPoint = [positionIndexes[0], 0];

    afterPointGeo =
      feature?.geometry?.coordinates[afterPoint[0]][afterPoint[1]];
  }

  intermediateHandle.properties.beforePGeo = beforePointGeo;
  intermediateHandle.properties.afterPGeo = afterPointGeo;

  if (shouldPreserveLines) {
    const numberOfVertices =
      feature?.geometry?.coordinates[positionIndexes[0]].length;

    const beforeBeforeBeforePoint = [
      positionIndexes[0],
      (positionIndexes[1] - 3 + numberOfVertices) % (numberOfVertices - 1),
    ];

    const afterAfterPoint = [
      positionIndexes[0],
      (positionIndexes[1] + 1) % (numberOfVertices - 1),
    ];

    const beforeBeforePointGeo =
      feature?.geometry?.coordinates[beforeBeforeBeforePoint[0]][
        beforeBeforeBeforePoint[1]
      ];

    const afterAfterPointGeo =
      feature?.geometry?.coordinates[afterAfterPoint[0]][afterAfterPoint[1]];

    intermediateHandle.properties.beforeBeforePGeo = beforeBeforePointGeo;
    intermediateHandle.properties.afterAfterPGeo = afterAfterPointGeo;
    intermediateHandle.properties.shouldPreserveLines = shouldPreserveLines;
  }
}

function getMarkupEditGuides(props: any, context: any) {
  context._isMarkupMode = true;
  context._cornerGuidePoints = [];
  const selectedGeometry =
    context.getSelectedFeaturesAsFeatureCollection(props);

  const boundingBox = bboxPolygon(bbox(selectedGeometry));
  boundingBox.properties.mode = "scale";
  boundingBox.properties.featureIndex = 0;
  const cornerGuidePoints: any[] = [];

  boundingBox.geometry.coordinates[0].forEach(
    (coord: any, coordIndex: number) => {
      if (coordIndex < 4) {
        // Get corner midpoint guides from the enveloping box
        const cornerPoint = point(coord, {
          guideType: "editHandle",
          editHandleType: "scale",
          positionIndexes: [coordIndex],
          featureIndex: 0,
        });
        cornerGuidePoints.push(cornerPoint);
      }
    }
  );

  if (cornerGuidePoints.length !== 4) return;
  context._cornerGuidePoints = cornerGuidePoints;

  return featureCollection([
    polygonToLine(boundingBox),
    ...context._cornerGuidePoints,
  ]);
}

function getSelectedGeometryGuides(
  features: any,
  selectedIndexes: any,
  existingEditHandle: any
) {
  let handles = [];

  for (const index of selectedIndexes) {
    if (index < features.length) {
      const { geometry, properties } = features[index];
      if (geometry.type !== GEOJSON_TYPES.Point) {
        handles.push(
          ...getEditHandlesForFeature(
            features[index],
            index,
            "existing",
            false,
            properties?.id
          )
        );
      }
    } else {
      console.warn(`selectedFeatureIndexes out of range ${index}`); // eslint-disable-line no-console
    }
  }

  if (existingEditHandle) {
    handles = handles.map((h) => {
      if (
        _.isEqual(
          h.properties.positionIndexes,
          existingEditHandle.properties.positionIndexes
        )
      ) {
        h.properties.state = "hovered";
        return h;
      }
      return h;
    });
  }

  return handles;
}

// turf.js does not support elevation for nearestPointOnLine
export function handleNearestPointOnLine(
  line: Feature<LineString>,
  inPoint: Feature<Point>,
  viewport: Viewport,
  minDistance = 1.5
) {
  const { coordinates } = line.geometry;
  if (coordinates.some((coord: any) => coord.length > 2)) {
    if (viewport) {
      return nearestPointOnProjectedLine(line, inPoint, viewport);
    }
  }

  const padding = DEFAULT_PADDING;
  const paddedLine = {
    ...line,
    geometry: {
      ...line.geometry,
      coordinates: line.geometry.coordinates.map((coord: any) => [
        coord[0] / padding,
        coord[1] / padding,
      ]),
    },
  };

  const paddedPoint = {
    ...inPoint,
    geometry: {
      ...inPoint.geometry,
      coordinates: [
        inPoint.geometry.coordinates[0] / padding,
        inPoint.geometry.coordinates[1] / padding,
      ],
    },
  };

  let nearest = nearestPointOnLine(paddedLine, paddedPoint);

  nearest.geometry.coordinates = [
    nearest.geometry.coordinates[0] * padding,
    nearest.geometry.coordinates[1] * padding,
  ];

  if (nearest.properties.dist < minDistance) {
    return {
      ...nearest,
      properties: {
        ...nearest.properties,
        parentId: line.properties.id,
      },
    };
  }
}

function getIntermediatePoint(props: any, existingEditHandle: any) {
  const { lastPointerMoveEvent, selectedIndexes, modeConfig } = props;
  const picks = lastPointerMoveEvent && lastPointerMoveEvent.picks;
  const mapCoords = lastPointerMoveEvent && lastPointerMoveEvent.mapCoords;

  if (picks && picks.length && mapCoords) {
    const featureAsPick =
      !existingEditHandle && picks.find((pick: any) => !pick.isGuide);

    let intermediatePoint: Feature<Point> = null;
    let positionIndexPrefix = [];

    if (
      featureAsPick &&
      !featureAsPick.object.geometry.type.includes(GEOJSON_TYPES.Point) &&
      selectedIndexes.includes(featureAsPick.index)
    ) {
      const referencePoint = point(mapCoords);

      recursivelyTraverseNestedArrays(
        featureAsPick.object.geometry.coordinates,
        [],
        (lineString, prefix) => {
          if (lineString.length > 1) {
            const lineStringFeature = toLineString(lineString);

            const candidateIntermediatePoint = handleNearestPointOnLine(
              lineStringFeature,
              referencePoint,
              modeConfig && modeConfig.viewport
            );

            if (
              !intermediatePoint ||
              (candidateIntermediatePoint &&
                candidateIntermediatePoint.properties.dist <
                  intermediatePoint.properties.dist)
            ) {
              intermediatePoint = candidateIntermediatePoint;
              positionIndexPrefix = prefix;
            }
          }
        }
      );
    }

    if (!intermediatePoint && !existingEditHandle && picks.length) {
      const intermediatePick = picks.find((pick: any) =>
        selectedIndexes.includes(pick.object.properties.featureIndex)
      );
      if (intermediatePick) {
        intermediatePoint = {
          ...intermediatePick.object,
          properties: {
            ...intermediatePick.object.properties,
            index: intermediatePick.object.properties.positionIndexes[1] - 1,
          },
        };

        positionIndexPrefix =
          intermediatePick.object.properties.positionIndexes;
      }
    }

    if (intermediatePoint) {
      const {
        geometry: { coordinates: position },
        properties: { index },
      } = intermediatePoint;

      return {
        type: GEOJSON_TYPES.Feature,
        properties: {
          guideType: "editHandle",
          editHandleType: "intermediate",
          featureIndex: featureAsPick
            ? featureAsPick.index
            : intermediatePoint.properties.featureIndex,
          positionIndexes: featureAsPick
            ? [...positionIndexPrefix, index + 1]
            : positionIndexPrefix,
        },
        geometry: {
          type: GEOJSON_TYPES.Point,
          coordinates: position,
        },
      };
    }
  }

  return null;
}

export function getEditModeGuides(props: any, context: any) {
  const { data, lastPointerMoveEvent, selectedIndexes } = props;

  if (selectedIndexes.length === 0) return;

  const { features } = data;
  const picks = lastPointerMoveEvent && lastPointerMoveEvent.picks;

  const isLine =
    selectedIndexes.length > 0 &&
    (features[selectedIndexes[0]].geometry.type === GEOJSON_TYPES.LineString ||
      features[selectedIndexes[0]].geometry.type ===
        GEOJSON_TYPES.MultiLineString);
  const isMarkup =
    selectedIndexes.length === 1 &&
    features[selectedIndexes[0]].properties.types.includes(
      GEOJSON_TYPES.markup
    ) &&
    !features[selectedIndexes[0]].properties.types.includes(
      GEOJSON_TYPES.dimensionLine
    ) &&
    !features[selectedIndexes[0]].properties.types.includes(
      MODES_IDS.markup_line
    );

  if (isMarkup) return getMarkupEditGuides(props, context);

  context._isMarkupMode = false;

  let handles = [];
  const existingEditHandle = getPickedExistingEditHandle(picks);

  handles.push(
    ...getSelectedGeometryGuides(features, selectedIndexes, existingEditHandle)
  );

  if (isLine) {
    return {
      type: GEOJSON_TYPES.FeatureCollection,
      features: handles,
    };
  }

  const intermediatePoint = getIntermediatePoint(props, existingEditHandle);

  if (intermediatePoint) {
    const intermediateHandleFeature =
      features[intermediatePoint.properties.featureIndex];
    const handleIndex =
      intermediatePoint.properties.positionIndexes[
        intermediatePoint.properties.positionIndexes.length - 1
      ];

    const isArcPoint =
      (intermediateHandleFeature.properties?.arcs || [])
        .flatMap((arc: any) => [arc.endPointIdx, ...arc.ignoreIndices])
        .includes(handleIndex) ||
      (intermediateHandleFeature.properties?.arcs || []).filter((arc: any) =>
        [MODE_RECTANGLE, MODE_SPHERE].includes(arc?.type)
      ).length > 0;

    if (!isArcPoint) {
      handles.unshift(intermediatePoint);
    }
  }

  return {
    type: GEOJSON_TYPES.FeatureCollection,
    features: handles,
  };
}

export function getEditModesSnapFeatures(props: any, context: any) {
  if (
    !context._isMarkupMode &&
    context._isDragging &&
    context._isEditHandle &&
    !context._isGeometryTranslate &&
    !context._isEdgeTranslate
  ) {
    const {
      lastPointerMoveEvent,
      modeConfig: { isSnapOn, snapDistance },
    } = props;

    const lastCoords = lastPointerMoveEvent.mapCoords;
    const snapFeatures = [];
    const isAltCtrl =
      lastPointerMoveEvent?.sourceEvent.ctrlKey ||
      lastPointerMoveEvent?.sourceEvent.metaKey;

    let sortedSnapTargets =
      context?.indexedTargets && lastCoords?.length > 0
        ? context.indexedTargets.within(lastCoords[0], lastCoords[1], 500, 50)
        : [];

    sortedSnapTargets = sortedSnapTargets
      .map((target: any) =>
        target?.properties?.id !== context._dragginFeature?.properties?.id
          ? {
              ...target,
              properties: {
                ...target.properties,
                editHandleType: "snap",
                guideType: "snap",
              },
            }
          : null
      )
      .filter(Boolean);

    let closest = sortedSnapTargets.length > 0 ? sortedSnapTargets[0] : null;

    const shouldSnapMultiplePoints = context._closestPoints?.every(
      (pt: any) => pt?.properties?.id !== closest?.properties?.id
    );

    if (isSnapOn || isAltCtrl) {
      if (
        closest?.geometry?.coordinates &&
        closest?.properties?.dist < snapDistance &&
        shouldSnapMultiplePoints
      ) {
        context._snapped = closest;
        snapFeatures.push(closest);
      } else {
        context._snapped = null;
        for (const closestTarget of sortedSnapTargets) {
          snapFeatures.push(closestTarget);
        }
      }
    }

    return snapFeatures;
  }

  context._snapped = null;
  return [];
}

export function handleFinishMarkupEdit(
  features: any,
  props: any,
  shouldUpdateCloud = false
) {
  const allFeatures = props.modeConfig.geoJson;

  const cleanedFeatures: any = [];

  const featuresWithBbox = features.map((feature: any) => {
    if (feature.properties.types.includes(GEOJSON_TYPES.markup)) {
      try {
        const bboxFeature = bboxPolygon(bbox(feature));

        const featureGeometry: any = feature?.geometry;

        const isArrow = feature?.properties?.types?.includes(
          MODES_IDS.markup_arrow
        );
        const isCloud = feature?.properties?.isCloud;

        if (
          shouldUpdateCloud &&
          isCloud &&
          bboxFeature?.geometry?.coordinates
        ) {
          const bboxCoordinates = bboxFeature.geometry.coordinates;
          const cloudCoordinates = getCloudPolygonCoordinates(bboxCoordinates);
          if (cloudCoordinates) {
            featureGeometry.coordinates = cloudCoordinates;
          }
        }

        if (isArrow) {
          const arrowlineCoords = featureGeometry.coordinates[0];
          const p1 = arrowlineCoords[0];
          const p2 = arrowlineCoords[1];
          const distance = calculateDistance(p1, p2);
          const arrowSize = Math.max(Math.min(distance / 10, 400), 40);
          const arrowLineFeature = {
            ...feature,
            geometry: {
              type: GEOJSON_TYPES.LineString,
              coordinates: [p1, p2],
            },
          };
          const arrowFeature: any = makeArrowFromLine(
            arrowLineFeature,
            45,
            arrowSize
          );
          if (arrowFeature?.geometry?.coordinates?.length > 0) {
            featureGeometry.coordinates = arrowFeature.geometry.coordinates;
          }
        }

        return {
          ...feature,
          properties: {
            ...feature.properties,
            bboxFeature,
          },
          geometry: featureGeometry,
        };
      } catch (e) {
        return feature;
      }
    }
    return feature;
  });

  featuresWithBbox.forEach((feature: any) => {
    if (feature?.properties?.correspondingLineId) {
      const lineFeature = allFeatures.find(
        (f: any) => f.properties.id === feature.properties.correspondingLineId
      );
      if (lineFeature) {
        if (
          !!lineFeature.properties.correspondingBoxId &&
          !!lineFeature.properties.correspondingTextId
        ) {
          const textFeature =
            feature.properties.id === lineFeature.properties.correspondingTextId
              ? feature
              : allFeatures.find(
                  (f: any) =>
                    f.properties.id ===
                    lineFeature.properties.correspondingTextId
                );
          const boxFeature =
            feature.properties.id === lineFeature.properties.correspondingBoxId
              ? feature
              : allFeatures.find(
                  (f: any) =>
                    f.properties.id ===
                    lineFeature.properties.correspondingBoxId
                );

          const boxCoords = boxFeature.properties.bboxFeature
            ? boxFeature.properties.bboxFeature.geometry.coordinates[0]
            : boxFeature.geometry.coordinates[0];
          const textCoords = textFeature.properties.bboxFeature
            ? textFeature.properties.bboxFeature.geometry.coordinates[0]
            : textFeature.geometry.coordinates[0];

          const linePoint1 = lineFeature.geometry.coordinates[0];
          const linePoint2 = lineFeature.geometry.coordinates[1];

          const newLinePoint1 = getMarkupLineCoords(boxCoords, linePoint2);
          const newLinePoint2 = getMarkupLineCoords(textCoords, linePoint1);

          cleanedFeatures.push(feature);
          if (newLinePoint1 && newLinePoint2) {
            const newLineFeature = {
              ...lineFeature,
              geometry: {
                ...lineFeature.geometry,
                coordinates: [newLinePoint1, newLinePoint2],
              },
            };
            cleanedFeatures.push(newLineFeature);
          }
        } else {
          const linePoint = lineFeature.geometry.coordinates[1];
          const featureCoords = feature?.properties?.bboxFeature
            ? feature?.properties?.bboxFeature?.geometry?.coordinates[0]
            : feature.geometry.coordinates[0];

          const newLinePoint = getMarkupLineCoords(featureCoords, linePoint);
          cleanedFeatures.push(feature);

          if (newLinePoint) {
            const newLineFeature = {
              ...lineFeature,
              geometry: {
                ...lineFeature.geometry,
                coordinates: [
                  newLinePoint,
                  lineFeature.geometry.coordinates[1],
                ],
              },
            };
            cleanedFeatures.push(newLineFeature);
          }
        }
      } else {
        cleanedFeatures.push(feature);
      }
    } else {
      cleanedFeatures.push(feature);
    }
  });

  return cleanedFeatures;
}
