import GeoJsonEditMode from "../base/GeojsonEditMode";
import { CLASSIFICATION_TYPES, GEOJSON_TYPES } from "../../consts/editor";

export class DrawPointMode extends GeoJsonEditMode {
  createTentativeFeature(props: any) {
    const { lastPointerMoveEvent } = props;
    const lastCoords = lastPointerMoveEvent
      ? [lastPointerMoveEvent.mapCoords]
      : [];

    return {
      type: GEOJSON_TYPES.Feature,
      properties: {
        guideType: "tentative",
      },
      geometry: {
        type: GEOJSON_TYPES.Point,
        coordinates: lastCoords[0],
      },
    };
  }

  handleClick = ({ mapCoords }: any, props: any) => {
    const {
      view,
      modeId,
      colorMap,
      activeClassification,
      filteredtakeOffTypes,
    } = props.modeConfig;

    const features = props.data.features;

    const geometry = {
      type: GEOJSON_TYPES.Point,
      coordinates: mapCoords,
    };

    const featureClassification =
      activeClassification &&
      activeClassification?.id &&
      activeClassification &&
      activeClassification.type === CLASSIFICATION_TYPES[2]
        ? activeClassification.id
        : null;

    const pointFeatures = this.getAddFeatureAction(
      geometry,
      features,
      view,
      filteredtakeOffTypes,
      featureClassification,
      modeId,
      props.featureAccessKey,
      colorMap
    );

    if (pointFeatures?.updatedData?.features?.length > 0) {
      this.addFeatures(pointFeatures.updatedData.features);
      this.applyActions(props, true);
    }
  };

  handlePointerMove(_event: any, props: any) {
    props.onUpdateCursor("crosshair");
  }
}
