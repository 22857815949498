import { useCallback, useRef } from "react";

import {
  getList,
  syncList,
  syncItem,
  pushAction,
  getLocalList,
} from "../dataUtils";
import {
  STORE_ADD,
  STORE_SET,
  STORE_RESET,
  STORE_DELETE,
} from "src/state/dataStore";
import { generateId } from "sf/utils/string";
import { getLocalItem, getLocalItems } from "../localForageUtils";

interface IDataHook {
  clearData: () => void | Promise<void>;
  getFullItem: (id: string) => any | Promise<any>;
  syncData: (id?: string) => void | Promise<void>;
  getFullItems: (ids: string[]) => void | Promise<any[]>;
  getData: (id?: string, offset?: number) => void | Promise<void>;
  syncSingleItem: (
    item: any,
    apiProps: any,
    cleanFuncProps: any,
    isAdd?: boolean,
    isRemove?: boolean,
    isLocalOnly?: boolean
  ) => void | Promise<void>;
}

interface IDataStore {
  getItemValue: (item: any) => any;
  resetData: () => void | Promise<void>;
  setDataError: (error: any) => void | Promise<void>;
  setDataIsLoading: (isLoading: boolean) => void | Promise<void>;
}

interface IDataHookCallbacks {
  onLoad?: () => void | Promise<void>;
  onReset?: () => void | Promise<void>;
  onError?: (error: any) => void | Promise<void>;
  onSuccess?: (data: any) => void | Promise<void>;
}

interface IDataHookProps {
  dataKey: string;
  useIdSync?: boolean;
  pendingData: React.MutableRefObject<any[]>;

  getApiProps?: (id: string) => any;
  getLocalApiProps?: (id: string) => any;
  handleCleanData?: (data: any[], props: any) => any[];

  store: IDataStore;
  callbacks?: IDataHookCallbacks;
}

export function useDataHook(props: IDataHookProps): IDataHook {
  const {
    store,
    dataKey,
    pendingData,
    getApiProps = null,
    handleCleanData = null,
    getLocalApiProps = null,
    useIdSync = false,
    callbacks = {
      onLoad: undefined,
      onReset: undefined,
      onError: undefined,
      onSuccess: undefined,
    },
  } = props;

  const { onReset, onLoad } = callbacks;
  const { resetData, setDataError, getItemValue, setDataIsLoading } = store;

  const currentRunId = useRef<string | null>(null);
  const currentSyncId = useRef<string | null>(null);

  const clearData = useCallback(async () => {
    await resetData?.();

    pushAction(pendingData, {
      dataKey,
      data: [],
      action: STORE_RESET,
    });

    await onReset?.();
  }, [onReset, setDataIsLoading]);

  const syncData = useCallback(
    async (id: string, cleanFuncProps = {}) => {
      const syncId = generateId();
      currentSyncId.current = syncId;

      const res = await syncList({
        key: dataKey,
        pendingData,
        filterValue: id,

        apiProps: getApiProps?.(id) || {},
        localApiProps: getLocalApiProps?.(id) || null,

        cleanFuncProps,
        cleanFunc: handleCleanData,

        runId: syncId,
        currentRunId: currentSyncId,
      });

      if (res?.ids?.size > 0 && currentSyncId.current === syncId) {
        pushAction(pendingData, {
          dataKey,
          filterValue: id,
          data: [...res.ids],
          action: STORE_DELETE,
        });
      }
    },
    [dataKey, pendingData, handleCleanData, getApiProps, getLocalApiProps]
  );

  const getData = useCallback(
    async (id: string, cleanFuncProps = {}) => {
      const offset = 0;
      const runId = generateId();
      currentRunId.current = runId;

      setDataIsLoading(true);

      let localDataCount = 0;

      const localRes = await getLocalList({
        key: dataKey,
        cleanFuncProps,
        cleanFunc: handleCleanData,
        localApiProps: getLocalApiProps?.(id) || null,
      });

      if (localRes?.data?.length) {
        if (currentRunId.current === runId) {
          localDataCount = localRes.data.length;
          pushAction(pendingData, {
            dataKey,
            filterValue: id,
            action: STORE_SET,
            data: localRes.data,
          });
        }
      }

      let hasNext = true;
      let nextOffset = offset;

      if (currentRunId.current === runId) {
        while (hasNext) {
          const res = await getList({
            key: dataKey,
            offset: nextOffset,
            ignoreLocal: nextOffset > 0,

            runId,
            pendingData,
            currentRunId,
            localDataCount,
            filterValue: id,

            apiProps: getApiProps?.(id) || {},
            localApiProps: getLocalApiProps?.(id) || null,

            cleanFuncProps,
            cleanFunc: handleCleanData,
          });

          if (res?.local) {
            if (res?.data?.length && currentRunId.current === runId) {
              pushAction(pendingData, {
                dataKey,
                data: res.data,
                filterValue: id,
                action: STORE_SET,
              });
            }

            if (currentRunId.current === runId) {
              await syncData(id, cleanFuncProps);
            }

            hasNext = false;
          } else {
            if (res?.error && currentRunId.current === runId) {
              setDataError(res.error);
            }

            if (res?.data?.length && currentRunId.current === runId) {
              hasNext = !!res.next;
              nextOffset = res?.next?.offset;
            } else {
              hasNext = false;
            }
          }
        }
      }

      if (currentRunId.current === runId) {
        await onLoad?.();
        setDataIsLoading(false);
      }
    },
    [
      dataKey,
      useIdSync,
      pendingData,
      onLoad,
      getApiProps,
      setDataError,
      handleCleanData,
      setDataIsLoading,
      getLocalApiProps,
    ]
  );

  const syncSingleItem = useCallback(
    async ({
      item,
      isAdd = false,
      isRemove = false,
      isLocalOnly = true,
      isIgnoreState = false,
      cleanFuncProps = {},
    }) => {
      if (!item?.id) {
        return null;
      }

      const commonApiProps = getApiProps?.(item.id) || {};

      const apiProps = commonApiProps?.$attributes
        ? {
            $attributes: commonApiProps.$attributes,
          }
        : null;

      const updatedItem = await syncItem({
        item,
        id: item.id,
        key: dataKey,
        isAdd,
        isRemove,
        isLocalOnly,
        apiProps,
        cleanFuncProps,
        cleanFunc: handleCleanData,
        newValue: isAdd ? getItemValue(item) : null,
      });

      if (isRemove) {
        if (!isIgnoreState) {
          pushAction(pendingData, {
            dataKey,
            data: [item],
            action: STORE_DELETE,
          });
        }
        return updatedItem;
      }

      if (!updatedItem) {
        return updatedItem;
      }

      if (!isIgnoreState) {
        pushAction(pendingData, {
          dataKey,
          action: STORE_ADD,
          data: [updatedItem],
        });
      }

      return updatedItem;
    },
    [dataKey, pendingData, handleCleanData, getItemValue]
  );

  async function getFullItem(item: any, cleanFuncProps = {}) {
    if (!item?.id) return null;

    try {
      const itemFromCache = await getLocalItem(dataKey, item.id);
      if (itemFromCache) {
        if (!!handleCleanData) {
          const cleanedItem = handleCleanData(
            {
              ...itemFromCache,
              ...item,
            },
            cleanFuncProps
          );

          return cleanedItem;
        }

        return {
          ...itemFromCache,
          ...item,
        };
      }

      const itemSynced = await syncSingleItem({ item, isLocalOnly: false });

      if (!!handleCleanData) {
        const cleanedItem = handleCleanData(
          {
            ...item,
            ...itemSynced,
          },
          cleanFuncProps
        );

        return cleanedItem;
      }

      return {
        ...item,
        ...itemSynced,
      };
    } catch (error) {
      return null;
    }
  }

  async function getFullItems(ids: string[], cleanFuncProps = {}) {
    try {
      const items: any[] = await getLocalItems(dataKey, ids);
      if (!!handleCleanData) {
        const cleanedItems = items.map((item: any) =>
          handleCleanData(item, cleanFuncProps)
        );
        return cleanedItems;
      }
      return items;
    } catch (error) {
      return [];
    }
  }

  return {
    getData,
    syncData,
    clearData,
    getFullItem,
    getFullItems,
    syncSingleItem,
  };
}
