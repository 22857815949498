import { Feature } from "geojson";
import { identity } from "../utils/functional/function";

export type TransformationFunction = (
  value: number,
  feature: Feature
) => number;
export type RenderFunction = (
  value: number,
  useMetrics?: boolean,
  shouldDisplayBaseMeasurement?: boolean
) => string;

export const FEATURE_TYPES = {
  AREA: "area",
  LINE: "line",
  COUNT: "count",
  OTHER: "other",
} as const;

export const DEFAULT_RENDER_FUNCTIONS: Record<string, RenderFunction> = {
  [FEATURE_TYPES.AREA]: (
    value: number,
    useMetrics: boolean,
    shouldDisplayBaseMeasurement: boolean = false
  ) => {
    const suffix = useMetrics ? "m²" : "sq.ft";
    const baseSuffix = useMetrics ? "cm²" : "sq.in";
    const finalSuffix = shouldDisplayBaseMeasurement ? baseSuffix : suffix;
    return `${value.toFixed(2)} ${finalSuffix}`;
  },
  [FEATURE_TYPES.LINE]: (
    value: number,
    useMetrics: boolean,
    shouldDisplayBaseMeasurement: boolean = false
  ) => {
    const suffix = useMetrics ? "m" : "ft";
    const baseSuffix = useMetrics ? "cm" : "in";
    const finalSuffix = shouldDisplayBaseMeasurement ? baseSuffix : suffix;
    return `${value.toFixed(2)} ${finalSuffix}`;
  },
  [FEATURE_TYPES.COUNT]: (value: number) => {
    return `${value}`;
  },
};

export const PASS_THROUGH_RENDER_FUNCTIONS: Record<string, RenderFunction> = {
  [FEATURE_TYPES.AREA]: identity as any,
  [FEATURE_TYPES.LINE]: identity as any,
  [FEATURE_TYPES.COUNT]: identity as any,
};

export const FORMULA_SUFFIX = "Formula";

export const QUANTITY1 = "quantity1";
export const QUANTITY1UOM = "quantity1UOM";
export const QUANTITY1FORUMULA = "quantity1Formula";

export const QUANTITY2 = "quantity2";
export const QUANTITY2UOM = "quantity2UOM";
export const QUANTITY2FORUMULA = "quantity2Formula";

export const QUANTITY3 = "quantity3";
export const QUANTITY3UOM = "quantity3UOM";
export const QUANTITY3FORUMULA = "quantity3Formula";

export const QUANTITY4 = "quantity4";
export const QUANTITY4UOM = "quantity4UOM";
export const QUANTITY4FORUMULA = "quantity4Formula";

export const INPUT_HEIGHT = "inputHeight";
export const INPUT_HEIGHT_UOM = "inputHeightUOM";

export const INPUT_GRID = "inputGrid";
export const INPUT_GRID_UOM = "inputGridUOM";

export const INPUT_THICKNESS = "inputThickness";
export const INPUT_THICKNESS_UOM = "inputThicknessUOM";

export const INPUT_WIDTH = "inputWidth";
export const INPUT_WIDTH_UOM = "inputWidthUOM";

export const INPUT_LENGTH = "inputLength";
export const INPUT_LENGTH_UOM = "inputLengthUOM";

export const INPUT_OFFSET = "inputOffset";
export const INPUT_OFFSET_UOM = "inputOffsetUOM";

export const QUANTITIES_UOM: any = {
  [QUANTITY1]: QUANTITY1UOM,
  [QUANTITY2]: QUANTITY2UOM,
  [QUANTITY3]: QUANTITY3UOM,
  [QUANTITY4]: QUANTITY4UOM,
  [INPUT_GRID]: INPUT_GRID_UOM,
  [INPUT_WIDTH]: INPUT_WIDTH_UOM,
  [INPUT_HEIGHT]: INPUT_HEIGHT_UOM,
  [INPUT_LENGTH]: INPUT_LENGTH_UOM,
  [INPUT_THICKNESS]: INPUT_THICKNESS_UOM,
};

export const UNIT_SQUARE_FEET = "square_feets";
export const UNIT_100_SQUARE_FEET = "square_100sqft";

export const UNIT_SQUARE_YARDS = "square_yards";
export const UNIT_SQUARE_METER = "square_meter";
export const UNIT_SQUARE_INCH = "square_inches";

export const UNIT_SQUARE_MILLIMETER = "square_millimeter";
export const UNIT_SQUARE_CENTIMETER = "square_centimeter";

export const UNIT_INCHES = "inches";
export const UNIT_FEET = "feet";
export const UNIT_YARD = "yard";
export const UNIT_METER = "meter";
export const UNIT_MILLIMETER = "millimeter";
export const UNIT_CENTIMETER = "centimeter";

export const UNIT_CUBIC_FEET = "cubic_feet";
export const UNIT_CUBIC_YARD = "cubic_yard";
export const UNIT_CUBIC_METER = "cubic_meter";

export const UNIT_TILE_COUT = "ea";
export const UNITS_DIVIDER = "divider";

export const UNITS: any = {
  [UNIT_SQUARE_INCH]: {
    indicator: "IN²",
    id: UNIT_SQUARE_INCH,
    value: UNIT_SQUARE_INCH,
    label: "Square Inches (IN²)",
    convert: (val: number) => val * 144,
  },
  [UNIT_SQUARE_FEET]: {
    indicator: "SF",
    id: UNIT_SQUARE_FEET,
    value: UNIT_SQUARE_FEET,
    label: "Square Feet (SF)",
    convert: (val: number) => val,
  },
  [UNIT_SQUARE_YARDS]: {
    indicator: "SY",
    id: UNIT_SQUARE_YARDS,
    value: UNIT_SQUARE_YARDS,
    label: "Square Yards (SY)",
    convert: (val: number) => val / 9,
  },
  [UNIT_100_SQUARE_FEET]: {
    indicator: "SQ",
    id: UNIT_100_SQUARE_FEET,
    value: UNIT_100_SQUARE_FEET,
    label: "Square - 100 square ft (SQ)",
    convert: (val: number) => val / 100,
  },
  [UNIT_SQUARE_METER]: {
    indicator: "M²",
    id: UNIT_SQUARE_METER,
    value: UNIT_SQUARE_METER,
    label: "Square Meter (M²)",
    convert: (val: number) => val / 10.764,
  },
  [UNIT_CENTIMETER]: {
    indicator: "CM",
    id: UNIT_CENTIMETER,
    value: UNIT_CENTIMETER,
    label: "Centimeters (CM)",
    convert: (val: number) => val * 30.48,
  },
  [UNIT_MILLIMETER]: {
    indicator: "MM",
    id: UNIT_MILLIMETER,
    value: UNIT_MILLIMETER,
    label: "Millimeters (MM)",
    convert: (val: number) => val * 304.8,
  },
  [UNIT_SQUARE_CENTIMETER]: {
    indicator: "CM²",
    id: UNIT_SQUARE_CENTIMETER,
    value: UNIT_SQUARE_CENTIMETER,
    label: "Square Centimeters (CM²)",
    convert: (val: number) => val * 929.03,
  },
  [UNIT_SQUARE_MILLIMETER]: {
    indicator: "MM²",
    id: UNIT_SQUARE_MILLIMETER,
    value: UNIT_SQUARE_MILLIMETER,
    label: "Square Millimeters (MM²)",
    convert: (val: number) => val * 92903,
  },
  [UNIT_INCHES]: {
    indicator: "IN",
    id: UNIT_INCHES,
    value: UNIT_INCHES,
    label: "Inches (IN)",
    convert: (val: number) => val * 12,
  },
  [UNIT_FEET]: {
    indicator: "FT",
    id: UNIT_FEET,
    value: UNIT_FEET,
    label: "Feet (FT)",
    convert: (val: number) => val,
  },
  [UNIT_YARD]: {
    indicator: "YD",
    id: UNIT_YARD,
    value: UNIT_YARD,
    label: "Yards (YD)",
    convert: (val: number) => val / 3,
  },
  [UNIT_METER]: {
    indicator: "M",
    id: UNIT_METER,
    value: UNIT_METER,
    label: "Meter (M)",
    convert: (val: number) => val / 3.281,
  },

  [UNIT_CUBIC_FEET]: {
    indicator: "CF",
    id: UNIT_CUBIC_FEET,
    value: UNIT_CUBIC_FEET,
    label: "Cubic Feet (CF)",
    convert: (val: number) => val,
  },
  [UNIT_CUBIC_YARD]: {
    indicator: "CY",
    id: UNIT_CUBIC_YARD,
    value: UNIT_CUBIC_YARD,
    label: "Cubic Yards (CY)",
    convert: (val: number) => val / 27,
  },
  [UNIT_CUBIC_METER]: {
    indicator: "M³",
    id: UNIT_CUBIC_METER,
    value: UNIT_CUBIC_METER,
    label: "Cubic Meter (M³)",
    convert: (val: number) => val / 35.315,
  },
  [UNIT_TILE_COUT]: {
    indicator: "EA",
    id: UNIT_TILE_COUT,
    value: UNIT_TILE_COUT,
    label: "EA",
    convert: (val: number) => val,
  },
};

export const METER_INDICATORS = ["M²", "M"];
export const CENTIMETER_INDICATORS = ["CM²", "CM"];

export const QUANTITY_CUTSOM = "custom";

export const QUANTITY_AREA = "area";
export const QUANTITY_COUNT = "count";
export const QUANTITY_LENGTH = "length";
export const QUANTITY_VOLUME = "volume";
export const QUANTITY_HOR_AREA = "hor_area";
export const QUANTITY_PERIMETER = "perimeter";
export const QUANTITY_PERIMETER_SURFACE_AREA = "perimeter_surface_area";
export const QUANTITY_TILE_COUNT = "tile_count";
export const QUANTITY_TOTAL_HEIGHT = "total_height";
export const QUANTITY_VER_AREA_1_SIDE = "ver_area_1_side";
export const QUANTITY_VER_AREA_2_SIDES = "ver_area_2_sides";
export const QUANTITY_VER_AREA_ALL_SIDES = "ver_area_all_sides";

export const QUANTITIES: any = {
  [QUANTITY_CUTSOM]: {
    label: "Custom Formula",
    id: QUANTITY_CUTSOM,
    value: QUANTITY_CUTSOM,
    units: [
      UNITS[UNIT_INCHES],
      UNITS[UNIT_FEET],
      UNITS[UNIT_YARD],
      UNITS_DIVIDER,
      UNITS[UNIT_MILLIMETER],
      UNITS[UNIT_CENTIMETER],
      UNITS[UNIT_METER],
      UNITS_DIVIDER,
      UNITS[UNIT_SQUARE_INCH],
      UNITS[UNIT_SQUARE_FEET],
      UNITS[UNIT_SQUARE_YARDS],
      UNITS[UNIT_100_SQUARE_FEET],
      UNITS_DIVIDER,
      UNITS[UNIT_SQUARE_METER],
      UNITS[UNIT_SQUARE_CENTIMETER],
      UNITS[UNIT_SQUARE_MILLIMETER],

      UNITS_DIVIDER,
      UNITS[UNIT_CUBIC_FEET],
      UNITS[UNIT_CUBIC_YARD],
      UNITS_DIVIDER,
      UNITS[UNIT_CUBIC_METER],
    ],
    defaultUnit: UNITS[UNIT_INCHES],
    defaultMetricUnit: UNITS[UNIT_METER],
  },

  [QUANTITY_AREA]: {
    label: "Area",
    id: QUANTITY_AREA,
    value: QUANTITY_AREA,
    units: [
      UNITS[UNIT_SQUARE_INCH],
      UNITS[UNIT_SQUARE_FEET],
      UNITS[UNIT_SQUARE_YARDS],
      UNITS[UNIT_100_SQUARE_FEET],
      UNITS_DIVIDER,
      UNITS[UNIT_SQUARE_METER],
      UNITS[UNIT_SQUARE_CENTIMETER],
      UNITS[UNIT_SQUARE_MILLIMETER],
    ],
    defaultUnit: UNITS[UNIT_SQUARE_FEET],
    defaultMetricUnit: UNITS[UNIT_SQUARE_METER],
  },
  [QUANTITY_PERIMETER]: {
    label: "Perimeter",
    id: QUANTITY_PERIMETER,
    value: QUANTITY_PERIMETER,
    units: [
      UNITS[UNIT_INCHES],
      UNITS[UNIT_FEET],
      UNITS[UNIT_YARD],
      UNITS_DIVIDER,
      UNITS[UNIT_MILLIMETER],
      UNITS[UNIT_CENTIMETER],
      UNITS[UNIT_METER],
    ],
    defaultUnit: UNITS[UNIT_FEET],
    defaultMetricUnit: UNITS[UNIT_METER],
  },
  [QUANTITY_TOTAL_HEIGHT]: {
    label: "Total height",
    id: QUANTITY_TOTAL_HEIGHT,
    value: QUANTITY_TOTAL_HEIGHT,
    units: [
      UNITS[UNIT_INCHES],
      UNITS[UNIT_FEET],
      UNITS[UNIT_YARD],
      UNITS_DIVIDER,
      UNITS[UNIT_MILLIMETER],
      UNITS[UNIT_CENTIMETER],
      UNITS[UNIT_METER],
    ],
    defaultUnit: UNITS[UNIT_FEET],
    defaultMetricUnit: UNITS[UNIT_METER],
  },
  [QUANTITY_VOLUME]: {
    label: "Volume",
    id: QUANTITY_VOLUME,
    value: QUANTITY_VOLUME,
    units: [
      UNITS[UNIT_CUBIC_FEET],
      UNITS[UNIT_CUBIC_YARD],
      UNITS_DIVIDER,
      UNITS[UNIT_CUBIC_METER],
    ],
    defaultUnit: UNITS[UNIT_CUBIC_YARD],
    defaultMetricUnit: UNITS[UNIT_CUBIC_METER],
  },
  [QUANTITY_PERIMETER_SURFACE_AREA]: {
    label: "Perimeter Surface Area",
    id: QUANTITY_PERIMETER_SURFACE_AREA,
    value: QUANTITY_PERIMETER_SURFACE_AREA,
    units: [
      UNITS[UNIT_SQUARE_INCH],
      UNITS[UNIT_SQUARE_FEET],
      UNITS[UNIT_SQUARE_YARDS],
      UNITS[UNIT_100_SQUARE_FEET],
      UNITS_DIVIDER,
      UNITS[UNIT_SQUARE_METER],
      UNITS[UNIT_SQUARE_CENTIMETER],
      UNITS[UNIT_SQUARE_MILLIMETER],
    ],
    defaultUnit: UNITS[UNIT_SQUARE_FEET],
    defaultMetricUnit: UNITS[UNIT_SQUARE_METER],
  },
  [QUANTITY_TILE_COUNT]: {
    label: "Tile Count",
    id: QUANTITY_TILE_COUNT,
    value: QUANTITY_TILE_COUNT,
    units: [UNITS[UNIT_TILE_COUT]],
    defaultUnit: UNITS[UNIT_TILE_COUT],
    defaultMetricUnit: UNITS[UNIT_TILE_COUT],
  },
  [QUANTITY_COUNT]: {
    label: "Count",
    id: QUANTITY_COUNT,
    value: QUANTITY_COUNT,
    units: [UNITS[UNIT_TILE_COUT]],
    defaultUnit: UNITS[UNIT_TILE_COUT],
    defaultMetricUnit: UNITS[UNIT_TILE_COUT],
  },
  [QUANTITY_VER_AREA_1_SIDE]: {
    label: "Vertical Area 1 Side",
    id: QUANTITY_VER_AREA_1_SIDE,
    value: QUANTITY_VER_AREA_1_SIDE,
    units: [
      UNITS[UNIT_SQUARE_INCH],
      UNITS[UNIT_SQUARE_FEET],
      UNITS[UNIT_SQUARE_YARDS],
      UNITS_DIVIDER,
      UNITS[UNIT_SQUARE_METER],
      UNITS[UNIT_SQUARE_MILLIMETER],
      UNITS[UNIT_SQUARE_CENTIMETER],
    ],
    defaultUnit: UNITS[UNIT_SQUARE_FEET],
    defaultMetricUnit: UNITS[UNIT_SQUARE_METER],
  },
  [QUANTITY_VER_AREA_2_SIDES]: {
    label: "Vertical Area 2 Sides",
    id: QUANTITY_VER_AREA_2_SIDES,
    value: QUANTITY_VER_AREA_2_SIDES,
    units: [
      UNITS[UNIT_SQUARE_INCH],
      UNITS[UNIT_SQUARE_FEET],
      UNITS[UNIT_SQUARE_YARDS],
      UNITS_DIVIDER,
      UNITS[UNIT_SQUARE_METER],
      UNITS[UNIT_SQUARE_MILLIMETER],
      UNITS[UNIT_SQUARE_CENTIMETER],
    ],
    defaultUnit: UNITS[UNIT_SQUARE_FEET],
    defaultMetricUnit: UNITS[UNIT_SQUARE_METER],
  },
  [QUANTITY_VER_AREA_ALL_SIDES]: {
    label: "Vertical Area All Sides",
    id: QUANTITY_VER_AREA_ALL_SIDES,
    value: QUANTITY_VER_AREA_ALL_SIDES,
    units: [
      UNITS[UNIT_SQUARE_INCH],
      UNITS[UNIT_SQUARE_FEET],
      UNITS[UNIT_SQUARE_YARDS],
      UNITS_DIVIDER,
      UNITS[UNIT_SQUARE_METER],
      UNITS[UNIT_SQUARE_MILLIMETER],
      UNITS[UNIT_SQUARE_CENTIMETER],
    ],
    defaultUnit: UNITS[UNIT_SQUARE_FEET],
    defaultMetricUnit: UNITS[UNIT_SQUARE_METER],
  },
  [QUANTITY_HOR_AREA]: {
    label: "Horizontal Area",
    id: QUANTITY_HOR_AREA,
    value: QUANTITY_HOR_AREA,
    units: [
      UNITS[UNIT_SQUARE_INCH],
      UNITS[UNIT_SQUARE_FEET],
      UNITS[UNIT_SQUARE_YARDS],
      UNITS_DIVIDER,
      UNITS[UNIT_SQUARE_METER],
      UNITS[UNIT_SQUARE_MILLIMETER],
      UNITS[UNIT_SQUARE_CENTIMETER],
    ],
    defaultUnit: UNITS[UNIT_SQUARE_FEET],
    defaultMetricUnit: UNITS[UNIT_SQUARE_METER],
  },
  [QUANTITY_LENGTH]: {
    label: "Length",
    id: QUANTITY_LENGTH,
    value: QUANTITY_LENGTH,
    units: [
      UNITS[UNIT_INCHES],
      UNITS[UNIT_FEET],
      UNITS[UNIT_YARD],
      UNITS_DIVIDER,
      UNITS[UNIT_METER],
      UNITS[UNIT_MILLIMETER],
      UNITS[UNIT_CENTIMETER],
    ],
    defaultUnit: UNITS[UNIT_FEET],
    defaultMetricUnit: UNITS[UNIT_METER],
  },
};

export const MARKUP_TABLE_WIDTH = 450;
