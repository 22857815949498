import kinks from "@turf/kinks";

import { CommonDrawMode } from "./common-draw";
import { head } from "../../utils/functional/list";
import { CLASSIFICATION_TYPES, GEOJSON_TYPES } from "../../consts/editor";

export class DrawPolygonMode extends CommonDrawMode {
  handleAddFeature = (event: any, props: any) => {
    const { activeClassification } = props.modeConfig;
    const clickSequence = this.getClickSequence();

    if (clickSequence?.length > 2) {
      const mainCoordinates = this.mainFeature?.properties?.closed
        ? this.mainFeature.geometry.coordinates[0]
        : clickSequence;
      const coordinates: any = [[...mainCoordinates, head(mainCoordinates)]];

      const feature: any = {
        type: GEOJSON_TYPES.Feature,
        properties: {},
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates,
        },
      };

      const featureClassification =
        activeClassification?.id &&
        activeClassification?.type === CLASSIFICATION_TYPES[0]
          ? activeClassification.id
          : null;

      const featureKinks = kinks(feature);
      const isEdgeIntersection = featureKinks?.features?.length > 1;

      if (
        (isEdgeIntersection && !this.mainFeature?.properties?.closed) ||
        this._lock
      ) {
        return;
      }

      const polygonFeatures = this.getAddFeatureOrBooleanPolygonAction(
        feature,
        props,
        featureClassification,
        null,
        this._arcs
      );

      if (polygonFeatures?.updatedData?.features?.length > 0) {
        this.addFeatures(polygonFeatures.updatedData.features);
        this.applyActions(props, true);
      }

      this._resetState();
    }
  };
}
