import { getGeom } from "@turf/invariant";
import { featureCollection, lineString, multiLineString } from "@turf/helpers";
import {
  Position,
  Feature,
  LineString,
  MultiPolygon,
  Polygon,
  MultiLineString,
} from "geojson";

/**
 * Converts a {@link Polygon} to {@link LineString|(Multi)LineString} or {@link MultiPolygon} to a
 * {@link FeatureCollection} of {@link LineString|(Multi)LineString}.
 *
 * @name polygonToLine
 * @param {Feature<Polygon|MultiPolygon>} poly Feature to convert
 * @param {Object} [options={}] Optional parameters
 * @param {Object} [options.properties={}] translates GeoJSON properties to Feature
 * @returns {FeatureCollection|Feature<LineString|MultiLinestring>} converted (Multi)Polygon to (Multi)LineString
 * @example
 * var poly = turf.polygon([[[125, -30], [145, -30], [145, -20], [125, -20], [125, -30]]]);
 *
 * var line = turf.polygonToLine(poly);
 *
 * //addToMap
 * var addToMap = [line];
 */
export default function (
  poly: Polygon | MultiPolygon | Feature<Polygon | MultiPolygon>,
  options: Record<string, any> = {}
) {
  const geom = getGeom(poly);
  if (!options.properties && poly.type === "Feature") {
    options.properties = poly.properties;
  }
  switch (geom.type) {
    case "Polygon":
      return polygonToLine(geom as any, options);
    case "MultiPolygon":
      return multiPolygonToLine(geom as any, options);
    default:
      throw new Error("invalid poly");
  }
}

/**
 * @private
 */
export function polygonToLine(
  poly: Feature<Polygon>,
  options: Record<string, any> = {}
) {
  const geom = getGeom(poly);
  const coords = geom.coordinates;
  const properties = options.properties
    ? options.properties
    : poly.type === "Feature"
    ? poly.properties
    : {};

  return coordsToLine(coords, properties);
}

/**
 * @private
 */
export function multiPolygonToLine(
  multiPoly: Feature<MultiPolygon>,
  options: Record<string, any> = {}
) {
  const geom = getGeom(multiPoly);
  const coords = geom.coordinates;
  const properties = options.properties
    ? options.properties
    : multiPoly.type === "Feature"
    ? multiPoly.properties
    : {};

  const lines: Feature<LineString>[] = [];
  coords.forEach((coord: Position[][]) => {
    lines.push(coordsToLine(coord, properties) as Feature<LineString>);
  });
  return featureCollection(lines);
}

/**
 * @private
 */
export function coordsToLine(
  coords: Position[][],
  properties: any
): Feature<LineString> | Feature<MultiLineString> {
  const validCoords = coords.filter(
    (line) => Array.isArray(line) && line.length >= 2
  );
  if (validCoords.length === 0) {
    console.error(
      "Invalid input: coordinates must contain at least two positions."
    );
    return null;
  }

  if (coords.length > 1) {
    return multiLineString(coords, properties);
  }
  return lineString(coords[0], properties);
}
