import * as THREE from "three";
import { Geometry } from "@luma.gl/core";

import { SHAPES } from "../../consts/svg";
import { GEOJSON_TYPES } from "../../consts/editor";
import { lineToPolygon } from "../../utils/pipeline";
import TubeGeometry from "../../utils/three/TubeGeometry";

let BufferGeometryUtils: any = null;

/**
 *
 *
 *  GEOMETRY UTILIS
 *
 *
 */

export function getPointSquareShape(point: any, pointWidth: number) {
  return {
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [
        [
          [
            point.geometry.coordinates[0] - pointWidth / 2,
            point.geometry.coordinates[1] - pointWidth / 2,
          ],
          [
            point.geometry.coordinates[0] + pointWidth / 2,
            point.geometry.coordinates[1] - pointWidth / 2,
          ],
          [
            point.geometry.coordinates[0] + pointWidth / 2,
            point.geometry.coordinates[1] + pointWidth / 2,
          ],
          [
            point.geometry.coordinates[0] - pointWidth / 2,
            point.geometry.coordinates[1] + pointWidth / 2,
          ],
          [
            point.geometry.coordinates[0] - pointWidth / 2,
            point.geometry.coordinates[1] - pointWidth / 2,
          ],
        ],
      ],
    },
    properties: point.properties,
  };
}

export function getPointCircleShape(point: any, pointWidth: number) {
  const radius = pointWidth / 2;
  const numSides = 32;

  const coordinates = [];
  for (let i = 0; i <= numSides; i++) {
    const angle = (i * 2 * Math.PI) / numSides;
    coordinates.push([
      point.geometry.coordinates[0] + radius * Math.cos(angle),
      point.geometry.coordinates[1] + radius * Math.sin(angle),
    ]);
  }

  return {
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [coordinates],
    },
    properties: point.properties,
  };
}

export function getPointTriangleDownShape(point: any, pointWidth: number) {
  const height = (Math.sqrt(3) / 2) * pointWidth;

  return {
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [
        [
          [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1] + (2 / 3) * height,
          ],
          [
            point.geometry.coordinates[0] - pointWidth / 2,
            point.geometry.coordinates[1] - (1 / 3) * height,
          ],
          [
            point.geometry.coordinates[0] + pointWidth / 2,
            point.geometry.coordinates[1] - (1 / 3) * height,
          ],
          [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1] + (2 / 3) * height,
          ],
        ],
      ],
    },
    properties: point.properties,
  };
}

export function getPointTriangleShape(point: any, pointWidth: number) {
  const height = (Math.sqrt(3) / 2) * pointWidth;

  return {
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [
        [
          [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1] - (2 / 3) * height,
          ],
          [
            point.geometry.coordinates[0] - pointWidth / 2,
            point.geometry.coordinates[1] + (1 / 3) * height,
          ],
          [
            point.geometry.coordinates[0] + pointWidth / 2,
            point.geometry.coordinates[1] + (1 / 3) * height,
          ],
          [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1] - (2 / 3) * height,
          ],
        ],
      ],
    },
    properties: point.properties,
  };
}

export function getPointDiamondShape(point: any, pointWidth: number) {
  const height = (pointWidth * Math.sqrt(2)) / 2;

  return {
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [
        [
          [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1] + height,
          ],
          [
            point.geometry.coordinates[0] - height,
            point.geometry.coordinates[1],
          ],
          [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1] - height,
          ],
          [
            point.geometry.coordinates[0] + height,
            point.geometry.coordinates[1],
          ],
          [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1] + height,
          ],
        ],
      ],
    },
    properties: point.properties,
  };
}

export function getPointPentagonSHape(point: any, pointWidth: number) {
  const radius = pointWidth / 2;

  const coordinates = [];
  for (let i = 0; i < 5; i++) {
    const angle = (i * 2 * Math.PI) / 5 - Math.PI / 2;
    coordinates.push([
      point.geometry.coordinates[0] + radius * Math.cos(angle),
      point.geometry.coordinates[1] + radius * Math.sin(angle),
    ]);
  }
  coordinates.push(coordinates[0]); // close the pentagon

  return {
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [coordinates],
    },
    properties: point.properties,
  };
}

export function getPointStarShape(point: any, pointWidth: number) {
  const outerRadius = pointWidth / 1.5;
  const innerRadius = pointWidth / 4;

  const coordinates = [];
  for (let i = 0; i < 10; i++) {
    const angle = (i * Math.PI) / 5 - Math.PI / 2;
    const radius = i % 2 === 0 ? outerRadius : innerRadius;
    coordinates.push([
      point.geometry.coordinates[0] + radius * Math.cos(angle),
      point.geometry.coordinates[1] + radius * Math.sin(angle),
    ]);
  }
  coordinates.push(coordinates[0]); // close the star

  return {
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [coordinates],
    },
    properties: point.properties,
  };
}

export function getPointRectangleShape(
  point: any,
  pointWidth: number,
  pointLength: number
) {
  return {
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [
        [
          [
            point.geometry.coordinates[0] - pointWidth / 2,
            point.geometry.coordinates[1] - pointLength / 2,
          ],
          [
            point.geometry.coordinates[0] + pointWidth / 2,
            point.geometry.coordinates[1] - pointLength / 2,
          ],
          [
            point.geometry.coordinates[0] + pointWidth / 2,
            point.geometry.coordinates[1] + pointLength / 2,
          ],
          [
            point.geometry.coordinates[0] - pointWidth / 2,
            point.geometry.coordinates[1] + pointLength / 2,
          ],
          [
            point.geometry.coordinates[0] - pointWidth / 2,
            point.geometry.coordinates[1] - pointLength / 2,
          ],
        ],
      ],
    },
    properties: point.properties,
  };
}
export function getPointPolygon(
  point: any,
  colorMap: any,
  referenceInchesPerPixels = 0
) {
  const featureClass =
    colorMap && point?.properties?.className in colorMap
      ? colorMap[point?.properties?.className]
      : null;

  const shape = featureClass?.shape || SHAPES.SQUARE;

  const pointWidth = (featureClass?.width || 5) / referenceInchesPerPixels;
  const pointLength = (featureClass?.length || 5) / referenceInchesPerPixels;

  switch (shape) {
    case SHAPES.SQUARE:
      return getPointSquareShape(point, pointWidth);
    case SHAPES.CIRCLE:
      return getPointCircleShape(point, pointWidth);
    case SHAPES.TRIANGLE_FLIPPED:
      return getPointTriangleDownShape(point, pointWidth);
    case SHAPES.TRIANGLE:
      return getPointTriangleShape(point, pointWidth);
    case SHAPES.DIAMOND:
      return getPointDiamondShape(point, pointWidth);
    case SHAPES.PENTAGON:
      return getPointPentagonSHape(point, pointWidth);
    case SHAPES.STAR:
      return getPointStarShape(point, pointWidth);
    case SHAPES.RECTANGLE:
      return getPointRectangleShape(point, pointWidth, pointLength);
    default:
      return getPointSquareShape(point, pointWidth);
  }
}

export function getLinePolygon(
  line: any,
  colorMap: any,
  referenceInchesPerPixels = 0
) {
  const featureClass =
    colorMap && line?.properties?.className in colorMap
      ? colorMap[line?.properties?.className]
      : null;

  const featureThikness = featureClass?.thikness;
  const lineThickness =
    Math.round(featureThikness / referenceInchesPerPixels / 2) || 5;
  const linePolygon = lineToPolygon(line, lineThickness);

  return {
    ...linePolygon,
    properties: line.properties,
  };
}

/**
 *
 *
 *  Extruded GEOMETRY UTILIS
 *
 *
 */
export function getPipelineTubeGeometries(
  pipeline: any,
  width: number = 50,
  zPadding = 0
) {
  const pipelineCoordinates = pipeline?.geometry.coordinates;
  let color = pipeline?.properties?.linePolygon?.properties?.color.map(
    (c: number) => c / 255
  ) ?? [0.2, 0.2, 1];

  const isVertical = pipelineCoordinates[0][2] !== pipelineCoordinates[1][2];

  color = isVertical
    ? [color[0] / 0.75, color[1] / 0.75, color[2] * 0.5]
    : color;

  const pipelineTHREEVectors = pipelineCoordinates.map(
    (coord: any) => new THREE.Vector3(coord[0], coord[1], coord[2] || 0)
  );

  const offset = 0;
  const radialSegments = 16;
  const direction = new THREE.Vector3(0, 0, -1);

  const curve = new THREE.CatmullRomCurve3(
    [pipelineTHREEVectors[0], pipelineTHREEVectors[1]],
    false,
    "catmullrom",
    0
  );

  const tube = new TubeGeometry(curve, 1, width, radialSegments, false);
  tube.computeVertexNormals();

  const matrix = new THREE.Matrix4().makeTranslation(
    direction.x * width,
    direction.y * width,
    direction.z * width - (zPadding + offset)
  );
  tube.applyMatrix4(matrix);
  tube.computeVertexNormals();

  const colors = [];

  for (let i = 0; i < tube.attributes.position.count; i++) {
    colors.push(color[0], color[1], color[2], 1);
  }
  tube.setAttribute("color", new THREE.Float32BufferAttribute(colors, 4));
  return tube;
}

export function getExtrudedPolygonGeometries(
  polygon: any,
  colorMap: any,
  referenceInchesPerPixels = 0,
  overridedHeight?: number,
  zPadding = 0,
  direction: THREE.Vector3 = new THREE.Vector3(0, 0, -1)
) {
  const alpha = 1;

  try {
    const featureClass =
      colorMap && polygon?.properties?.className in colorMap
        ? colorMap[polygon?.properties?.className]
        : null;

    const height =
      (overridedHeight || featureClass?.height || 0) /
      (referenceInchesPerPixels || 0);

    if (!height) {
      return null;
    }

    const color = (featureClass?.colorStyle?.fill || [150, 150, 150]).map(
      (c: number) => c / 255
    );

    const offset = Math.round(
      (featureClass?.offset || 0) / (referenceInchesPerPixels || 0)
    );

    const depth = height;

    const [outerRing, ...holes] = polygon?.geometry.coordinates;

    const shape = new THREE.Shape();

    outerRing.forEach((coord: any, index: number) => {
      if (index === 0) {
        shape.moveTo(coord[0], coord[1]);
      } else {
        shape.lineTo(coord[0], coord[1]);
      }
    });

    holes.forEach((holeCoords: any) => {
      const path = new THREE.Path();
      holeCoords.forEach((coord: any, index: number) => {
        if (index === 0) {
          path.moveTo(coord[0], coord[1]);
        } else {
          path.lineTo(coord[0], coord[1]);
        }
      });
      shape.holes.push(path);
    });

    const extrudeSettings = {
      steps: 1,
      depth: depth,
      curveSegments: 12,
      bevelEnabled: false,
    };

    const extrudedGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

    extrudedGeometry.computeVertexNormals();
    extrudedGeometry.setIndex([
      ...Array(extrudedGeometry.attributes.position.count).keys(),
    ]);

    // Apply direction transformation
    const matrix = new THREE.Matrix4().makeTranslation(
      direction.x * depth,
      direction.y * depth,
      direction.z * depth - (zPadding + offset)
    );
    extrudedGeometry.applyMatrix4(matrix);
    extrudedGeometry.computeVertexNormals();

    const colors = [];

    for (let i = 0; i < extrudedGeometry.attributes.position.count; i++) {
      colors.push(color[0], color[1], color[2], alpha);
    }

    extrudedGeometry.setAttribute(
      "color",
      new THREE.Float32BufferAttribute(colors, 4)
    );

    return extrudedGeometry;
  } catch (e) {
    console.error("Failed to getExtrudedPolygonGeometries: ", e);
    return null;
  }
}

export function getExtrudedFeature(
  feature: any,
  colorMap: any,
  referenceInchesPerPixels = 0,
  height = 0
) {
  if (feature?.geometry?.type === GEOJSON_TYPES.Point) {
    const buffererdPoint = getPointPolygon(
      feature,
      colorMap,
      referenceInchesPerPixels
    );

    if (buffererdPoint) {
      return getExtrudedPolygonGeometries(
        buffererdPoint,
        colorMap,
        referenceInchesPerPixels,
        null,
        height
      );
    }
  }

  if (feature?.geometry?.type === GEOJSON_TYPES.LineString) {
    const linePolygon = getLinePolygon(
      feature,
      colorMap,
      referenceInchesPerPixels
    );

    if (linePolygon) {
      return getExtrudedPolygonGeometries(
        linePolygon,
        colorMap,
        referenceInchesPerPixels,
        null,
        height
      );
    }
  }

  if (feature?.geometry?.type === GEOJSON_TYPES.MultiLineString) {
    const lines = feature?.geometry?.coordinates.map((line: any) => ({
      type: GEOJSON_TYPES.Feature,
      properties: feature?.properties,
      geometry: {
        type: GEOJSON_TYPES.LineString,
        coordinates: line,
      },
    }));

    const geometries = lines.map((line: any) =>
      getExtrudedFeature(line, colorMap, referenceInchesPerPixels, height)
    );

    return geometries.filter((geometry: any) => geometry);
  }

  if (feature?.geometry?.type === GEOJSON_TYPES.Polygon) {
    return getExtrudedPolygonGeometries(
      feature,
      colorMap,
      referenceInchesPerPixels,
      null,
      height
    );
  }

  if (feature?.geometry?.type === GEOJSON_TYPES.MultiPolygon) {
    const polygons = feature?.geometry?.coordinates.map((polygon: any) => ({
      type: GEOJSON_TYPES.Feature,
      properties: feature?.properties,
      geometry: {
        type: GEOJSON_TYPES.Polygon,
        coordinates: polygon,
      },
    }));

    const geometries = polygons.map((polygon: any) =>
      getExtrudedFeature(polygon, colorMap, referenceInchesPerPixels, height)
    );

    return geometries.filter((geometry: any) => geometry);
  }
}

/**
 *
 *
 *  merge geometry and create scne mesh
 *
 *
 */

export function generateFeaturesGeometries(
  features: any,
  colorMap: any,
  referenceInchesPerPixels = 0,
  height = 0
) {
  try {
    const filteredFeatures = features.filter(
      (feature: any) =>
        !feature?.properties?.id.includes("hole") &&
        !feature?.properties?.types.includes(GEOJSON_TYPES.markup)
    );

    if (!filteredFeatures?.length) return [];

    const geometries: any[] = [];

    for (let i = 0; i < filteredFeatures.length; i++) {
      const realHeight = height / referenceInchesPerPixels;

      const extrudedFeature = getExtrudedFeature(
        filteredFeatures[i],
        colorMap,
        referenceInchesPerPixels,
        realHeight
      );

      if (extrudedFeature) {
        if (Array.isArray(extrudedFeature)) {
          geometries.push(
            ...extrudedFeature.map((g) => ({
              ...g,
              className: filteredFeatures[i]?.properties?.className,
            }))
          );
        } else {
          geometries.push({
            ...extrudedFeature,
            className: filteredFeatures[i]?.properties?.className,
          });
        }
      }
    }

    return geometries;
  } catch (e) {
    console.error("Failed to generateFeaturesGeometries: ", e);
    return [];
  }
}

export async function mergeGeometries(geometries: any) {
  if (!BufferGeometryUtils?.mergeBufferGeometries) {
    BufferGeometryUtils = await import(
      "three/examples/jsm/utils/BufferGeometryUtils.js"
    );
  }

  if (!geometries?.length) {
    return null;
  }
  // @ts-ignore
  const geometry = BufferGeometryUtils.mergeBufferGeometries(geometries, true);

  if (!geometry) {
    console.error("Failed to merge geometries.");
    return null;
  }

  const colors = geometry.attributes.color.array;
  const positions = geometry.attributes.position.array;
  const indices = geometry.index ? geometry.index.array : [];

  const lumaGeometry = new Geometry({
    attributes: {
      positions: {
        value: positions,
        size: 3,
      },
      colors: {
        size: 4,
        value: new Float32Array(colors),
      },
    },
    indices,
  });

  return lumaGeometry || null;
}

export async function generate3dSceneMesh(
  features: any,
  colorMap?: any,
  referenceInchesPerPixels = 0
) {
  const geometries = generateFeaturesGeometries(
    features,
    colorMap,
    referenceInchesPerPixels
  );

  const finalGeometry = mergeGeometries(geometries);
  return finalGeometry;
}
