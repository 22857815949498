import React from "react";

export const QP_ICONS_IDS = {
  VISIBLE: "visible",
  INVISIBLE: "invisible",
  PARTIALLY_VISIBLE: "partially_visible",
  SELECTED: "selected",
  PARTIALLY_SELECTED: "partially_selected",
  DESELECTED: "deselected",
  KEBAB: "kebab",
};

export const QP_ICONS = {
  [QP_ICONS_IDS.VISIBLE]: {
    width: "16",
    height: "16",
    color: "#323232",
    viewBox: "0 0 16 16",

    icon: QpVisibleIcon,
  },
  [QP_ICONS_IDS.INVISIBLE]: {
    width: "16",
    height: "16",
    color: "#323232",
    viewBox: "0 0 16 16",

    icon: QpInvisibleIcon,
  },
  [QP_ICONS_IDS.PARTIALLY_VISIBLE]: {
    width: "16",
    height: "16",
    color: "#323232",
    viewBox: "0 0 16 16",

    icon: QpPartiallyVisibleIcon,
  },
  [QP_ICONS_IDS.SELECTED]: {
    width: "15",
    height: "20",
    color: "#323232",
    viewBox: "0 0 15 20",

    icon: QpSelectedIcon,
  },
  [QP_ICONS_IDS.DESELECTED]: {
    width: "15",
    height: "16",
    color: "#323232",
    viewBox: "0 0 15 16",

    icon: QpDeselectedIcon,
  },
  [QP_ICONS_IDS.PARTIALLY_SELECTED]: {
    width: "15",
    height: "16",
    color: "#323232",
    viewBox: "0 0 15 16",

    icon: QpPartiallySelectedIcon,
  },
  [QP_ICONS_IDS.KEBAB]: {
    width: "20",
    height: "20",
    color: "#323232",
    viewBox: "0 0 20 20",

    icon: QpKebabIcon,
  },
};

export function QpVisibleIcon({ color = "#323232" }) {
  return (
    <>
      <path
        id="Path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0785 8.31394C1.97383 8.11994 1.97383 7.8846 2.0785 7.6906C3.33983 5.35794 5.66983 3.33594 7.99983 3.33594C10.3298 3.33594 12.6598 5.35794 13.9212 7.69127C14.0258 7.88527 14.0258 8.1206 13.9212 8.3146C12.6598 10.6473 10.3298 12.6693 7.99983 12.6693C5.66983 12.6693 3.33983 10.6473 2.0785 8.31394Z"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M9.41421 6.58579C10.1953 7.36683 10.1953 8.63317 9.41421 9.41421C8.63317 10.1953 7.36683 10.1953 6.58579 9.41421C5.80474 8.63317 5.80474 7.36683 6.58579 6.58579C7.36683 5.80474 8.63317 5.80474 9.41421 6.58579"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function QpInvisibleIcon({ color = "#323232" }) {
  return (
    <>
      <path
        id="Path"
        d="M7.99746 12.6702C7.43613 12.6702 6.87413 12.5515 6.32812 12.3402"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M13.9213 8.3125C12.66 10.6452 10.33 12.6672 8 12.6672"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M12.7188 5.94531C13.1794 6.48465 13.5888 7.07265 13.9208 7.68665C14.0254 7.88065 14.0254 8.11598 13.9208 8.30998"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M3.33594 12.6693L12.6693 3.33594"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_6"
        d="M11.3625 4.63994C10.3312 3.84194 9.16517 3.33594 7.99983 3.33594C5.66983 3.33594 3.33983 5.35794 2.0785 7.69127C1.97383 7.88527 1.97383 8.1206 2.0785 8.3146C2.70917 9.4806 3.6065 10.5686 4.63717 11.3659"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function QpPartiallyVisibleIcon({ color = "#323232" }) {
  return (
    <>
      <path
        id="Path"
        d="M7.99746 12.6702C7.43613 12.6702 6.87413 12.5515 6.32812 12.3402"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M13.9213 8.3125C12.66 10.6452 10.33 12.6672 8 12.6672"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M12.7188 5.94531C13.1794 6.48465 13.5888 7.07265 13.9208 7.68665C14.0254 7.88065 14.0254 8.11598 13.9208 8.30998"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M3.33594 12.6693L12.6693 3.33594"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_5"
        d="M6.51344 9.48344C5.69344 8.66344 5.69344 7.33344 6.51344 6.51344C7.33344 5.69344 8.66344 5.69344 9.48344 6.51344"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_6"
        d="M11.3625 4.63994C10.3312 3.84194 9.16517 3.33594 7.99983 3.33594C5.66983 3.33594 3.33983 5.35794 2.0785 7.69127C1.97383 7.88527 1.97383 8.1206 2.0785 8.3146C2.70917 9.4806 3.6065 10.5686 4.63717 11.3659"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function QpSelectedIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M8 16C11.3137 16 14 13.3137 14 10C14 6.68629 11.3137 4 8 4C4.68629 4 2 6.68629 2 10C2 13.3137 4.68629 16 8 16Z"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.6293 10.226L7.07396 11.6706L7.06463 11.6613L10.324 8.40195"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function QpDeselectedIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function QpPartiallySelectedIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00661 8.31798L5.50003 8.30436L10.616 8.30478"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function QpKebabIcon({ color = "#323232" }) {
  return (
    <>
      <path
        id="Vector"
        d="M10 7.75C10.5533 7.75 11 7.30333 11 6.75C11 6.19667 10.5533 5.75 10 5.75C9.44667 5.75 9 6.19667 9 6.75C9 7.30333 9.44667 7.75 10 7.75ZM10 9.08333C9.44667 9.08333 9 9.53 9 10.0833C9 10.6367 9.44667 11.0833 10 11.0833C10.5533 11.0833 11 10.6367 11 10.0833C11 9.53 10.5533 9.08333 10 9.08333ZM10 12.4167C9.44667 12.4167 9 12.8633 9 13.4167C9 13.97 9.44667 14.4167 10 14.4167C10.5533 14.4167 11 13.97 11 13.4167C11 12.8633 10.5533 12.4167 10 12.4167Z"
        fill="#1C2931"
      />
    </>
  );
}
