import { createTentativeFeature } from "../../utils/coordinates";
import { GEOJSON_TYPES } from "../../consts/editor";

import { FeatureCollection } from "geojson";
import { generateId } from "../../utils/string";
import GeoJsonEditMode from "../base/GeojsonEditMode";
import { getPickedEditHandle } from "../../utils/modes";
import { makeMarkupFeature } from "../base/ImmutableLayersData";
import { getMarkupFeatureContextProps } from "../../utils/geometry";

export class DrawMarkupFreeHandMode extends GeoJsonEditMode {
  createTentativeFeature(props: any) {
    return createTentativeFeature(props, this, GEOJSON_TYPES.markup, true);
  }

  getGuides(props: any) {
    const guides: FeatureCollection = {
      type: GEOJSON_TYPES.FeatureCollection,
      features: [],
    };

    const clickSequence = this.getClickSequence();

    if (clickSequence.length === 0 || !props.lastPointerMoveEvent) {
      return guides;
    }
    const tentativeFeature = this.createTentativeFeature(props);

    guides.features.push(...tentativeFeature);

    if (clickSequence.length < 3) {
      guides.features = guides.features.slice(0, -1);
    }

    return guides;
  }

  handleClick = (event: any, props: any) => {
    const { picks } = event;
    const features = props.data.features;

    const clickSequence = this.getClickSequence();
    const clickedEditHandle = getPickedEditHandle(picks);

    if (!clickedEditHandle && clickSequence.length === 0) {
      const mapCoords = event.mapCoords;
      this.addClickSequence({
        mapCoords,
      });
    }

    if (!clickedEditHandle && clickSequence.length > 3) {
      const clickSequence = this.getClickSequence();
      if (clickSequence.length > 2) {
        const mapCoords = event.mapCoords;
        const options = props?.modeConfig?.options;

        const featureId = generateId();
        const feature = {
          type: GEOJSON_TYPES.Feature,
          properties: {
            id: featureId,
            text: options.text,
            size: options.size.default,
            color: options.color.default,
            border: options.border.default,
            thinkness: options?.thinkness?.default || 0,
            opacity: options.color.default.fillOpacity === 0 ? 0 : 50,
          },
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates: [...clickSequence, mapCoords],
          },
        };

        this.resetClickSequence();

        const markupFeature = makeMarkupFeature(
          feature,
          "",
          props.modeConfig.modeId
        );
        const contextProps = getMarkupFeatureContextProps(
          markupFeature,
          props.modeConfig.deck.current.deck
        );
        if (contextProps) {
          props.modeConfig.setSelected(contextProps.selections);
          props.modeConfig.setDefaultMode();
        }

        this.addFeatures([markupFeature]);
        this.applyActions(props, true, {
          contextProps,
        });
      }
    }
  };

  handleKeyUp = (event: any, props: any) => {
    const features = props.data.features;
    const ctrlOrCmd = event.metaKey || event.ctrlKey || event.altKey;

    if (
      event.key === "z" ||
      event.key === "Z" ||
      (ctrlOrCmd && (event.key === "Z" || event.key === "z"))
    ) {
      event.preventDefault();
      const clickSequence = this.getClickSequence();

      if (clickSequence.length > 1) {
        this._clickSequence = clickSequence.slice(0, -1);
      } else {
        this._clickSequence = [];
      }
    }

    if (event.key === "Enter") {
      const clickSequence = this.getClickSequence();
      if (clickSequence.length > 2) {
        const featureId = generateId();
        const feature = {
          type: GEOJSON_TYPES.Feature,
          properties: {
            id: featureId,
            color: props.modeConfig.options.color.default,
            opacity: props.modeConfig.options.opacity.default,
            size: props.modeConfig.options.size.default,
            thinkness: props.modeConfig.options.thinkness.default,
          },
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates: clickSequence,
          },
        };

        this.resetClickSequence();

        const markupFeature = makeMarkupFeature(
          feature,
          "",
          props.modeConfig.modeId
        );
        const contextProps = getMarkupFeatureContextProps(
          markupFeature,
          props.modeConfig.deck.current.deck
        );
        if (contextProps) {
          props.modeConfig.setDefaultMode();
          props.modeConfig.setSelected(contextProps.selections);
        }

        this.addFeatures([markupFeature]);
        this.applyActions(props, true, {
          contextProps,
        });
      }
    }
  };

  handlePointerMove = (event: any, props: any) => {
    props.onUpdateCursor("crosshair");

    const clickSequence = this.getClickSequence();
    const mapCoords = event.mapCoords;

    if (clickSequence.length > 0 && mapCoords) {
      const dist = Math.hypot(
        mapCoords[0] - clickSequence[clickSequence.length - 1][0],
        mapCoords[1] - clickSequence[clickSequence.length - 1][1]
      );

      if (clickSequence.length > 0 && Math.abs(dist) > 100) {
        this.addClickSequence({
          mapCoords,
        });
      }
    }
  };
}
