export const prevent = (
  e: Pick<Event, "preventDefault" | "stopPropagation">
) => {
  e.preventDefault();
  e.stopPropagation();
};

export const getParagraphDimensions = (
  text: string,
  fontSize?: string,
  fontWeight?: string,
  maxWidth?: string,
  lineHeight?: string
) => {
  const container = document.createElement("p");
  if (container) {
    container.style.width = "fit-content";
    container.style.overflowWrap = "break-word";

    container.style.lineHeight = lineHeight;
    container.style.fontSize = fontSize;
    container.style.fontWeight = fontWeight;
    container.textContent = text;
    document.body.appendChild(container);

    const width = container.clientWidth;

    container.style.maxWidth = maxWidth;
    const height = container.clientHeight;

    container.parentNode.removeChild(container);
    return { width, height };
  }
  return { width: 0, height: 0 };
};
