import { Feature, FeatureCollection, Polygon, Position } from "geojson";

import { generateId } from "../../utils/string";
import GeoJsonEditMode from "../base/GeojsonEditMode";
import ImmutableLayersData, {
  makeMarkupFeature,
} from "../base/ImmutableLayersData";
import { GEOJSON_TYPES, MODES_IDS } from "../../consts/editor";
import {
  getTwoClickPolygon,
  getMarkupFeatureContextProps,
} from "../../utils/geometry";

export class DrawMarkupRectangleMode extends GeoJsonEditMode {
  handleClick = (event: any, props: any) => {
    if (props.modeConfig && props.modeConfig.dragToDraw) {
      return;
    }

    this.addClickSequence(event);
    this.checkAndFinishPolygon(props);
  };

  checkAndFinishPolygon(props: any) {
    const features = props.data.features;

    const clickSequence = this.getClickSequence();
    const tentativeFeature = this.getTentativeGuide(
      props,
      GEOJSON_TYPES.markup
    );

    if (
      clickSequence.length > 1 &&
      tentativeFeature?.geometry?.type === GEOJSON_TYPES.Polygon
    ) {
      const options = props?.modeConfig?.options;
      const featureId = generateId();
      const feature = {
        type: GEOJSON_TYPES.Feature,
        properties: {
          id: featureId,
          text: options.text,
          size: options.size.default,
          color: options.color.default,
          border: options.border.default,
          thinkness: options?.thinkness?.default || 0,
          opacity: options.color.default.fillOpacity === 0 ? 0 : 50,
        },
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates: tentativeFeature.geometry.coordinates,
        },
      };

      const markupFeature = makeMarkupFeature(
        feature,
        "",
        props.modeConfig.modeId
      );

      this.resetClickSequence();

      const contextProps = getMarkupFeatureContextProps(
        markupFeature,
        props.modeConfig.deck.current.deck
      );
      if (contextProps) {
        props.modeConfig.setSelected(contextProps.selections);
        props.modeConfig.setDefaultMode();
      }

      this.addFeatures([markupFeature]);

      this.applyActions(props, true, {
        contextProps,
      });
    }
  }

  getGuides = (props: any) => {
    const { lastPointerMoveEvent } = props;
    const clickSequence = this.getClickSequence();

    const guides: FeatureCollection = {
      type: GEOJSON_TYPES.FeatureCollection,
      features: [],
    };

    if (clickSequence.length === 0) {
      // nothing to do yet
      return guides;
    }

    const corner1 = clickSequence[0];
    const corner2 = lastPointerMoveEvent.mapCoords;

    const polygon = this.getTwoClickPolygon(corner1, corner2);
    if (polygon) {
      guides.features.push({
        type: GEOJSON_TYPES.Feature,
        properties: {
          shape: polygon.properties && polygon.properties.shape,
          guideType: GEOJSON_TYPES.markup,
        },
        geometry: polygon.geometry,
      });
    }

    return guides;
  };

  getTwoClickPolygon(coord1: Position, coord2: Position): Feature<Polygon> {
    return getTwoClickPolygon(coord1, coord2);
  }

  handlePointerMove(event: any, props: any) {
    props.onUpdateCursor("crosshair");
  }

  createTentativeFeature(props: any) {
    const { lastPointerMoveEvent } = props;
    const clickSequence = this.getClickSequence();

    const lastCoords = lastPointerMoveEvent
      ? [lastPointerMoveEvent.mapCoords]
      : [];

    let tentativeFeature;
    if (clickSequence.length === 1) {
      tentativeFeature = this.getTwoClickPolygon(
        clickSequence[0],
        lastCoords[0]
      );
    }

    return tentativeFeature;
  }
}
