import { generateId } from "../../utils/string";

import { CommonDrawMode } from "./common-draw";
import { head } from "../../utils/functional/list";
import { makeFeature } from "../base/ImmutableLayersData";
import { CLASSIFICATION_TYPES, GEOJSON_TYPES } from "../../consts/editor";

export class DrawLineMode extends CommonDrawMode {
  handleAddFeature = (event: any, props: any) => {
    const isCtrlCmd = event.ctrlKey || event.metaKey;

    const features = [];
    const clickSequence = this.getClickSequence();
    const { view, activeClassification } = props.modeConfig;

    if (clickSequence.length > 0) {
      const coordinates: any = this?.mainFeature?.geometry?.coordinates;
      const cleanedCoords =
        coordinates?.length > 0 &&
        coordinates[0]?.length &&
        Array.isArray(head(coordinates[0]))
          ? coordinates[0].slice(0, -1)
          : coordinates?.slice(0, -1);

      const featureClassification =
        activeClassification?.id &&
        activeClassification?.type === CLASSIFICATION_TYPES[1]
          ? activeClassification.id
          : null;

      const previousIndices = this._arcs.flatMap((arc) => [
        ...arc.ignoreIndices,
        arc.endPointIdx,
      ]);
      const addedArcsIds = new Set();

      if (props?.is2PointsMode || isCtrlCmd) {
        const newId = generateId();
        features.push(
          makeFeature({
            geometry: {
              type: GEOJSON_TYPES.LineString,
              coordinates:
                isCtrlCmd && !props?.is2PointsMode
                  ? cleanedCoords
                  : coordinates,
            },
            id: newId,
            view,
            className: featureClassification,
            featureAccessKey: props.featureAccessKey,
            clMap: {
              [activeClassification?.id || ""]: activeClassification,
            },
            featureProperties: {
              arcs: this?.mainFeature?.properties?.arcs || [],
            },
          })
        );
      } else {
        for (const idx in cleanedCoords) {
          const index = Number(idx);
          if (index > 0) {
            const newId = generateId();
            const arc = previousIndices.includes(index)
              ? this._arcs.find((arc) =>
                  [
                    arc.endPointIdx,
                    arc.startPointIdx,
                    ...arc.ignoreIndices,
                  ].includes(index)
                )
              : null;

            if (arc) {
              if (!addedArcsIds.has(arc.id)) {
                const arcCoordinates = cleanedCoords.slice(
                  arc.startPointIdx,
                  arc.endPointIdx + 1
                );
                const lineArc = {
                  ...arc,
                  startPointIdx: 0,
                  endPointIdx: arcCoordinates.length - 1,
                  ignoreIndices: new Array(arcCoordinates.length - 2)
                    .fill(0)
                    .map((_, i) => i + 1),
                };

                features.push(
                  makeFeature({
                    geometry: {
                      type: GEOJSON_TYPES.LineString,
                      coordinates: arcCoordinates,
                    },
                    id: newId,
                    view,
                    className: featureClassification,
                    featureAccessKey: props.featureAccessKey,
                    clMap: {
                      [activeClassification?.id || ""]: activeClassification,
                    },
                    featureProperties: { arcs: [lineArc] },
                  })
                );

                addedArcsIds.add(arc.id);
              }
            } else {
              features.push(
                makeFeature({
                  geometry: {
                    type: GEOJSON_TYPES.LineString,
                    coordinates: [
                      cleanedCoords[index - 1],
                      cleanedCoords[index],
                    ],
                  },
                  id: newId,
                  view,
                  className: featureClassification,
                  featureAccessKey: props.featureAccessKey,
                  clMap: {
                    [activeClassification?.id || ""]: activeClassification,
                  },
                })
              );
            }
          }
        }
      }

      this.addFeatures(features);
      this.applyActions(props, true);
      this._resetState();
    }
  };
}
