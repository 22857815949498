import React from "react";
import { MODES_IDS } from "../../consts/editor";

export const EDITOR_ICON_IDS = {
  snapshot: "snapshots",
  documents: "documents",
};

export const EDITOR_ICONS = {
  [MODES_IDS.hole]: {
    width: "36",
    height: "36",
    color: "#323232",
    viewBox: "0 0 36 36",

    icon: CutIcon,
  },
  [MODES_IDS.line]: {
    width: "20",
    height: "20",
    color: "#323232",
    viewBox: "0 0 20 20",

    icon: DrawLineIcon,
  },
  [MODES_IDS.point]: {
    width: "20",
    height: "20",
    color: "#323232",
    viewBox: "0 0 20 20",

    icon: PointIcon,
  },
  [MODES_IDS.pan]: {
    width: "36",
    height: "36",
    color: "#323232",
    viewBox: "0 0 36 36",

    icon: HandIcon,
  },
  [MODES_IDS.polygon]: {
    width: "20",
    height: "20",
    color: "#323232",
    viewBox: "0 0 20 20",

    icon: PolygonIcon,
  },
  [MODES_IDS.split]: {
    width: "36",
    height: "36",
    color: "#323232",
    viewBox: "0 0 36 36",

    icon: SplitIcon,
  },
  [MODES_IDS.dimensionLine]: {
    width: "36",
    height: "36",
    color: "#323232",
    viewBox: "0 0 36 36",

    icon: ScaleIcon,
  },
  [MODES_IDS.merge]: {
    width: "36",
    height: "36",
    color: "#323232",
    viewBox: "0 0 36 36",

    icon: MergeIcon,
  },
  [MODES_IDS.pointer]: {
    width: "36",
    height: "36",
    color: "#323232",
    viewBox: "0 0 36 36",

    icon: CursorIcon,
  },
  [MODES_IDS.magic_tool]: {
    width: "36",
    height: "36",
    color: "#323232",
    viewBox: "0 0 36 36",

    icon: CursorIcon,
  },
  [MODES_IDS.search]: {
    width: "36",
    height: "36",
    color: "#323232",
    viewBox: "0 0 36 36",

    icon: CursorIcon,
  },
  [MODES_IDS.rect]: {
    width: "20",
    height: "20",
    color: "#323232",
    viewBox: "0 0 20 20",

    icon: RectangleIcon,
  },
  [MODES_IDS.comparison_align]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",

    icon: ComparisonEditIcon,
  },
  [MODES_IDS.markup_box]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",

    icon: MarkupBoxIcon,
  },
  [MODES_IDS.markup_line]: {
    width: "18",
    height: "18",
    color: "#323232",
    viewBox: "0 0 18 18",

    icon: MarkupLineIcon,
  },
  [MODES_IDS.markup_arrow]: {
    width: "18",
    height: "18",
    color: "#323232",
    viewBox: "0 0 18 18",

    icon: MarkupLineIcon,
  },
  [MODES_IDS.markup_text_box]: {
    width: "22",
    height: "22",
    color: "#323232",
    viewBox: "0 0 22 22",

    icon: MarkupTextIcon,
  },
  [MODES_IDS.markup_cloud]: {
    width: "22",
    height: "22",
    color: "#323232",
    viewBox: "0 0 22 22",

    icon: MarkupTextIcon,
  },
  [MODES_IDS.markup_text]: {
    width: "22",
    height: "22",
    color: "#323232",
    viewBox: "0 0 22 22",

    icon: MarkupTextIcon,
  },
  [MODES_IDS.markup_ellipse]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",

    icon: MarkupEllipseIcon,
  },
  [MODES_IDS.markup_polygon]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",

    icon: MarkupPolygonIcon,
  },
  [MODES_IDS.markup_freehand]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",

    icon: MarkupFreehandIcon,
  },
  [MODES_IDS.markup_rounded_box]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",

    icon: MarkupRoundedBoxIcon,
  },
  [MODES_IDS.history]: {
    width: "36",
    height: "36",
    color: "#323232",
    viewBox: "0 0 36 36",

    icon: HistoryIcon,
  },

  [EDITOR_ICON_IDS.snapshot]: {
    width: "20",
    height: "20",
    color: "#323232",
    viewBox: "0 0 20 20",

    icon: SnapshotIcon,
  },
  [EDITOR_ICON_IDS.documents]: {
    width: "24",
    height: "24",
    color: "#323232",
    viewBox: "0 0 24 24",

    icon: DocumentsIcon,
  },
};

export function PointIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M8 10H12M10 12V8M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function MarkupRoundedBoxIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M18 21.0142H6C4.343 21.0142 3 19.6712 3 18.0142V6.01416C3 4.35716 4.343 3.01416 6 3.01416H18C19.657 3.01416 21 4.35716 21 6.01416V18.0142C21 19.6712 19.657 21.0142 18 21.0142Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function MarkupFreehandIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M12.4189 12.4001L14.2859 12.0261C14.5558 11.973 14.8042 11.8415 14.9999 11.6481L20.4519 6.20008C20.8029 5.84886 21 5.37263 21 4.87608C21 4.37954 20.8029 3.90331 20.4519 3.55208V3.55208C20.1006 3.20108 19.6244 3.00391 19.1279 3.00391C18.6313 3.00391 18.1551 3.20108 17.8039 3.55208L12.3519 9.00008C12.157 9.19526 12.0241 9.44365 11.9699 9.71408L11.5999 11.5811C11.5784 11.6933 11.5847 11.809 11.6183 11.9182C11.6519 12.0274 11.7117 12.1267 11.7924 12.2075C11.8732 12.2883 11.9725 12.3481 12.0817 12.3816C12.1909 12.4152 12.3067 12.4215 12.4189 12.4001V12.4001Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.36523 14.4241C12.4892 17.5481 16.7652 18.3401 18.9102 16.1921C20.7182 14.3841 20.4372 11.0721 18.4422 8.20605"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8402 5.51222C12.0832 2.62222 7.38516 2.26122 4.76816 4.87822C1.83916 7.80722 2.63116 13.3472 6.53616 17.2522C8.24601 18.9902 10.3595 20.2779 12.6882 21.0002"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function MarkupPolygonIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M10.922 3.63004L3.68102 8.89104C3.03802 9.35804 2.77002 10.185 3.01502 10.941L5.78102 19.454C6.02602 20.209 6.73002 20.721 7.52502 20.721H16.476C17.27 20.721 17.974 20.21 18.22 19.454L20.986 10.941C21.231 10.186 20.963 9.35804 20.32 8.89104L13.078 3.63004C12.435 3.16304 11.565 3.16304 10.922 3.63004V3.63004Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function MarkupEllipseIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M18.7175 5.28249C22.4275 8.99247 22.4275 15.0075 18.7175 18.7175C15.0075 22.4275 8.99243 22.4275 5.28249 18.7175C1.5725 15.0075 1.5725 8.99243 5.28249 5.28249C8.99247 1.5725 15.0075 1.5725 18.7175 5.28249"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function MarkupTextIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M17.0462 4.95279C20.3852 8.29179 20.3852 13.7053 17.0462 17.0443C13.7072 20.3833 8.29369 20.3833 4.95469 17.0443C1.61569 13.7053 1.61569 8.29179 4.95469 4.95279C8.29279 1.61469 13.7072 1.61469 17.0462 4.95279Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.3999 18.7571V14.267C7.3999 13.844 7.4989 13.4264 7.6906 13.0493L10.9999 6.5L14.3092 13.0493C14.5009 13.4264 14.5999 13.844 14.5999 14.267V18.7571"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5306 13.6752L12.8233 14.9559L10.9999 13.5879L9.17654 14.9559L7.46924 13.6752"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function MarkupLineIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M1 1L17 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function MarkupBoxIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M18 21.0142H6C4.343 21.0142 3 19.6712 3 18.0142V6.01416C3 4.35716 4.343 3.01416 6 3.01416H18C19.657 3.01416 21 4.35716 21 6.01416V18.0142C21 19.6712 19.657 21.0142 18 21.0142Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
    </>
  );
}

export function ComparisonEditIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M18 7H10C8.34315 7 7 8.34315 7 10V18C7 19.6569 8.34315 21 10 21H18C19.6569 21 21 19.6569 21 18V10C21 8.34315 19.6569 7 18 7Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 17H6C5.20435 17 4.44129 16.6839 3.87868 16.1213C3.31607 15.5587 3 14.7956 3 14V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H14C14.7956 3 15.5587 3.31607 16.1213 3.87868C16.6839 4.44129 17 5.20435 17 6V7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.631 13.9316C10.622 13.9316 10.6131 13.9334 10.6047 13.9369C10.5964 13.9404 10.5889 13.9455 10.5826 13.9519C10.5762 13.9583 10.5712 13.966 10.5679 13.9743C10.5645 13.9827 10.5629 13.9916 10.563 14.0006C10.563 14.0143 10.5671 14.0277 10.5747 14.0391C10.5823 14.0505 10.5932 14.0593 10.6058 14.0645C10.6185 14.0697 10.6324 14.071 10.6458 14.0682C10.6592 14.0655 10.6715 14.0588 10.6811 14.0491C10.6908 14.0393 10.6972 14.0269 10.6998 14.0135C10.7023 14 10.7008 13.9861 10.6955 13.9735C10.6901 13.961 10.6811 13.9502 10.6696 13.9428C10.6581 13.9353 10.6447 13.9314 10.631 13.9316"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9309 13.9997C13.9309 14.0087 13.9327 14.0176 13.9362 14.0259C13.9397 14.0343 13.9448 14.0418 13.9512 14.0481C13.9576 14.0545 13.9652 14.0594 13.9736 14.0628C13.982 14.0662 13.9909 14.0678 13.9999 14.0677C14.0136 14.0677 14.027 14.0636 14.0384 14.056C14.0497 14.0484 14.0586 14.0375 14.0638 14.0249C14.0689 14.0122 14.0703 13.9983 14.0675 13.9849C14.0648 13.9714 14.0581 13.9592 14.0483 13.9495C14.0386 13.9399 14.0262 13.9334 14.0128 13.9309C13.9993 13.9283 13.9854 13.9299 13.9728 13.9352C13.9602 13.9406 13.9495 13.9496 13.9421 13.9611C13.9346 13.9726 13.9307 13.986 13.9309 13.9997"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 14.07C17 14.079 17.0018 14.0879 17.0053 14.0963C17.0088 14.1046 17.0139 14.1121 17.0203 14.1184C17.0267 14.1248 17.0343 14.1298 17.0427 14.1331C17.051 14.1365 17.06 14.1381 17.069 14.138C17.0827 14.138 17.0961 14.1339 17.1074 14.1263C17.1188 14.1187 17.1277 14.1078 17.1328 14.0952C17.138 14.0825 17.1393 14.0686 17.1366 14.0552C17.1339 14.0418 17.1272 14.0295 17.1174 14.0199C17.1077 14.0102 17.0953 14.0038 17.0818 14.0012C17.0684 13.9987 17.0545 14.0002 17.0419 14.0055C17.0293 14.0109 17.0186 14.0199 17.0111 14.0314C17.0037 14.0429 16.9998 14.0563 17 14.07"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9309 11.07C13.9309 11.079 13.9327 11.0879 13.9362 11.0963C13.9397 11.1046 13.9448 11.1121 13.9512 11.1184C13.9576 11.1248 13.9652 11.1298 13.9736 11.1331C13.982 11.1365 13.9909 11.1381 13.9999 11.138C14.0136 11.138 14.027 11.1339 14.0384 11.1263C14.0497 11.1187 14.0586 11.1078 14.0638 11.0952C14.0689 11.0825 14.0703 11.0686 14.0675 11.0552C14.0648 11.0418 14.0581 11.0295 14.0483 11.0199C14.0386 11.0102 14.0262 11.0038 14.0128 11.0012C13.9993 10.9987 13.9854 11.0002 13.9728 11.0055C13.9602 11.0109 13.9495 11.0199 13.9421 11.0314C13.9346 11.0429 13.9307 11.0563 13.9309 11.07"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9309 17.3688C13.9309 17.3778 13.9327 17.3868 13.9362 17.3951C13.9397 17.4034 13.9448 17.4109 13.9512 17.4173C13.9576 17.4236 13.9652 17.4286 13.9736 17.4319C13.982 17.4353 13.9909 17.437 13.9999 17.4368C14.0136 17.4368 14.027 17.4328 14.0384 17.4251C14.0497 17.4175 14.0586 17.4067 14.0638 17.394C14.0689 17.3813 14.0703 17.3674 14.0675 17.354C14.0648 17.3406 14.0581 17.3283 14.0483 17.3187C14.0386 17.3091 14.0262 17.3026 14.0128 17.3C13.9993 17.2975 13.9854 17.299 13.9728 17.3044C13.9602 17.3097 13.9495 17.3187 13.9421 17.3302C13.9346 17.3417 13.9307 17.3551 13.9309 17.3688"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function RectangleIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M4.16406 5.83301V15.833"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8359 14.167L15.8359 4.16699"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6641 17.5003H14.9974C14.5374 17.5003 14.1641 17.127 14.1641 16.667V15.0003C14.1641 14.5403 14.5374 14.167 14.9974 14.167H16.6641C17.1241 14.167 17.4974 14.5403 17.4974 15.0003V16.667C17.4974 17.127 17.1241 17.5003 16.6641 17.5003Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5.83333H3.33333C2.87333 5.83333 2.5 5.46 2.5 5V3.33333C2.5 2.87333 2.87333 2.5 3.33333 2.5H5C5.46 2.5 5.83333 2.87333 5.83333 3.33333V5C5.83333 5.46 5.46 5.83333 5 5.83333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16406 15.833L14.1641 15.833"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8359 4.16699H5.83594"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function CursorIcon({ color = "#323232" }) {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1859 19.1676L21.9218 22.5976C22.0784 22.9076 21.9543 23.2859 21.6443 23.4426L19.7734 24.3892C19.4634 24.5459 19.0851 24.4217 18.9284 24.1117L17.1926 20.6817L14.2009 22.5542L14.2076 12.1709C14.2076 11.6526 14.8001 11.3567 15.2143 11.6684L23.4651 17.8659L20.1859 19.1676Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function MergeIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M14.668 19.666C14.668 20.5868 15.4138 21.3327 16.3346 21.3327"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3346 16.3327C21.3346 15.4118 20.5888 14.666 19.668 14.666"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.668 17.1667V12.1667C14.668 11.2458 15.4138 10.5 16.3346 10.5H23.8346C24.7555 10.5 25.5013 11.2458 25.5013 12.1667V19.6667C25.5013 20.5875 24.7555 21.3333 23.8346 21.3333H18.8346"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 18.8327V23.8327C21.3333 24.7535 20.5875 25.4993 19.6667 25.4993H12.1667C11.2458 25.4993 10.5 24.7535 10.5 23.8327V16.3327C10.5 15.4118 11.2458 14.666 12.1667 14.666H17.1667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function ScaleIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M11.5273 19.9688L13.2148 21.6571"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5938 14.9043L18.2821 16.5926"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2188 18.2812L14.3446 19.4062"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9062 16.5938L16.0321 17.7187"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2812 13.2168L19.4062 14.3426"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9688 11.5293L21.0946 12.6543"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5313 10.9648L10.9653 20.5308C10.3437 21.1524 10.3437 22.1601 10.9653 22.7817L13.2163 25.0327C13.8379 25.6543 14.8456 25.6543 15.4672 25.0327L25.0332 15.4667C25.6548 14.8451 25.6548 13.8373 25.0332 13.2158L22.7823 10.9648C22.1607 10.3432 21.1529 10.3432 20.5313 10.9648Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function SplitIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M11.4443 19.666V21.9993C11.4443 23.1039 12.3398 23.9993 13.4443 23.9993H22.4443C23.5489 23.9993 24.4443 23.1039 24.4443 21.9993V19.666"
        stroke={color}
      />
      <path
        d="M24.4443 15.3333V13C24.4443 11.8954 23.5489 11 22.4443 11H13.4443C12.3398 11 11.4443 11.8954 11.4443 13V15.3333"
        stroke={color}
      />
      <line
        x1="25.3889"
        y1="17.2773"
        x2="10.5"
        y2="17.2773"
        stroke={color}
        strokeLinecap="round"
        strokeDasharray="2 2"
      />
    </>
  );
}

export function PolygonIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M4.16406 5.83301V14.1663"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8359 14.1663V5.83301"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6641 17.5003H14.9974C14.5374 17.5003 14.1641 17.127 14.1641 16.667V15.0003C14.1641 14.5403 14.5374 14.167 14.9974 14.167H16.6641C17.1241 14.167 17.4974 14.5403 17.4974 15.0003V16.667C17.4974 17.127 17.1241 17.5003 16.6641 17.5003Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 17.5003H3.33333C2.87333 17.5003 2.5 17.127 2.5 16.667V15.0003C2.5 14.5403 2.87333 14.167 3.33333 14.167H5C5.46 14.167 5.83333 14.5403 5.83333 15.0003V16.667C5.83333 17.127 5.46 17.5003 5 17.5003Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6641 5.83333H14.9974C14.5374 5.83333 14.1641 5.46 14.1641 5V3.33333C14.1641 2.87333 14.5374 2.5 14.9974 2.5H16.6641C17.1241 2.5 17.4974 2.87333 17.4974 3.33333V5C17.4974 5.46 17.1241 5.83333 16.6641 5.83333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5.83333H3.33333C2.87333 5.83333 2.5 5.46 2.5 5V3.33333C2.5 2.87333 2.87333 2.5 3.33333 2.5H5C5.46 2.5 5.83333 2.87333 5.83333 3.33333V5C5.83333 5.46 5.46 5.83333 5 5.83333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83594 15.833H14.1693"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1693 4.16699H5.83594"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function HandIcon({ color = "#1C2931" }) {
  return (
    <>
      <path
        d="M16.3333 17.9993V10.916C16.3333 10.226 16.8933 9.66602 17.5833 9.66602V9.66602C18.2733 9.66602 18.8333 10.226 18.8333 10.916V17.9993"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3333 12.584C16.3333 11.894 15.7733 11.334 15.0833 11.334V11.334C14.3933 11.334 13.8333 11.894 13.8333 12.584V18.0007"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8333 12.584C18.8333 11.894 19.3933 11.334 20.0833 11.334V11.334C20.7733 11.334 21.3333 11.894 21.3333 12.584V17.1673V19.6673L22.9174 18.0832C23.4233 17.5773 24.2433 17.5773 24.7491 18.0832V18.0832C25.1858 18.5198 25.2533 19.204 24.9108 19.7173L21.9841 24.1073C21.0566 25.4982 19.4958 26.334 17.8241 26.334H16.3333C13.5716 26.334 11.3333 24.0957 11.3333 21.334V17.1673V14.2507C11.3333 13.5607 11.8933 13.0007 12.5833 13.0007V13.0007C13.2733 13.0007 13.8333 13.5607 13.8333 14.2507"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function CutIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M10.5 23.834C10.5 24.7548 11.2458 25.5007 12.1667 25.5007"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 21.334V18.834"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1667 14.666C11.2458 14.666 10.5 15.4118 10.5 16.3327"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.668 25.5007C20.5888 25.5007 21.3346 24.7548 21.3346 23.834"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.168 25.5H14.668"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3346 21.3327V16.3327C21.3346 15.4118 20.5888 14.666 19.668 14.666H14.668"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.668 14.6667V12.1667C14.668 11.2458 15.4138 10.5 16.3346 10.5H23.8346C24.7555 10.5 25.5013 11.2458 25.5013 12.1667V19.6667C25.5013 20.5875 24.7555 21.3333 23.8346 21.3333H21.3346"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function DrawLineIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M15 5L5 15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 19H2C1.448 19 1 18.552 1 18V16C1 15.448 1.448 15 2 15H4C4.552 15 5 15.448 5 16V18C5 18.552 4.552 19 4 19Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 5H16C15.448 5 15 4.552 15 4V2C15 1.448 15.448 1 16 1H18C18.552 1 19 1.448 19 2V4C19 4.552 18.552 5 18 5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function HistoryIcon({ color = "#323232" }) {
  return (
    <>
      <path
        d="M17.9998 12.159H12.1641"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0005 12.1641C21.6839 12.1641 24.6699 15.1501 24.6699 18.8335C24.6699 22.5169 21.6839 25.503 18.0005 25.503C14.3171 25.503 11.3311 22.5169 11.3311 18.8335"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6651 9.65625L12.1641 12.1573"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6651 14.6651L12.1641 12.1641"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5008 19.4522H17.3262V16.1875"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function SnapshotIcon({ color = "#323232" }) {
  return (
    <>
      <path
        fill={color}
        d="M10.0003 12.914C9.19824 12.914 8.51161 12.6284 7.94043 12.0573C7.36925 11.4861 7.08366 10.7994 7.08366 9.99738C7.08366 9.19531 7.36925 8.50868 7.94043 7.9375C8.51161 7.36632 9.19824 7.08073 10.0003 7.08073C10.8024 7.08073 11.489 7.36632 12.0602 7.9375C12.6314 8.50868 12.917 9.19531 12.917 9.99738C12.917 10.7994 12.6314 11.4861 12.0602 12.0573C11.489 12.6284 10.8024 12.914 10.0003 12.914ZM10.0003 11.664C10.4586 11.664 10.851 11.5008 11.1774 11.1745C11.5038 10.8481 11.667 10.4557 11.667 9.99738C11.667 9.53904 11.5038 9.14668 11.1774 8.82029C10.851 8.4939 10.4586 8.33071 10.0003 8.33071C9.54197 8.33071 9.14961 8.4939 8.82322 8.82029C8.49683 9.14668 8.33364 9.53904 8.33364 9.99738C8.33364 10.4557 8.49683 10.8481 8.82322 11.1745C9.14961 11.5008 9.54197 11.664 10.0003 11.664ZM4.42605 17.0807C4.00335 17.0807 3.64616 16.9349 3.35449 16.6432C3.06283 16.3515 2.91699 15.9943 2.91699 15.5716V12.4974H4.16697V15.5743C4.16697 15.6384 4.19368 15.6972 4.2471 15.7506C4.30053 15.804 4.3593 15.8307 4.42341 15.8307H7.5003V17.0807H4.42605ZM12.5003 17.0807V15.8307H15.5772C15.6413 15.8307 15.7001 15.804 15.7535 15.7506C15.8069 15.6972 15.8336 15.6384 15.8336 15.5743V12.4974H17.0836V15.5716C17.0836 15.9943 16.9378 16.3515 16.6461 16.6432C16.3545 16.9349 15.9973 17.0807 15.5746 17.0807H12.5003ZM2.91699 7.49738V4.42313C2.91699 4.00042 3.06283 3.64323 3.35449 3.35156C3.64616 3.0599 4.00335 2.91406 4.42605 2.91406H7.5003V4.16404H4.42341C4.3593 4.16404 4.30053 4.19075 4.2471 4.24417C4.19368 4.2976 4.16697 4.35637 4.16697 4.42048V7.49738H2.91699ZM15.8336 7.49738V4.42048C15.8336 4.35637 15.8069 4.2976 15.7535 4.24417C15.7001 4.19075 15.6413 4.16404 15.5772 4.16404H12.5003V2.91406H15.5746C15.9973 2.91406 16.3545 3.0599 16.6461 3.35156C16.9378 3.64323 17.0836 4.00042 17.0836 4.42313V7.49738H15.8336Z"
      />
    </>
  );
}

export function DocumentsIcon({ color = "#323232" }) {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 8H15C14.448 8 14 7.552 14 7V3"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11H14"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14H14"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 17H12.33"
        stroke="#1C2931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}
