import request from "../request";

export async function detectRegions(vector, features) {
  const searchQuery = window.location.search;

  const { body } = await request.post(
    `togal/scale/${vector.id}?${searchQuery}`,
    {
      body: features,
    }
  );
  return body;
}

export async function detectSymbol(
  vector,
  bounding_box,
  organization_id = null
) {
  const { body } = await request.post(`togal/symbols`, {
    body: {
      bounding_box,
      view_id: vector.id,
      ...(organization_id ? { organization_id } : {}),
    },
  });
  return body;
}

export async function detectHistorySymbol(
  vector,
  symbolId,
  organization_id = null
) {
  const { body } = await request.post(`togal/symbols`, {
    body: {
      view_id: vector.id,
      symbol_id: symbolId,
      ...(organization_id ? { organization_id } : {}),
    },
  });
  return body;
}

export async function detectSymbolInSet(
  vector,
  bounding_box,
  organization_id = null
) {
  const { body } = await request.post(`togal/symbols`, {
    body: {
      bounding_box,
      view_id: vector.id,
      set_id: vector.set_id,
      ...(organization_id ? { organization_id } : {}),
    },
  });
  return body;
}

export async function detectHistorySymbolInSet(
  vector,
  symbolId,
  organization_id = null
) {
  const { body } = await request.post(`togal/symbols`, {
    body: {
      view_id: vector.id,
      symbol_id: symbolId,
      set_id: vector.set_id,
      ...(organization_id ? { organization_id } : {}),
    },
  });
  return body;
}

export async function detectPatterns(vector, bounding_box) {
  const { body } = await request.post(`togal/patterns`, {
    body: {
      bounding_box,
      view_id: vector.id,
    },
  });
  return body;
}

export async function getPipeline(payload) {
  const { body } = await request.post(`togal/calculate_pipes_layout`, {
    body: payload,
  });
  return body;
}
