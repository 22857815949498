import kinks from "@turf/kinks";
import turfIntersect from "@turf/intersect";
import turfDifference from "@turf/difference";

import { generateId } from "../../utils/string";
import { head } from "../../utils/functional/list";
import { GEOJSON_TYPES } from "../../consts/editor";
import { CommonDrawMode } from "../draw/common-draw";
import { makeFeature } from "../base/ImmutableLayersData";
import { getCleanedShapes, getIntersectFeatures } from "./utils";

export class CutMode extends CommonDrawMode {
  handleAddFeature = (event: any, props: any) => {
    const isCtrlCmd = event.ctrlKey || event.metaKey;

    const clickSequence = this.getClickSequence();

    if (clickSequence?.length > 2) {
      const mainCoordinates = this.mainFeature?.properties?.closed
        ? this.mainFeature.geometry.coordinates[0]
        : clickSequence;
      const coordinates: any = [[...mainCoordinates, head(mainCoordinates)]];

      const feature: any = {
        type: GEOJSON_TYPES.Feature,
        properties: {
          arcs: this._arcs,
        },
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates,
        },
      };

      const featureKinks = kinks(feature);
      const isEdgeIntersection = featureKinks?.features?.length > 1;

      if (
        (isEdgeIntersection && !this.mainFeature?.properties?.closed) ||
        this._lock
      ) {
        return;
      }

      this.applyCutAction(feature, props, isCtrlCmd);

      this._resetState();
    }
  };

  applyCutAction = (feature: any, props: any, isCtrlCmd = false): any => {
    const { view, colorMap } = props.modeConfig;

    const intersectedFeatures = getIntersectFeatures(feature, props);

    if (!intersectedFeatures?.length) return null;

    let newFeatures = [];
    const originalFeatures = [];

    for (const selectedFeature of intersectedFeatures) {
      this.deleteFeatures([selectedFeature]);
      originalFeatures.push(selectedFeature);

      if (selectedFeature.geometry.type === GEOJSON_TYPES.Polygon) {
        let difference = null;
        if (isCtrlCmd) {
          difference = turfIntersect(selectedFeature, feature);
        } else {
          difference = turfDifference(selectedFeature, feature);
        }

        getCleanedShapes([difference]).forEach((cleanedShape) => {
          newFeatures.push({
            ...cleanedShape,
            properties: {
              ...selectedFeature.properties,
              id: generateId(),
            },
          });
        });
      }

      if (selectedFeature.geometry.type === GEOJSON_TYPES.MultiPolygon) {
        const cleanedShapes = getCleanedShapes([selectedFeature]);
        for (const cleanedShape of cleanedShapes) {
          const isIntersect = turfIntersect(
            cleanedShape as any,
            feature as any
          );

          if (isIntersect) {
            let difference = null;
            if (isCtrlCmd) {
              difference = turfIntersect(selectedFeature, feature);
            } else {
              difference = turfDifference(selectedFeature, feature);
            }

            getCleanedShapes([difference]).forEach((cleanedShape) => {
              newFeatures.push({
                ...cleanedShape,
                properties: {
                  ...selectedFeature.properties,
                  id: generateId(),
                },
              });
            });
          } else {
            newFeatures.push({
              ...cleanedShape,
              properties: {
                ...selectedFeature.properties,
                id: generateId(),
              },
            });
          }
        }
      }
    }

    newFeatures = newFeatures.map((newFeature) =>
      makeFeature({
        geometry: newFeature.geometry,
        id: newFeature.properties.id,
        name: newFeature.properties.name,
        view,
        clMap: colorMap,
        className: newFeature.properties.className,
        featureProperties: newFeature.properties,
      })
    );
    // add fix holes

    this.addFeatures(newFeatures);
    this.applyActions(props, true, {
      ignoreHover: true,
    });
  };
}
