import { Position } from "geojson";
import ellipse from "@turf/ellipse";
import { point } from "@turf/helpers";
import distance from "@turf/distance";
import bboxPolygon from "@turf/bbox-polygon";

import { DEFAULT_PADDING } from "../../consts/coordinates";

import { divide } from "../../utils/functional/math";
import { getIntermediatePosition } from "../base/GeojsonEditMode";
import { DrawMarkupRectangleMode } from "./draw-markup-rectangle";

export class DrawEllipseMarkupMode extends DrawMarkupRectangleMode {
  getTwoClickPolygon(coord1: Position, coord2: Position) {
    const minX = Math.min(coord1[0], coord2[0]);
    const minY = Math.min(coord1[1], coord2[1]);
    const maxX = Math.max(coord1[0], coord2[0]);
    const maxY = Math.max(coord1[1], coord2[1]);
    const padding = DEFAULT_PADDING;

    const polygonPoints = bboxPolygon([
      minX / padding,
      minY / padding,
      maxX / padding,
      maxY / padding,
    ]).geometry.coordinates[0];

    const centerCoordinates = getIntermediatePosition(
      coord1.map(divide(padding)),
      coord2.map(divide(padding))
    );

    const xSemiAxis = Math.max(
      distance(point(polygonPoints[0]), point(polygonPoints[1])),
      0.001
    );
    const ySemiAxis = Math.max(
      distance(point(polygonPoints[0]), point(polygonPoints[3])),
      0.001
    );

    let geojson = ellipse(centerCoordinates, xSemiAxis, ySemiAxis);

    geojson = {
      ...geojson,
      geometry: {
        ...geojson.geometry,
        coordinates: [
          geojson.geometry.coordinates[0].map((arr) => [
            arr[0] * padding,
            arr[1] * padding,
          ]),
        ],
      },
    };

    return geojson;
  }
}
