import bbox from "@turf/bbox";
import { Point, Feature } from "geojson";
import bboxPolygon from "@turf/bbox-polygon";
import { point, featureCollection } from "@turf/helpers";

import {
  getEditHandlesForGeometry,
  getPickedExistingEditHandle,
} from "../utils/modes";
import GeoJsonEditMode from "./base/GeojsonEditMode";
import polygonToLine from "../utils/turf/polygon-to-line";
import { GEOJSON_TYPES, MODES_IDS } from "../consts/editor";

export class PanMode extends GeoJsonEditMode {
  private _isPanning: boolean;

  constructor() {
    super();

    this._isPanning = false;
  }

  // edited from  : https://github.com/uber/nebula.gl/blob/master/modules/edit-modes/src/lib/modify-mode.ts
  getGuides = (props: any) => {
    const { data, lastPointerMoveEvent, selectedIndexes } = props;
    const handles = [];

    if (selectedIndexes.length === 0) return;
    const { features } = data;

    const picks = lastPointerMoveEvent && lastPointerMoveEvent.picks;
    const mapCoords = lastPointerMoveEvent && lastPointerMoveEvent.mapCoords;

    const isMarkup =
      selectedIndexes.length === 1 &&
      features[selectedIndexes[0]].properties.types.includes(
        GEOJSON_TYPES.markup
      ) &&
      !features[selectedIndexes[0]].properties.types.includes(
        MODES_IDS.markup_line
      );

    if (isMarkup) {
      const selectedGeometry =
        this.getSelectedFeaturesAsFeatureCollection(props);
      const boundingBox = bboxPolygon(bbox(selectedGeometry));
      boundingBox.properties.mode = "scale";
      const cornerGuidePoints: any[] = [];

      boundingBox.geometry.coordinates[0].forEach(
        (coord: any, coordIndex: number) => {
          if (coordIndex < 4) {
            // Get corner midpoint guides from the enveloping box
            const cornerPoint = point(coord, {
              guideType: "editHandle",
              editHandleType: "scale",
              positionIndexes: [coordIndex],
              featureIndex: 0,
            });
            cornerGuidePoints.push(cornerPoint);
          }
        }
      );

      if (cornerGuidePoints.length !== 4) return;

      return featureCollection([
        polygonToLine(boundingBox),
        ...cornerGuidePoints,
      ]);
    }

    const isLine =
      selectedIndexes.length > 0 &&
      (features[selectedIndexes[0]].geometry.type ===
        GEOJSON_TYPES.LineString ||
        features[selectedIndexes[0]].geometry.type ===
          GEOJSON_TYPES.MultiLineString);

    for (const index of selectedIndexes) {
      if (index < features.length) {
        const { geometry } = features[index];
        handles.push(...getEditHandlesForGeometry(geometry, index));
      } else {
        console.warn(`selectedFeatureIndexes out of range ${index}`); // eslint-disable-line no-console
      }
    }

    if (isLine) {
      return {
        type: GEOJSON_TYPES.FeatureCollection,
        features: handles,
      };
    }

    if (picks && picks.length && mapCoords) {
      const existingEditHandle = getPickedExistingEditHandle(picks);
      const featureAsPick =
        !existingEditHandle && picks.find((pick: any) => !pick.isGuide);

      let intermediatePoint: Feature<Point> = null;
      let positionIndexPrefix = [];

      if (!intermediatePoint && !existingEditHandle && picks.length) {
        const intermediatePick = picks.find((pick: any) =>
          selectedIndexes.includes(pick.object.properties.featureIndex)
        );
        if (intermediatePick) {
          intermediatePoint = {
            ...intermediatePick.object,
            properties: {
              ...intermediatePick.object.properties,
              index: intermediatePick.object.properties.positionIndexes[1] - 1,
            },
          };

          positionIndexPrefix =
            intermediatePick.object.properties.positionIndexes;
        }
      }

      if (intermediatePoint) {
        const {
          geometry: { coordinates: position },
          properties: { index },
        } = intermediatePoint;

        handles.unshift({
          type: GEOJSON_TYPES.Feature,
          properties: {
            guideType: "editHandle",
            editHandleType: "intermediate",
            featureIndex: featureAsPick
              ? featureAsPick.index
              : intermediatePoint.properties.featureIndex,
            positionIndexes: featureAsPick
              ? [...positionIndexPrefix, index + 1]
              : positionIndexPrefix,
          },
          geometry: {
            type: GEOJSON_TYPES.Point,
            coordinates: position,
          },
        });
      }
    }

    return {
      type: GEOJSON_TYPES.FeatureCollection,
      features: handles,
    };
  };

  get3dGuides = (props: any) => {
    const {
      is3dMode,
      meshData,
      staticData,
      colorMap,
      referenceInchesPerPixels,
    } = props.modeConfig;
    if (!is3dMode) return null;

    const guides3dMeshes = [];

    if (meshData) {
      guides3dMeshes.push(meshData);
    }

    const data = [...props.data?.features];

    if (staticData?.length > 0) {
      data.push(...staticData);
    }

    if (data?.length === 0) return null;

    const dataState = {
      dataL: props.data?.features?.length || 0,
      staticDataL: staticData?.length || 0,
    };

    const didStateChange =
      dataState?.dataL !== this._previousState?.dataL ||
      dataState?.staticDataL !== this._previousState?.staticDataL;

    if (didStateChange) {
      this._previousState = dataState;
    }

    if (!this._isPreparingMesh3d && (!this._mesh3d || didStateChange)) {
      this.generateMesh(data, colorMap, referenceInchesPerPixels);
    }

    if (this._mesh3d) {
      guides3dMeshes.push(this._mesh3d);
    }

    return guides3dMeshes;
  };

  handleStartDragging = () => {
    this._isPanning = true;
  };

  handleStopDragging = () => {
    this._isPanning = false;
  };

  handlePointerMove = (event: any, props: any) => {
    if (this._isPanning) {
      props.onUpdateCursor("grabbing");
      return;
    }

    props.onUpdateCursor("grab");
    return;
  };
}
