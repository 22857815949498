import {
  QUANTITIES_UOM,
  QUANTITY_CUTSOM,
} from "../../../consts/classifications";

import {
  MATH_TOKENS,
  MATH_OPERATORS,
  NUMBERS_PATTERN,
  REVERSE_MAP_LIST,
  QUANTITY_FORMULAS,
  ALL_ALLOWED_STRINGS,
  QUANTITY_FORMULAS_SET,
  FORMULA_FUNCTIONS_KEYS,
  QUANTITY_FORMULAS_CLS_MAP,
  QUANTITY_FORMULAS_CLS_QMAP,
  ALL_ALLOWED_STRINGS_PATTERNS,
} from "./const";

export function cleanStringSpaces(str: string): string {
  return str.replace(/\s+/g, " ");
}

function cleanFormulaQuantities(formula: string, cls?: any): string {
  let cleanedFormula = formula;
  let hasReplacements = true;

  while (hasReplacements) {
    hasReplacements = false;

    for (const quantityKey of [...QUANTITY_FORMULAS_SET]) {
      if (cleanedFormula.includes(quantityKey)) {
        const quantityMeasure =
          cls?.[QUANTITY_FORMULAS_CLS_QMAP[quantityKey]] ?? "";
        const quanityMeasureUOM =
          cls?.[QUANTITIES_UOM[QUANTITY_FORMULAS_CLS_QMAP[quantityKey]]] ?? "";

        if (quantityMeasure === QUANTITY_CUTSOM) {
          const quantityFormulaKey = QUANTITY_FORMULAS_CLS_MAP[quantityKey];
          const quantityFormula = cls?.[quantityFormulaKey] ?? "";
          cleanedFormula = cleanedFormula.replaceAll(
            quantityKey,
            quantityFormula ? `(${quantityFormula.replaceAll("=", "")})` : ""
          );
        } else {
          const measureKey =
            REVERSE_MAP_LIST[quantityMeasure + quanityMeasureUOM];

          if (measureKey) {
            cleanedFormula = cleanedFormula.replaceAll(quantityKey, measureKey);
          } else {
            cleanedFormula = cleanedFormula.replaceAll(quantityKey, "");
          }
        }

        hasReplacements = true;
      }
    }
  }

  return cleanedFormula;
}

function getFormulaTokens(
  formula: string,
  quantityKey: string,
  returnAllTokens = false
): string[] {
  const quantityTokens = QUANTITY_FORMULAS[quantityKey] ?? [];

  return formula
    .split(/([=+\-*/%(), ])/)
    .map((token) => (token === " " ? " " : token.trim()))
    .filter(Boolean)
    .filter((token) =>
      returnAllTokens
        ? true
        : token === " " ||
          NUMBERS_PATTERN.test(token) ||
          MATH_OPERATORS.includes(token) ||
          quantityTokens.find((t) => t === token) ||
          ALL_ALLOWED_STRINGS.find((t) => t === token)
    );
}

export function getValidFormula(
  formula: string,
  quantityKey: string,
  cls?: any,
  returnAllTokens = false
): {
  validFormula: string;
  validTokens: string[];
  validFormulaQuantities: string;
  validTokensQuantities: string[];
} {
  const quantityCleanedFormula = cleanFormulaQuantities(formula, cls);

  const tokens = getFormulaTokens(formula, quantityKey, returnAllTokens);
  const cleanedFormulaTokens = getFormulaTokens(
    quantityCleanedFormula,
    quantityKey
  );

  return {
    validTokens: tokens,
    validFormula: tokens.join(""),

    validTokensQuantities: cleanedFormulaTokens,
    validFormulaQuantities: cleanedFormulaTokens.join(""),
  };
}

export function getFormulaInputError(
  formula: string,
  quantityKey: string,
  cls: any
): string {
  const { validTokens } = getValidFormula(formula, quantityKey);

  const filteredTokens = validTokens.filter((t) => t !== " ");

  const quantityTokens = QUANTITY_FORMULAS[quantityKey] ?? [];
  const quantityFormulas = Object.keys(FORMULA_FUNCTIONS_KEYS);

  for (let i = 0; i < filteredTokens.length; i++) {
    const token = filteredTokens[i];

    if (quantityFormulas.includes(token)) {
      const tokenKey = FORMULA_FUNCTIONS_KEYS[token];

      if (!cls?.[tokenKey]) {
        return `Invalid formula, please fill in the "${tokenKey}" field`;
      }
    }

    if (
      ALL_ALLOWED_STRINGS_PATTERNS.test(token) ||
      quantityTokens.includes(token)
    ) {
      const previousToken = filteredTokens[i - 1];
      if (![...MATH_TOKENS, "(", ","].includes(previousToken)) {
        return `Unexpected token before "${token}", expected a math operator`;
      }
    }
  }

  return null;
}
