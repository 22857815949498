import { FeatureCollection } from "geojson";

import { last } from "../../utils/functional/list";
import { GEOJSON_TYPES } from "../../consts/editor";
import GeoJsonEditMode from "../base/GeojsonEditMode";
import {
  lineToPolygon,
  generateJoints,
  getNewDrainLine,
  getPipelineWidth,
  generate3dPipeline,
  roundFeatureCoords,
  getUpdatedPipelines,
  updatePipelineHeight,
  lineFeatureToSegments,
  getPipelineModeDetails,
  getPipelinesNearestPoint,
  convertPipelineApiToNormalPipelines,
} from "../../utils/pipeline";

import {
  snapToDegAbs,
  makeArrowFromLine,
  createSnapTargets,
  getDistanceTooltip,
  createTentativeFeature,
  getSnappedFeatureToLengths,
  updatePipeLineFeatureCoordinates,
} from "../../utils/coordinates";
import { RGB_COLORS } from "../../consts/style";
import { KEYS } from "../../consts/keysAndMouse";
import { SNAP_ANGLES_S } from "../../consts/coordinates";
import { getTwoClickPolygon } from "../../utils/geometry";
import { translateFeatures } from "../../modes/edit/editUtils";
import booleanIntersects from "../../utils/turf/boolean-intersects";
import { PIPE_VECTOR_TYPE, PIPELINE_WIDTHS } from "../../consts/pipeline";
import {
  distance2d,
  getEditHandlesForFeature,
  getPickedPipes,
} from "../../utils/modes";

export class DrawPipelineMode extends GeoJsonEditMode {
  mainFeature: any;
  _arrowFeature: any;
  _cache: any = new Map();
  _selection: any = new Set();
  _isFrozen: boolean = false;
  _isHeightMode: boolean = false;

  _snapTargets: any = null;

  _isDragging: boolean = false;
  _draggingFeatures: any = [];
  _draggingStartCoords: any = [];
  _featuresBeforeDragging: any = [];

  _joints: any = [];
  _nearestPoint: any;

  _pipelines: any = [];
  _pipelinesApi: any = [];
  _pipelineMesh: any = null;
  _pipelineApiMesh: any = null;
  _mainFeatureMesh: any = null;

  _isWKeyDown: boolean = false;
  _isXKeyDown: boolean = false;

  _pipelineWidth: number = PIPELINE_WIDTHS[0];

  constructor() {
    super();
  }

  _resetState = () => {
    this.resetClickSequence();

    this.mainFeature = null;
    this._mainFeatureMesh = null;

    this._isHeightMode = false;
  };

  get3dGuides = (props: any) => {
    const { is3dMode } = props.modeConfig;

    if (is3dMode) {
      return [
        this._pipelineMesh,
        this._pipelineApiMesh,
        this._mainFeatureMesh,
      ].filter(Boolean);
    }

    return [];
  };

  getGuides = (props: any) => {
    const { lastPointerMoveEvent, modeConfig } = props;
    const { referenceInchesPerPixels } = modeConfig;
    const modeDetails = getPipelineModeDetails(modeConfig?.pipelineMode);
    const mapCoords = lastPointerMoveEvent?.mapCoords || [0, 0];

    const mergedPipelines = (this._pipelines || []).concat(
      this._pipelinesApi || []
    );

    if (!modeDetails.isPipelineMode && this._clickSequence.length > 0) {
      this._resetState();
    }

    const guides: FeatureCollection = {
      type: GEOJSON_TYPES.FeatureCollection,
      features: [],
    };

    if (this._arrowFeature) {
      if (!this._arrowFeature.properties._finalized) {
        const newArrowCoordiante = snapToDegAbs(
          this._arrowFeature.geometry.coordinates[0],
          [mapCoords],
          SNAP_ANGLES_S
        );
        this._arrowFeature.geometry.coordinates[1] = newArrowCoordiante;
        const arrowFeature = makeArrowFromLine(this._arrowFeature, 45);
        guides.features.push(arrowFeature);
      } else {
        guides.features.push(this._arrowFeature);
      }
    }

    if (
      mergedPipelines?.length > 0 &&
      this._clickSequence.length === 0 &&
      (modeDetails.isPipelineMode || modeDetails.isDrainMode)
    ) {
      try {
        const nearest = getPipelinesNearestPoint(mergedPipelines, mapCoords);

        if (nearest) {
          this._nearestPoint = nearest;
          guides.features.push(nearest);
        } else {
          this._nearestPoint = null;
        }
      } catch (error) {
        console.error("error", error);
        this._nearestPoint = null;
      }
    } else if (this._nearestPoint) {
      this._nearestPoint = null;
    }

    if (modeDetails.isPipelineMode) {
      const guideType = props?.modeConfig?.guideType || "tentative";

      if (this._clickSequence.length > 0) {
        const isShiftKey = lastPointerMoveEvent?.sourceEvent?.shiftKey;
        const [mainFeature, ..._features] = createTentativeFeature(
          props,
          this,
          guideType,
          false,
          false,
          {
            angles: SNAP_ANGLES_S,
          }
        );

        const mainFeatureAsLine = {
          ...mainFeature,
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates:
              mainFeature.geometry.type === GEOJSON_TYPES.MultiLineString
                ? mainFeature.geometry.coordinates[0]
                : mainFeature.geometry.coordinates,
          },
        };
        const mainLine = isShiftKey
          ? mainFeatureAsLine
          : getSnappedFeatureToLengths(
              mainFeatureAsLine,
              referenceInchesPerPixels
            );

        this.mainFeature = mainLine;
        guides.features = guides.features.concat(
          getEditHandlesForFeature(this.mainFeature, -1)
        );

        if (this.mainFeature) {
          const pipelineWidth = getPipelineWidth(
            this._pipelineWidth,
            referenceInchesPerPixels
          );
          const linePolygon = lineToPolygon(
            this.mainFeature,
            pipelineWidth,
            this._cache
          );
          if (linePolygon) {
            this.mainFeature = {
              ...this.mainFeature,
              properties: {
                ...this.mainFeature.properties,
                linePolygon,
                pipelineWidth: this._pipelineWidth,
              },
            };
            this.generateMainFeatureMesh(referenceInchesPerPixels);

            const lines = lineFeatureToSegments(
              this.mainFeature,
              true,
              this.mainFeature.properties
            ).map((f: any) => lineToPolygon(f, pipelineWidth, this._cache));
            guides.features = guides.features.concat(lines);
          }
        }
      }
    }

    if (mergedPipelines?.length > 0) {
      guides.features = guides.features.concat(
        mergedPipelines.map((p: any) => ({
          ...p.properties.linePolygon,
          properties: {
            ...p.properties.linePolygon.properties,
            color: this._selection.has(p.properties.id)
              ? RGB_COLORS.BLUE
              : p.properties.linePolygon.properties.color,
            borderColor: this._selection.has(p.properties.id)
              ? RGB_COLORS.BLUE
              : p.properties.linePolygon.properties.borderColor,
          },
        }))
      );
    }

    if (modeDetails.isEditMode) {
      mergedPipelines.forEach((p: any) => {
        if (this._selection.has(p.properties.id)) {
          guides.features = guides.features.concat(
            getEditHandlesForFeature(
              p,
              -1,
              "existing",
              false,
              p.properties.id
            ).map((handle: any) => ({
              ...handle,
              properties: {
                ...handle.properties,
                editHandleType: this._selection.has(
                  p.properties.id + "_" + handle.properties.positionIndexes[0]
                )
                  ? "magic_handle"
                  : handle.properties.editHandleType,
              },
            }))
          );
        }
      });

      if (this._draggingStartCoords.length > 0) {
        const boxFeature = getTwoClickPolygon(
          this._draggingStartCoords,
          mapCoords
        );
        guides.features.push(boxFeature);
      }
    }

    guides.features = guides.features.concat(this._joints);

    return guides;
  };

  getTooltips = (props: any) => {
    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);

    if (
      modeDetails.isEditMode &&
      this._draggingFeatures.length > 0 &&
      this._draggingFeatures[0].properties?.editHandleType
    ) {
      const parentFeature = this._pipelines.find(
        (p: any) => p.properties.id === this._draggingFeatures[0].properties.id
      );
      if (parentFeature) {
        const distanceTooltips = getDistanceTooltip(
          props,
          parentFeature,
          this,
          true
        );

        return distanceTooltips;
      }
    }

    if (modeDetails.isPipelineMode) {
      const distanceTooltips = getDistanceTooltip(
        props,
        this.mainFeature,
        this,
        true
      );

      return distanceTooltips;
    }
  };

  handleAddFeature = (event: any, props: any) => {
    const { referenceInchesPerPixels } = props.modeConfig;

    const segments = lineFeatureToSegments(
      this.mainFeature,
      true,
      this.mainFeature.properties
    ).map((f: any) => {
      const pipelineWidth = getPipelineWidth(
        this._pipelineWidth,
        referenceInchesPerPixels
      );
      const linePolygon = lineToPolygon(f, pipelineWidth, this._cache);
      linePolygon.properties.id = f.properties.id;

      return {
        ...f,
        properties: {
          ...f.properties,
          linePolygon,
        },
      };
    });

    this._pipelines.push(...segments.map(roundFeatureCoords));

    this._resetState();
    if (this._pipelines.length > 0) {
      this.generateMesh(referenceInchesPerPixels);
      this.handleUpdatePipelineFomAPI(props);
      this._joints = generateJoints(
        this._pipelines,
        this._cache,
        referenceInchesPerPixels
      );
    }
  };

  handleUpdatePipelineFomAPI = async (props: any) => {
    const vectorFeatures = [...props.data.features];
    const { geoJson, handleProccessPipeline } = props.modeConfig;

    if (this._arrowFeature) {
      vectorFeatures.push(this._arrowFeature);
    }

    try {
      const pipelineFeatures = await handleProccessPipeline(
        vectorFeatures,
        geoJson,
        this._pipelines
      );

      if (pipelineFeatures?.length > 0) {
        this._pipelinesApi = pipelineFeatures.map(roundFeatureCoords);

        this._pipelineApiMesh = await generate3dPipeline(
          this._pipelinesApi,
          0.25,
          0
        );
      } else {
        this._pipelinesApi = [];
        this._pipelineApiMesh = null;
      }
    } catch (error) {
      this._pipelinesApi = [];
      this._pipelineApiMesh = null;
      console.error("error", error);
    }
  };

  generateMesh = async (referenceInchesPerPixels: number) => {
    this._pipelineMesh = await generate3dPipeline(
      this._pipelines,
      referenceInchesPerPixels,
      0
    );
  };

  generateMainFeatureMesh = async (referenceInchesPerPixels: number) => {
    if (this.mainFeature) {
      const mainFeatureLines = lineFeatureToSegments(
        this.mainFeature,
        true,
        this.mainFeature.properties
      );

      this._mainFeatureMesh = await generate3dPipeline(
        mainFeatureLines,
        referenceInchesPerPixels,
        0
      );
    }
  };

  handleDblClick = (event: any, props: any) => {
    const { referenceInchesPerPixels } = props.modeConfig;

    const pickedFeature = getPickedPipes(event.picks)[0] || null;
    const apiPipeline =
      pickedFeature &&
      this._pipelinesApi.find(
        (p: any) => p?.properties?.id === pickedFeature?.properties?.id
      );

    if (apiPipeline) {
      const convertedPipes = convertPipelineApiToNormalPipelines(
        this._pipelinesApi,
        referenceInchesPerPixels
      ).map(roundFeatureCoords);
      this._pipelines = this._pipelines.concat(convertedPipes);

      this._pipelinesApi = [];
      this._pipelineApiMesh = null;
      this._arrowFeature = null;

      this.generateMesh(referenceInchesPerPixels);
    }
  };

  handleClick = (event: any, props: any) => {
    const {
      view,
      modeId,
      colorMap,
      pipelineMode,
      filteredtakeOffTypes,
      referenceInchesPerPixels,
    } = props.modeConfig;
    const isShiftKey = event.sourceEvent.shiftKey;

    const modeDetails = getPipelineModeDetails(pipelineMode);

    if (modeDetails?.isEditMode) {
      const isShiftKey = event.sourceEvent.shiftKey;
      const picks = getPickedPipes(event.picks);

      if (
        picks?.length > 0 &&
        picks[0]?.geometry?.type === GEOJSON_TYPES.Polygon
      ) {
        const featureId = picks[0]?.properties?.id;

        if (isShiftKey) {
          if (this._selection.has(featureId)) {
            this._selection.delete(featureId);
          } else {
            this._selection.add(featureId);
          }
        } else {
          this._selection = new Set([featureId]);
        }
      }

      if (!picks?.length) {
        this._selection = new Set();
      }
    }

    if (modeDetails?.isEntryMode) {
      if (!this._arrowFeature || this._arrowFeature.properties._finalized) {
        this._arrowFeature = {
          type: GEOJSON_TYPES.Feature,
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates: [event.mapCoords, event.mapCoords],
          },
          properties: {
            opacity: 100,
            thinkness: 10,
            _finalized: false,
            color: RGB_COLORS.RED,
            border: RGB_COLORS.RED,
            types: [PIPE_VECTOR_TYPE],
          },
        };
      } else {
        this._arrowFeature.properties._finalized = true;

        const arrowFeature = makeArrowFromLine(this._arrowFeature, 45);
        this._arrowFeature = arrowFeature;

        this.handleUpdatePipelineFomAPI(props);
        this._resetState();
      }
    }

    if (modeDetails.isPipelineMode) {
      const nearestCoordinates = isShiftKey
        ? null
        : this._nearestPoint?.geometry?.coordinates;

      const coordinates = this.mainFeature?.geometry?.coordinates;
      const lastCoordinate =
        coordinates?.length > 0
          ? Array.isArray(last(coordinates[0]))
            ? last(coordinates[0])
            : last(coordinates)
          : null;

      if (this._nearestPoint && !isShiftKey) {
        const updatedData = this._nearestPoint.properties.snapped
          ? this._pipelines
          : getUpdatedPipelines(
              this._pipelines,
              this._nearestPoint,
              referenceInchesPerPixels
            );
        this._pipelines = updatedData.map(roundFeatureCoords);
      }

      let mapCoords = nearestCoordinates || lastCoordinate || event.mapCoords;
      mapCoords = mapCoords.length === 2 ? [...mapCoords, 0] : mapCoords;

      this.addClickSequence({ mapCoords });
      if (this._isHeightMode) {
        this._isHeightMode = false;
      }
    }

    if (modeDetails.isDrainMode) {
      const mapCoords = event.mapCoords;
      const countClassName = pipelineMode;
      const nearestCoordinates = isShiftKey
        ? null
        : this._nearestPoint?.geometry?.coordinates;

      if (nearestCoordinates && !isShiftKey) {
        const updatedData = this._nearestPoint.properties.snapped
          ? this._pipelines
          : getUpdatedPipelines(
              this._pipelines,
              this._nearestPoint,
              referenceInchesPerPixels
            );

        const newDrainLine = getNewDrainLine(mapCoords, nearestCoordinates);

        this._pipelines = [...updatedData, roundFeatureCoords(newDrainLine)];
        this._joints = generateJoints(
          this._pipelines,
          this._cache,
          referenceInchesPerPixels
        );
      }

      const pointFeatureGeometry = {
        type: GEOJSON_TYPES.Point,
        coordinates: [mapCoords[0], mapCoords[1]],
      };

      const editAction = this.getAddFeatureAction(
        pointFeatureGeometry,
        props.data.features,
        view,
        filteredtakeOffTypes,
        countClassName,
        modeId,
        props.featureAccessKey,
        colorMap
      );
      props.onEdit({
        ...editAction,
        editContext: {
          ...editAction.editContext,
          recalculateClassifications: true,
        },
      });

      this.generateMesh(referenceInchesPerPixels);
    }

    this._joints = generateJoints(
      this._pipelines,
      this._cache,
      referenceInchesPerPixels
    );
  };

  handleStartDragging = (event: any, props: any) => {
    const { isFocused, useCanvasPan } = props.modeConfig;
    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);

    if (useCanvasPan || isFocused) {
      return;
    }

    if (modeDetails.isEditMode) {
      const isShiftKey = event.sourceEvent.shiftKey;
      this._isDragging = true;

      if (isShiftKey) {
        this._draggingStartCoords = event.mapCoords;
      } else {
        const picks = getPickedPipes(event.picks);

        const selectedPipes = this._pipelines.filter((p: any) =>
          this._selection.has(p.properties.id)
        );
        const nonSelectedPipes = this._pipelines.filter(
          (p: any) => !this._selection.has(p.properties.id)
        );

        this._snapTargets = createSnapTargets(nonSelectedPipes, []);

        if (picks?.length > 0) {
          this._draggingFeatures = picks[0]?.properties?.editHandleType
            ? picks
            : selectedPipes;
          this._featuresBeforeDragging = [...this._pipelines];
        }
      }
    }
  };

  handleDragging = (event: any, props: any) => {
    const { isFocused, useCanvasPan, pipelineMode, referenceInchesPerPixels } =
      props.modeConfig;
    if (useCanvasPan || isFocused) {
      return;
    }

    const modeDetails = getPipelineModeDetails(pipelineMode);
    if (this._isDragging && modeDetails.isEditMode) {
      const isCommandOrAlt =
        event.sourceEvent.metaKey || event.sourceEvent.altKey;
      const isControlKey = event.sourceEvent.ctrlKey;
      const isShiftKey = event.sourceEvent.shiftKey;

      const isDraggingFeature =
        this._draggingFeatures.length > 0 &&
        this._draggingFeatures[0].geometry.type === GEOJSON_TYPES.LineString;

      const isDraggingHandle =
        this._draggingFeatures.length > 0 &&
        this._draggingFeatures[0].properties?.editHandleType;

      const dragginFeatures = [...this._draggingFeatures].map(
        (f: any) =>
          this._featuresBeforeDragging.find(
            (p: any) => p.properties.id === f.properties.id
          ) || f
      );

      if (isControlKey) {
        const distance = distance2d(
          event.mapCoords[0],
          event.mapCoords[1],
          event.pointerDownMapCoords[0],
          event.pointerDownMapCoords[1]
        );

        this._pipelines = this._pipelines.map((p: any) => {
          if (this._selection.has(p.properties.id)) {
            const feature = dragginFeatures.find(
              (df: any) => df.properties.id === p.properties.id
            );

            return updatePipelineHeight(
              feature,
              distance,
              referenceInchesPerPixels,
              isCommandOrAlt,
              isShiftKey
            );
          }

          return p;
        });

        return;
      }

      if (isDraggingFeature) {
        let targetCoord = event.mapCoords;
        let snapCoord: any = null;
        let snapCoordOrigin: any = null;

        if (isCommandOrAlt) {
          this._pipelines.forEach((f: any) => {
            if (this._selection.has(f.properties.id)) {
              const p0 = f.geometry.coordinates[0];
              const p1 = f.geometry.coordinates[1];

              const originFeature = dragginFeatures.find(
                (df: any) => df.properties.id === f.properties.id
              );

              const snapCoord1 = this._snapTargets.within(p0[0], p0[1], 500, 1);
              const snapCoord2 = this._snapTargets.within(p1[0], p1[1], 500, 1);

              if (snapCoord1?.length > 0) {
                if (
                  snapCoord1[0].properties.dist < snapCoord?.properties.dist ||
                  !snapCoord
                ) {
                  snapCoord = snapCoord1[0];
                  snapCoordOrigin = originFeature?.geometry?.coordinates[0];
                }
              }

              if (snapCoord2?.length > 0) {
                if (
                  snapCoord2[0].properties.dist < snapCoord?.properties.dist ||
                  !snapCoord
                ) {
                  snapCoord = snapCoord2[0];
                  snapCoordOrigin = originFeature?.geometry?.coordinates[1];
                }
              }
            }
          });
        }

        let diffX = targetCoord[0] - event.pointerDownMapCoords[0];
        let diffY = targetCoord[1] - event.pointerDownMapCoords[1];

        if (snapCoord && snapCoordOrigin) {
          diffX = snapCoord.geometry.coordinates[0] - snapCoordOrigin[0];
          diffY = snapCoord.geometry.coordinates[1] - snapCoordOrigin[1];
        }

        const newFeatures = translateFeatures(dragginFeatures, diffX, diffY);

        this._pipelines = this._pipelines.map((pipe: any) => {
          const found = newFeatures.find(
            (f: any) => f?.properties?.id === pipe?.properties?.id
          );

          if (found) {
            const pipelineWidth = getPipelineWidth(
              pipe.properties.pipelineWidth,
              referenceInchesPerPixels
            );
            const linePolygon = lineToPolygon(pipe, pipelineWidth, this._cache);

            if (linePolygon) {
              linePolygon.properties.id = found.properties.id;
            }

            return roundFeatureCoords({
              ...pipe,
              geometry: found.geometry,
              properties: {
                ...found.properties,
                linePolygon,
              },
            });
          }

          return pipe;
        });
      }

      if (isDraggingHandle && dragginFeatures.length > 0) {
        const snapCoord = this._snapTargets.within(
          event.mapCoords[0],
          event.mapCoords[1],
          500,
          1
        );

        const feature = dragginFeatures[0];
        const handleFeature = this._draggingFeatures[0];

        const isShiftKey = event.sourceEvent.shiftKey;

        const targetCoord =
          isCommandOrAlt && snapCoord?.length > 0
            ? snapCoord[0].geometry.coordinates
            : event.mapCoords;

        const newFeature = roundFeatureCoords(
          updatePipeLineFeatureCoordinates(
            feature,
            handleFeature,
            targetCoord,
            referenceInchesPerPixels,
            isShiftKey
          )
        );

        if (newFeature) {
          this._pipelines = this._pipelines.map((pipe: any) =>
            pipe?.properties?.id === newFeature?.properties?.id
              ? roundFeatureCoords(newFeature)
              : pipe
          );
        }
      }
    }
  };

  handleStopDragging = (event: any, props: any) => {
    const mapCoords = event.mapCoords;
    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);
    const { referenceInchesPerPixels } = props.modeConfig;

    if (this._isDragging && modeDetails.isEditMode) {
      this._isDragging = false;

      this._snapTargets = null;
      this._draggingFeatures = [];
      this._featuresBeforeDragging = [];

      this._joints = generateJoints(
        this._pipelines,
        this._cache,
        referenceInchesPerPixels
      );
      this.generateMesh(referenceInchesPerPixels);
      this._joints = generateJoints(
        this._pipelines,
        this._cache,
        referenceInchesPerPixels
      );

      if (this._draggingStartCoords.length > 0) {
        const boxFeature = getTwoClickPolygon(
          this._draggingStartCoords,
          mapCoords
        );
        const intersections = this._pipelines.filter((p: any) =>
          booleanIntersects(p, boxFeature)
        );

        this._selection = new Set(
          intersections.map((p: any) => p.properties.id)
        );

        this._draggingStartCoords = [];
      }
    }
  };

  handleKeyDown = (event: any, props: any) => {
    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);
    const { referenceInchesPerPixels } = props.modeConfig;

    const ctrlOrCmd = event.metaKey || event.ctrlKey || event.altKey;

    if (event.key === KEYS.ENTER) {
      event.preventDefault();
      if (modeDetails.isPipelineMode && this._clickSequence.length > 0) {
        this.handleAddFeature(event, props);
      }
    }

    if (event.key === KEYS.BACKSPACE && modeDetails.isEditMode) {
      event.preventDefault();

      this._pipelines = this._pipelines.filter(
        (p: any) => !this._selection.has(p.properties.id)
      );
      this._selection = new Set();

      this.generateMesh(referenceInchesPerPixels);
      this._joints = generateJoints(
        this._pipelines,
        this._cache,
        referenceInchesPerPixels
      );
    }

    if (
      ctrlOrCmd &&
      (event.key === "a" || event.key === "A") &&
      modeDetails.isEditMode
    ) {
      event.preventDefault();
      event.stopPropagation();

      this._selection = new Set(
        this._pipelines.map((p: any) => p.properties.id)
      );
    }

    if (event.key === KEYS.BACKSPACE && modeDetails.isEntryMode) {
      event.preventDefault();
      this._pipelinesApi = [];
      this._arrowFeature = null;
      this._pipelineApiMesh = null;
    }

    if (event.key === KEYS.ESCAPE) {
      if (this._clickSequence.length > 0 && modeDetails.isPipelineMode) {
        event.preventDefault();
        event.stopPropagation();
        this._resetState();
      }

      if (
        this?._arrowFeature?.properties?._finalized === false &&
        modeDetails.isEntryMode
      ) {
        event.preventDefault();
        event.stopPropagation();
        this._arrowFeature = null;
        this._resetState();
      }
    }

    if (
      modeDetails.isPipelineMode &&
      (event.key === "z" ||
        event.key === "Z" ||
        (ctrlOrCmd && (event.key === "Z" || event.key === "z")))
    ) {
      event.preventDefault();
      const clickSequence = this.getClickSequence();

      if (clickSequence.length > 1) {
        this.setClickSequence(clickSequence.slice(0, -1));
      } else {
        this._resetState();
      }
    }

    if (event.key === "p" || event.key === "P") {
      event.preventDefault();
      event.stopPropagation();
    }

    if (
      modeDetails.isPipelineMode &&
      (event.key === "h" || event.key === "H")
    ) {
      event.preventDefault();
      event.stopPropagation();

      this._isHeightMode = !this._isHeightMode;
    }

    if (modeDetails.isEditMode && event.key === KEYS.ARROWUP) {
      event.preventDefault();
      event.stopPropagation();

      this._pipelines = this._pipelines.map((p: any) => {
        if (this._selection.has(p.properties.id)) {
          const currentPipelineWidth = p.properties.pipelineWidth;
          const index = PIPELINE_WIDTHS.indexOf(currentPipelineWidth);
          const newPipelineWidthIndes =
            index < PIPELINE_WIDTHS.length - 1 ? index + 1 : 0;
          const newPipelineWidth = PIPELINE_WIDTHS[newPipelineWidthIndes];

          const pipelineWidth = getPipelineWidth(
            newPipelineWidth,
            referenceInchesPerPixels
          );
          const linePolygon = lineToPolygon(p, pipelineWidth, this._cache);

          if (linePolygon) {
            linePolygon.properties.id = p.properties.id;
          }

          return roundFeatureCoords({
            ...p,
            properties: {
              ...p.properties,
              pipelineWidth: newPipelineWidth,
              linePolygon,
            },
          });
        }

        return p;
      });

      this.generateMesh(referenceInchesPerPixels);
    }

    if (modeDetails.isEditMode && event.key === KEYS.ARROWDOWN) {
      event.preventDefault();
      event.stopPropagation();

      this._pipelines = this._pipelines.map((p: any) => {
        if (this._selection.has(p.properties.id)) {
          const currentPipelineWidth = p.properties.pipelineWidth;
          const index = PIPELINE_WIDTHS.indexOf(currentPipelineWidth);
          const newPipelineWidthIndes =
            index > 0 ? index - 1 : PIPELINE_WIDTHS.length - 1;
          const newPipelineWidth = PIPELINE_WIDTHS[newPipelineWidthIndes];

          const pipelineWidth = getPipelineWidth(
            newPipelineWidth,
            referenceInchesPerPixels
          );
          const linePolygon = lineToPolygon(p, pipelineWidth, this._cache);

          if (linePolygon) {
            linePolygon.properties.id = p.properties.id;
          }

          return roundFeatureCoords({
            ...p,
            properties: {
              ...p.properties,
              pipelineWidth: newPipelineWidth,
              linePolygon,
            },
          });
        }

        return p;
      });

      this.generateMesh(referenceInchesPerPixels);
    }

    if (modeDetails.isPipelineMode && event.key === KEYS.ARROWUP) {
      event.preventDefault();
      event.stopPropagation();

      const index = PIPELINE_WIDTHS.indexOf(this._pipelineWidth);
      const newPipelineWidthIndes =
        index < PIPELINE_WIDTHS.length - 1 ? index + 1 : 0;
      this._pipelineWidth = PIPELINE_WIDTHS[newPipelineWidthIndes];
    }

    if (modeDetails.isPipelineMode && event.key === KEYS.ARROWDOWN) {
      event.preventDefault();
      event.stopPropagation();

      const index = PIPELINE_WIDTHS.indexOf(this._pipelineWidth);
      const newPipelineWidthIndes =
        index > 0 ? index - 1 : PIPELINE_WIDTHS.length - 1;
      this._pipelineWidth = PIPELINE_WIDTHS[newPipelineWidthIndes];
    }

    if (event.key === "w" || event.key === "W") {
      this._isWKeyDown = true;
    }

    if (event.key === "x" || event.key === "X") {
      this._isXKeyDown = true;
    }
  };

  handleKeyUp = (event: any, props: any) => {
    if (event.key === "w" || event.key === "W") {
      this._isWKeyDown = false;
    }

    if (event.key === "x" || event.key === "X") {
      this._isXKeyDown = false;
    }
  };

  handlePointerMove = (event: any, props: any) => {
    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);

    if (modeDetails.isPipelineMode || modeDetails.isDrainMode) {
      props.onUpdateCursor("crosshair");
    } else {
      props.onUpdateCursor("default");
    }
  };
}
