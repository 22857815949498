import bbox from "@turf/bbox";
import bboxPolygon from "@turf/bbox-polygon";

import {
  GEOJSON_TYPES,
  DEFAULT_COLOR,
  DEFAULT_AREA_ID,
  DOORS_POLYGON_ID,
  DEFAULT_REGION_ID,
  DEFAULT_ROOM_ID_ML,
  DEFAULT_COLOR_DARK,
  DEFAULT_REGION_ID_ML,
  DEFAULT_FOOTPRINT_ID,
} from "../../consts/editor";
import {
  scaleFeature,
  getMagicFeatures,
  deconstructShape,
  getBufferedGeometry,
  getCleanedFootPrint,
  getNegativeFootprint,
  getMagicToolInfoTooltip,
} from "./utils";
import { CANVAS } from "../../consts/events";
import GeoJsonEditMode from "../base/GeojsonEditMode";
import { calculateDistance } from "../../utils/coordinates";
import { getEditHandlesForFeature } from "../../utils/modes";

export class MagicToolMode extends GeoJsonEditMode {
  _pointFeatures: any = [];
  _features: any = [];

  _rooms: any = [];
  _doors: any = [];
  _regions: any = [];
  _footprint: any = null;
  _oldFeatures: any = [];
  _featureIndex: number = 1;
  _featuresResult: any = null;
  _negativeFootprint: any = [];
  _simplifiedFeature: any = null;
  _simplificationFactor: number = 0.02;

  _debugFeatures: any = [];

  _isDragging: boolean = false;
  _isDataReady: boolean = false;
  _isPreparingData: boolean = false;

  constructor() {
    super();

    this._oldFeatures = [];
  }

  _resetState = () => {
    this.resetClickSequence();

    this._debugFeatures = [];
    this._pointFeatures = [];
    this._simplifiedFeature = null;
    this._simplificationFactor = 0.02;

    this._featuresResult = null;
  };

  _prepareData = (props: any) => {
    this._isPreparingData = true;

    const page = props.modeConfig.view.page;
    const rooms = (page?.geojson?.features || []).filter((fe: any) =>
      fe.properties.types.includes(DEFAULT_ROOM_ID_ML)
    );
    const regions = (page?.geojson?.features || []).filter(
      (fe: any) =>
        fe.properties.types.includes(DEFAULT_REGION_ID_ML) &&
        !fe.properties.types.includes(DOORS_POLYGON_ID) &&
        !fe.properties.types.includes(DEFAULT_FOOTPRINT_ID)
    );

    const doors = (page?.geojson?.features || []).filter((fe: any) =>
      fe.properties.types.includes(DOORS_POLYGON_ID)
    );
    const footprint = (page?.geojson?.features || []).find((fe: any) =>
      fe.properties.types.includes(DEFAULT_FOOTPRINT_ID)
    );

    let negativeRooms = [];
    let negativeRegions = [];

    if (footprint) {
      const { cleanedFootprint, holes } = getCleanedFootPrint(footprint);

      const footprintBBox = bboxPolygon(bbox(cleanedFootprint));
      footprintBBox.properties.color = [120, 120, 120];
      this._footprint = footprintBBox;

      if (holes.length > 0) {
        const scaledDownHoles = holes.map((hole: any) => {
          try {
            const scaled = scaleFeature(hole, 0.95);
            return scaled;
          } catch (e) {
            return hole;
          }
        });

        negativeRooms = holes;
        negativeRegions = scaledDownHoles;
      } else {
        if (rooms?.length > 0) {
          try {
            const negativeFootprint = getNegativeFootprint(
              props,
              footprint,
              rooms
            );
            if (negativeFootprint) {
              negativeRooms = negativeFootprint;
              const scaledDownRooms = negativeFootprint.map((hole: any) => {
                try {
                  const scaled = scaleFeature(hole, 0.95);
                  return scaled;
                } catch (e) {
                  return hole;
                }
              });
              negativeRegions = scaledDownRooms;
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
    }

    this._doors = doors;
    this._rooms = [...rooms, ...negativeRooms];
    this._regions = [...regions, ...negativeRegions];

    this._isDataReady = true;
  };

  _prepareResults = () => {
    const result = getMagicFeatures(
      this._pointFeatures,
      this._rooms,
      this._regions
    );
    this._simplifiedFeature = null;
    this._simplificationFactor = 0.02;

    this._featuresResult = result;
  };

  dispatchEvent = (newState: number) => {
    const event = new CustomEvent(CANVAS.INTERACTIVE_MODE_CHANGE, {
      detail: {
        mode: newState === 0 ? 2 : 1,
      },
    });

    window.dispatchEvent(event);
  };

  getGuides = (props: any) => {
    const color = props?.modeConfig?.activeClassification
      ? props?.modeConfig?.activeClassification?.colorStyle?.color
      : this._featureIndex === 0
      ? DEFAULT_COLOR_DARK.color
      : DEFAULT_COLOR.color;

    if (!this._isDataReady && !this._isPreparingData) {
      this._prepareData(props);
    }

    const clickSequence = this.getClickSequence();
    const guides: any = {
      type: GEOJSON_TYPES.FeatureCollection,
      features: [],
    };

    if (this._footprint) {
      guides.features.push(this._footprint);
    }

    if (this._oldFeatures.length > 0) {
      guides.features = guides.features.concat(this._oldFeatures);
    }

    if (!!this._simplifiedFeature) {
      guides.features.push({
        ...this._simplifiedFeature,
        properties: {
          ...this._simplifiedFeature.properties,
          color,
        },
      });

      const handles = getEditHandlesForFeature(
        this._simplifiedFeature,
        0,
        "magic_handle"
      );
      guides.features = guides.features.concat(handles);
    } else {
      if (this?._featuresResult?.mergedFeatures?.length > 0) {
        const displayFeature =
          this._featuresResult.mergedFeatures[this._featureIndex];
        guides.features.push({
          ...displayFeature,
          properties: {
            ...displayFeature.properties,
            color,
          },
        });

        const handles = getEditHandlesForFeature(
          displayFeature,
          0,
          "magic_handle"
        );

        guides.features = guides.features.concat(handles);
      }
    }

    if (clickSequence.length > 0) {
      const lineFeature = {
        type: GEOJSON_TYPES.Feature,
        geometry: {
          type: GEOJSON_TYPES.LineString,
          coordinates: clickSequence,
        },
        properties: {
          color: [255, 0, 0],
          types: ["line"],
        },
      };
      guides.features.push(lineFeature);
    }

    guides.features = guides.features.concat(this._pointFeatures);
    return guides;
  };

  getTooltips = () => {
    const infoTooltip: any = getMagicToolInfoTooltip(this._footprint);
    return [...(infoTooltip || [])];
  };

  handleStartDragging = (event: any, props: any) => {
    const { isFocused, useCanvasPan } = props.modeConfig;
    if (isFocused || useCanvasPan) {
      return;
    }

    this._isDragging = true;
    const isShiftKey = event?.sourceEvent?.shiftKey;

    if (!isShiftKey) {
      this._resetState();
    } else {
      this.resetClickSequence();
      this._debugFeatures = [];
      this._featuresResult = null;
      this._simplifiedFeature = null;
      this._simplificationFactor = 0.02;
    }

    const mapCoords = event.mapCoords;
    this.addClickSequence({
      mapCoords,
    });
  };

  handleDragging = (event: any, props: any) => {
    if (!this._isDragging) return;

    const mapCoords = event.mapCoords;
    const clickSequence = this.getClickSequence();

    if (clickSequence.length > 0) {
      const lastCoords = clickSequence[clickSequence.length - 1];

      const distance = calculateDistance(mapCoords, lastCoords);

      if (distance > 10) {
        this.addClickSequence({
          mapCoords,
        });
      }
    }
  };

  handleStopDragging = (_event: any, props: any) => {
    const clickSequence = this.getClickSequence();

    if (this._isDragging && clickSequence.length > 1) {
      const lineFeature = {
        type: GEOJSON_TYPES.Feature,
        geometry: {
          type: GEOJSON_TYPES.LineString,
          coordinates: clickSequence,
        },
        properties: {
          color: [255, 0, 0],
          types: ["line"],
        },
      };
      const bufferedLine = getBufferedGeometry(lineFeature, 0.1);
      this._pointFeatures.push(bufferedLine);
      this._prepareResults();
    }

    this.resetClickSequence();
    this._isDragging = false;
  };

  handleDblClick = (event: any, props: any) => {
    const mapCoords = event.mapCoords;
    if (!this._footprint) return;

    const isShiftKey = event?.sourceEvent?.shiftKey;
    const featuresBbox = this._footprint.bbox;

    if (!isShiftKey) {
      this._resetState();
    }

    if (
      mapCoords[0] < featuresBbox[0] ||
      mapCoords[0] > featuresBbox[2] ||
      mapCoords[1] < featuresBbox[1] ||
      mapCoords[1] > featuresBbox[3]
    ) {
      console.error("click outside the footprint");
      return;
    }

    const pointFeature = {
      type: GEOJSON_TYPES.Feature,
      geometry: {
        type: GEOJSON_TYPES.Point,
        coordinates: [mapCoords[0], mapCoords[1]],
      },
      properties: {
        types: ["point"],
        color: [255, 0, 0],
      },
    };
    const bufferedPoint = getBufferedGeometry(pointFeature, 0.5);

    this._pointFeatures.push(bufferedPoint);

    this._prepareResults();
  };

  handleClick = (event: any, props: any) => {};

  handleKeyDown = (event: any, props: any) => {
    if (
      event.key === "Escape" &&
      this._featuresResult?.mergedFeatures?.length > 0
    ) {
      event.preventDefault();
      event.stopPropagation();
      this._resetState();
    }

    if (event.key === "Enter") {
      event.preventDefault();
      this.handleAddFeature(event, props);
    }

    if (event.key === "r" || event.key === "R") {
      event.preventDefault();
      event.stopPropagation();

      const shape = this._featuresResult?.mergedFeatures[this._featureIndex];
      if (shape) {
        this._simplifiedFeature = shape;
        this._simplificationFactor = 0.02;
      }
    }

    if (event.key === "s" || event.key === "S") {
      event.preventDefault();
      event.stopPropagation();

      const shape = this._featuresResult?.mergedFeatures[this._featureIndex];
      if (shape) {
        const simplified = deconstructShape(shape, this._simplificationFactor);
        if (simplified.length > 0) {
          this._simplifiedFeature = simplified[0];
          this._simplificationFactor = this._simplificationFactor * 1.5;
        }
      }
    }

    if (event.key === "d" || event.key === "D") {
      event.preventDefault();
      event.stopPropagation();
      if (!!this._simplifiedFeature) {
        const simplified = deconstructShape(this._simplifiedFeature);
        if (simplified.length > 0) {
          this._simplifiedFeature = simplified[0];
        }
      }
    }

    if (event.key === "n" || event.key === "N") {
      event.preventDefault();
      event.stopPropagation();

      if (this._featureIndex !== 1) {
        this._featureIndex = 1;
        this._simplifiedFeature = null;
        this._simplificationFactor = 0.02;
        this.dispatchEvent(this._featureIndex);
      }
    }

    if (event.key === "g" || event.key === "G") {
      event.preventDefault();
      event.stopPropagation();

      if (this._featureIndex !== 0) {
        this._featureIndex = 0;
        this._simplifiedFeature = null;
        this._simplificationFactor = 0.02;
        this.dispatchEvent(this._featureIndex);
      }
    }

    if (event.key === "Tab") {
      event.preventDefault();
      event.stopPropagation();

      this._featureIndex = this._featureIndex === 1 ? 0 : 1;

      this._simplifiedFeature = null;
      this._simplificationFactor = 0.02;
      this.dispatchEvent(this._featureIndex);
    }
  };

  handleKeyUp = (event: any, props: any) => {};

  handleInteractiveModeChange = (event: any, props: any) => {
    if (event.detail === 2) {
      this._featureIndex = 0;
    }
    if (event.detail === 1) {
      this._featureIndex = 1;
    }
  };

  handleAddFeature = (event: any, props: any) => {
    const { activeClassification } = props.modeConfig;
    const feature =
      this._simplifiedFeature ||
      this._featuresResult?.mergedFeatures[this._featureIndex];

    const featureClassification = activeClassification
      ? activeClassification?.id
      : this._featureIndex === 0
      ? DEFAULT_AREA_ID
      : DEFAULT_REGION_ID;

    const polygonFeatures = this.getAddFeatureOrBooleanPolygonAction(
      feature,
      props,
      featureClassification,
      null
    );

    if (polygonFeatures?.updatedData?.features?.length > 0) {
      this.addFeatures(polygonFeatures.updatedData.features);
      this.applyActions(props, true);
    }

    this._resetState();
  };

  handlePointerMove = (event: any, props: any) => {
    if (this._isDragging) {
      props.onUpdateCursor("crosshair");
    }
  };
}
