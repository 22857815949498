import request from "../request";

export async function fetchSymbolsHistory({ id }) {
  const { body } = await request.get(`symbol`, {
    query: { $where: { project_id: id }, $limit: 36 },
  });
  return body;
}

export async function fetchSymbol(vector, bounding_box) {
  const { body } = await request.post(`symbol`, {
    body: {
      bounding_box,
      view_id: vector.id,
    },
  });
  return body;
}

export async function deleteSymbolsHistory(ids) {
  const params = new URLSearchParams();
  ids.forEach((id) => params.append("id", id));
  const queryString = `?${params.toString()}`;
  const { body } = await request.delete(`symbol${queryString}`);
  return body;
}

export async function deleteAllSymbolsHistory(id) {
  const { body } = await request.delete(`symbol?project_id=${id}`);
  return body;
}

export async function getSymbolSearchResults({
  symbolSearchAttemptId,
  next_page = null,
}) {
  const { limit, offset } = next_page ?? {};

  const { body } = await request.get(`symbol_search`, {
    query: {
      $where: {
        symbol_search_attempt_id: symbolSearchAttemptId,
      },
      $order: ["created", "asc"],
      ...(limit != null && { $limit: limit }),
      ...(offset != null && { $offset: offset }),
    },
  });
  return body;
}
