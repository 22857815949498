import localforage from "localforage";

import {
  getPageNameFromPageObject,
  getPageNumberFromPageObject,
  getFinalPageNameFromPageObject,
} from "sf/utils/page";
import { sleep } from "sf/utils/function";
import { hashString } from "sf/utils/style";
import { generateId } from "sf/utils/string";
import { GEOJSON_TYPES } from "sf/consts/editor";

import * as setApi from "src/lib/api/set";
import * as pageApi from "src/lib/api/page";
import * as viewApi from "src/lib/api/view";
import * as projectApi from "src/lib/api/project";
import * as organizationApi from "src/lib/api/organization";

import {
  idKey,
  setKey,
  pageKey,
  viewKey,
  projectKey,
  organizationKey,
  MAX_PARALLEL_QUERIES,
  DEFAULT_PAGINATION_LIMIT,
} from "./const";

import * as LFC from "./localForageUtils";
import { STORE_ADD } from "src/state/dataStore";
import { rotateFeatures } from "src/modules/Editor/utils";
import { prepareDataViews } from "src/modules/Editor/data";

const API = {
  [setKey]: setApi,
  [pageKey]: pageApi,
  [viewKey]: viewApi,
  [projectKey]: projectApi,
  [organizationKey]: organizationApi,
};

const SET_LOCAL_API = {
  [setKey]: LFC.setSetsIds,
  [pageKey]: LFC.setPagesIds,
  [viewKey]: LFC.setViewsIds,
  [projectKey]: LFC.setProjectIds,
  [organizationKey]: LFC.setOrgIds,
};

const GET_LOCAL_API = {
  [setKey]: LFC.getAllLocalSets,
  [pageKey]: LFC.getAllLocalPages,
  [viewKey]: LFC.getAllLocalViews,
  [projectKey]: LFC.getAllLocalProjects,
  [organizationKey]: LFC.getAllLocalOrgs,
};

const CLEAR_LOCAL_API = {
  [setKey]: LFC.clearAllLocalSets,
  [pageKey]: LFC.clearAllLocalPages,
  [viewKey]: LFC.clearAllLocalViews,
  [projectKey]: LFC.clearAllLocalProjects,
  [organizationKey]: LFC.clearAllLocalOrgs,
};

export function hashApi(key: string, apiProps: any) {
  return key + hashString(JSON.stringify(apiProps));
}

export async function listDataIds(
  key: string,
  apiProps = {}
): Promise<Set<number>> {
  const api = API[key];

  const { rows } = await api.list({
    $offset: 0,
    $limit: 10000,
    ...apiProps,
    $attributes: ["id"],
  });

  return new Set(rows?.map((item: any) => item.id));
}

export async function pushAction(
  pendingData: React.MutableRefObject<any[]>,
  action: any
) {
  let success = false;

  while (!success) {
    try {
      pendingData.current.push(action);
      success = true;
    } catch (error) {
      // adding a small delay to avoid very close calls
      await sleep(10);
    }
  }
}

/**
 *
 *
 * GENERIC FUNCTION TO GET DATA FROM LOCAL STORAGE
 *
 *
 */

interface IGetLocalListProps {
  key: string;
  localApiProps?: any;
  cleanFuncProps?: any;
  cleanFunc?: (data: any[], props: any) => any[];
  pendingData?: React.MutableRefObject<any[]>; // Optional
}

interface IGetLocalListReturn {
  data: any[];
  error: any;
}

export async function getLocalList({
  key,
  cleanFunc,
  cleanFuncProps,
  localApiProps = {},
}: IGetLocalListProps): Promise<IGetLocalListReturn> {
  const getLocal = GET_LOCAL_API[key];

  try {
    let data = await getLocal(localApiProps);

    if (Array.isArray(data) && data.length > 0) {
      if (cleanFunc) {
        data = data.map((d) => cleanFunc(d, cleanFuncProps));
      }
      return { data, error: null };
    }

    return { data: [], error: null };
  } catch (error) {
    return { data: [], error };
  }
}

/**
 *
 *
 * GENERIC FUNCTION TO GET DATA FROM API
 *
 *
 */

interface IGetDataProps extends IGetLocalListProps {
  apiProps?: any;
  offset?: number;
  ignoreLocal?: boolean;
  localDataCount?: number;
  filterValue?: string | null;

  runId?: string;
  currentRunId?: React.MutableRefObject<string | null>;
}

interface IGetDataReturn extends IGetLocalListReturn {
  next: any;
  count: number;
  local?: boolean;
  requestedSync?: boolean;
}

interface IGetAPIDataProps {
  api: any;
  apiProps: any;
  dataKey: string;
  cleanFuncProps: any;
  filterValue?: string | null;
  pendingData: React.MutableRefObject<any[]>;
  cleanFunc: (data: any[], props: any) => any[];

  runId?: string;
  currentRunId?: React.MutableRefObject<string | null>;
}

async function getApiData({
  api,
  apiProps,
  dataKey,
  cleanFunc,
  filterValue,

  pendingData,
  cleanFuncProps,

  runId,
  currentRunId,
}: IGetAPIDataProps) {
  const res = await api.list(apiProps);

  const rows = cleanFunc
    ? (res.rows || []).map((d: any) => cleanFunc(d, cleanFuncProps))
    : res.rows;

  if (runId === currentRunId?.current && rows.length > 0) {
    await pushAction(pendingData, {
      dataKey,
      data: rows,
      filterValue,
      action: STORE_ADD,
    });
  }

  return {
    ...res,
    rows,
  };
}

export async function getList({
  key,
  offset = 0,
  ignoreLocal = false,

  pendingData,
  apiProps = {},
  localApiProps = {},
  localDataCount = 0,

  runId,
  currentRunId,

  filterValue,
  cleanFunc = null,
  cleanFuncProps = null,
}: IGetDataProps): Promise<IGetDataReturn> {
  const api = API[key];

  const setLocal = SET_LOCAL_API[key];
  const getLocal = GET_LOCAL_API[key];
  const clearLocal = CLEAR_LOCAL_API[key];

  let count = 0;
  let data: any[] = [];
  let next: any = null;
  let error: any = null;
  let shouldSyncAll = false;
  let requestedSync = false;

  const syncingKey = hashApi(key, apiProps);
  const syncValue = await LFC.getSyncingValue(syncingKey);

  if (!syncValue) {
    shouldSyncAll = true;
  } else if (!ignoreLocal && getLocal) {
    const ids = (await listDataIds(key, apiProps)) || new Set();
    const totalSData = ids.size; // total data in the server

    if (localDataCount === 0) {
      data = (await getLocal(localApiProps)) || [];

      if (data.length > 0 && data?.length === totalSData) {
        if (cleanFunc) {
          data = data.map((d: any) => cleanFunc(d, cleanFuncProps));
        }
        return {
          data,
          next,
          error: null,
          local: true,
          requestedSync,
          count: data?.length || 0,
        };
      }
    } else if (localDataCount === totalSData) {
      return {
        data,
        next,
        error: null,
        local: true,
        requestedSync,
        count: data?.length || 0,
      };
    } else {
      clearLocal(localApiProps);
      await LFC.clearSyncingValue(syncingKey);
    }
  }

  if (shouldSyncAll && !ignoreLocal && clearLocal) {
    await LFC.setSyncingValue(syncingKey);
    await clearLocal(localApiProps);
  }

  try {
    const promises = Array.from({ length: MAX_PARALLEL_QUERIES }, (_, i) =>
      getApiData({
        api,
        runId,
        filterValue,
        currentRunId,
        pendingData,
        dataKey: key,
        apiProps: {
          $limit: DEFAULT_PAGINATION_LIMIT,
          $offset: offset + i * DEFAULT_PAGINATION_LIMIT,
          ...apiProps,
        },
        cleanFunc,
        cleanFuncProps,
      })
    );

    const results = await Promise.all(promises);

    results.forEach((res: any) => {
      if (res?.rows?.length > 0) {
        count = res.count;
        for (let i = 0; i < res.rows.length; i++) {
          data.push(res.rows[i]);
        }

        if (
          res.next_page &&
          (!next || (next.offset && res.next_page.offset > next.offset))
        ) {
          next = res.next_page;
        }
      }
    });

    const allResultsHaveNext = results.every((res: any) => res?.next_page);
    if (!allResultsHaveNext && next) {
      next = null;
    }

    if (setLocal && data.length > 0) {
      await setLocal(data, localApiProps);
    }
  } catch (e) {
    error = e;
  }

  return {
    data,
    count,
    error,
    next,
    local: false,
  };
}

/**
 *
 *
 * GENERIC FUNCTION TO SYNC DATA FROM API
 *
 *
 */

interface ISyncListProps extends IGetLocalListProps {
  apiProps?: any;
  localApiProps?: any;
  filterValue?: string | null;

  runId?: string;
  currentRunId?: React.MutableRefObject<string | null>;
}

interface ISyncListReturn {
  error: any;
  data: any[];
  ids: Set<number>;
}

export async function syncList({
  key,
  pendingData,
  filterValue,
  apiProps = {},
  localApiProps = {},

  cleanFunc = null,
  cleanFuncProps = null,

  runId,
  currentRunId,
}: ISyncListProps): Promise<ISyncListReturn | null> {
  const api = API[key];
  const setLocal = SET_LOCAL_API[key];

  const syncingKey = hashApi(key, apiProps);
  const syncValue = await LFC.getSyncingValue(syncingKey);

  if (syncValue) {
    const ids = (await listDataIds(key, apiProps)) || new Set();

    const totalData = ids.size;

    let data: any[] = [];
    let offset = 0;
    let hasMoreData = true;

    try {
      while (hasMoreData) {
        const promises = Array.from({ length: MAX_PARALLEL_QUERIES }, (_, i) =>
          offset + i * DEFAULT_PAGINATION_LIMIT < totalData
            ? getApiData({
                api,
                runId,
                filterValue,
                pendingData,
                dataKey: key,
                currentRunId,
                apiProps: {
                  $limit: DEFAULT_PAGINATION_LIMIT,
                  $offset: offset + i * DEFAULT_PAGINATION_LIMIT,
                  ...apiProps,
                  $where: {
                    ...(apiProps?.$where || {}),
                    updated: { $gte: syncValue },
                  },
                },
                cleanFunc,
                cleanFuncProps,
              })
            : null
        );

        const results = await Promise.all(promises);

        for (const res of results) {
          if (res?.rows?.length) {
            for (let i = 0; i < res.rows.length; i++) {
              data.push(res.rows[i]);
            }
          }
        }

        hasMoreData = results.every((res: any) => res?.next_page);

        if (hasMoreData) {
          offset += MAX_PARALLEL_QUERIES * DEFAULT_PAGINATION_LIMIT;
        }
      }

      if (data.length > 0) {
        await LFC.setSyncingValue(syncingKey);
        await setLocal(data, localApiProps);
      }

      return {
        ids,
        data,
        error: null,
      };
    } catch (error) {
      return {
        error,
        data: [],
        ids: new Set<number>(),
      };
    }
  }
  return null;
}

/**
 *
 *
 * GENERIC FUNCTION TO SYNC SINGLE DATA ITEM FROM API
 *
 *
 */

interface SyncSingleItemProps {
  key: string;
  newValue?: any;
  item?: Partial<any>;
  id?: number | string | null;
  apiProps?: Record<string, any>;

  isAdd?: boolean;
  isRemove?: boolean;
  isLocalOnly?: boolean;

  cleanFunc?: (item: any, props?: any) => any;
  cleanFuncProps?: any;
}

export async function syncItem({
  key,
  id = null,
  item = {},
  apiProps = {},
  isAdd = false,
  isRemove = false,
  isLocalOnly = false,
  newValue,
  cleanFunc,
  cleanFuncProps,
}: SyncSingleItemProps): Promise<any | null> {
  const api = API[key];
  let newItem: any | null = null;

  try {
    let existingItem: any = item.id
      ? item
      : {
          ...((await LFC.getLocalItem(key, id)) || {}),
          ...item,
        };

    if ((isAdd || isRemove) && !isLocalOnly) {
      try {
        const res = await api.get({
          id,
          query: {
            ...apiProps,
          },
        });
        existingItem = { ...existingItem, ...res };
      } catch (error) {
        // Optional: handle error
      }
    }

    if (cleanFunc) {
      existingItem = cleanFunc(existingItem, cleanFuncProps);
    }

    if (!isRemove && existingItem) {
      await LFC.setLocalItem(key, existingItem, true);
      newItem = existingItem;
    }

    if (isAdd || isRemove) {
      let keys: any[] = (await LFC.getLocalItem(key, idKey)) || [];

      if (isAdd && newValue && keys) {
        const found = keys.find(
          (i: any) =>
            i?.id === (typeof newValue === "object" ? newValue.id : newValue)
        );

        if (!found) {
          keys.push(newValue);
        } else {
          keys = keys.map((i: any) =>
            i?.id === (typeof newValue === "object" ? newValue.id : newValue)
              ? newValue
              : i
          );
        }
      }

      if (isRemove && id) {
        await LFC.removeItem(key, id);
        keys = keys.filter((k: any) => k.id !== id);
        newItem = existingItem;
      }

      try {
        await localforage.setItem(key + idKey, keys);
      } catch (error) {
        // Optional: handle error
      }
    }

    return newItem;
  } catch (e) {
    console.error("syncSingle Item : ", e);
    return null;
  }
}

/**
 *
 *
 * STORE SPECIFIC CLEANING FUNCTIONS
 *
 *
 */

export function scaleUpFeature(feature: any, vector: any) {
  if (!feature?.geometry?.coordinates) return null;

  try {
    const xFactor = Number(vector?.url_width || 1);
    const yFactor = Number(vector.url_height || 1);

    let updatedFeature = {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: feature.geometry.coordinates.map(function scaleFeature(
          coordinate: any
        ) {
          if (Array.isArray(coordinate[0])) {
            return coordinate.map(scaleFeature);
          }

          const updatedX = Math.round(coordinate[0] * xFactor);
          const updatedY = Math.round(coordinate[1] * yFactor);

          return [updatedX, updatedY];
        }),
      },
      properties: {
        ...feature.properties,
        area: 0,
        name: "",
        perimeter: 0,
        suggestion: null,
        className: "text",
        types: ["number", "RECT", "MANUAL"],
        id: feature?.properties?.id || generateId(),
      },
    };

    updatedFeature.geometry.coordinates = [
      [
        ...updatedFeature.geometry.coordinates[0],
        updatedFeature.geometry.coordinates[0][0],
      ],
    ];

    return updatedFeature;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function cleanPageData(page: any, _props: any) {
  const geojson_text_cleaned =
    page?.geojson_text_cleaned?.features?.length ||
    page?.geojson_text?.features?.length
      ? {
          type: GEOJSON_TYPES.FeatureCollection,
          features: rotateFeatures(
            page,
            (page?.geojson_text?.features || [])
              .filter((feature: any) => feature?.geometry?.coordinates?.length)
              .map((feature: any) => scaleUpFeature(feature, page) || [])
              .filter(Boolean),
            page?.metadata?.rotateRad || 0
          ),
        }
      : null;

  return {
    ...page,
    geojson_text_cleaned,
    pageName: getPageNameFromPageObject(page),
    finalName: getFinalPageNameFromPageObject(page),
    pageNumber: getPageNumberFromPageObject(page) || "",
  };
}

export function cleanViewData(view: any, { set = null }: any) {
  if (!set?.id || !view?.id) return view;

  const pagesMap = new Map((set?.pages || []).map((p: any) => [p.id, p]));

  const cleanedView = prepareDataViews(
    {
      ...view,
      page: pagesMap.has(view?.page_id) ? pagesMap.get(view?.page_id) : null,
    },
    set?.classification || [],
    0,
    false,
    set?.classification_order || []
  );

  return cleanedView;
}
