import React from "react";
import config from "src/config";

import HubSpot from "src/modules/App/components/ThirdParty/HubSpot";
import { useAuth } from "src/modules/Auth/context";

export default function ThirdParty() {
  const { env } = config;
  const { isMobile } = useAuth();

  if (isMobile) return null;

  return (
    <>
      {env !== "local" && <HubSpot />}
    </>
  );
}
