export const DEFAULT_PADDING = 10000;

// every 45 degrees
export const SNAP_ANGLES = new Array(17).fill(0).map((i, j) => j * 45 - 360);
// every 22.5 degrees
export const SNAP_ANGLES_S = new Array(33)
  .fill(0)
  .map((i, j) => j * 22.5 - 360);

export const STRAIGHT_SNAP_ANGLES = new Array(9)
  .fill(0)
  .map((i, j) => j * 90 - 360);

export const DEFAULT_COMPARISON_TRANSFORMS = {
  translateX: 0,
  translateY: 0,
  rotateRad: 0,
  scale: 1,
  rotateTranslationX: 0,
  rotateTranslationY: 0,
};

export const ARC_MODE_CIRCLE = 0;
export const ARC_MODE_QUADRATIC = 1;
export const MODE_RECTANGLE = 2;
export const MODE_SPHERE = 3;

export const DEFAULT_ARC_SEGMENTS = 6;
