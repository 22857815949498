import turfBuffer from "@turf/buffer";
import turfDifference from "@turf/difference";
import turfLineSplit from "../../utils/turf/line-split";
import lineIntersect from "../../utils/turf/line-intersect";

import { Feature, Polygon, Geometry } from "geojson";

import {
  updateGeometryWithPadding,
  getRecursivePaddingFunction,
} from "../../utils/coordinates";
import { CommonDrawMode } from "./common-draw";
import { generateId } from "../../utils/string";
import { GEOJSON_TYPES } from "../../consts/editor";
import { validateGeometry } from "../../utils/editor";
import ImmutableLayersData, {
  makeFeature,
  updateFeatureArcs,
} from "../base/ImmutableLayersData";

const PADDING = 10000;

export class DrawSplitMode extends CommonDrawMode {
  handleAddFeature = (event: any, props: any) => {
    const clickSequence = this.getClickSequence();

    if (clickSequence.length > 1) {
      const lineFeature = {
        type: GEOJSON_TYPES.Feature,
        properties: {},
        geometry: {
          type: GEOJSON_TYPES.LineString,
          coordinates: clickSequence,
        },
      };
      const intersectFeatures = this.getIntersectFeatures(lineFeature, props);

      for (const feature of intersectFeatures) {
        if (
          feature.geometry.type === GEOJSON_TYPES.Polygon ||
          feature.geometry.type === GEOJSON_TYPES.MultiPolygon
        ) {
          try {
            this.splitPolygon(lineFeature, feature, props);
          } catch (e) {
            console.error("Error splitting polygon", e);
          }
        } else if (feature.geometry.type === GEOJSON_TYPES.LineString) {
          try {
            this.splitLine(lineFeature, feature, props);
          } catch (e) {
            console.error("Error splitting line", e);
          }
        } else if (feature.geometry.type === GEOJSON_TYPES.MultiLineString) {
          try {
            this.splitMultiLine(lineFeature, feature, props);
          } catch (e) {
            console.error("Error splitting line", e);
          }
        }
      }

      const deletedIds = new Set(
        this._deleteFeatures.map((f: any) => f.properties.id)
      );
      const newSelections = [
        ...props.data.features
          .map((fe: any) => fe.properties.id)
          .filter((id: any) => !deletedIds.has(id)),
        ...this._addFeatures.map((fe: any) => fe.properties.id),
      ];

      props.modeConfig.setSelected(newSelections);

      this._resetState();
      this.applyActions(props, true);
    }
  };

  getIntersectFeatures(lineFeature: any, props: any) {
    const tentativeFeature: any = updateGeometryWithPadding(
      PADDING,
      lineFeature
    );

    const selectedFeatures = props.data.features;

    const intersectedFeatures = selectedFeatures.filter((feature: any) => {
      const paddedFeature: any = updateGeometryWithPadding(PADDING, feature);
      const intersection = lineIntersect(tentativeFeature, paddedFeature);
      return intersection?.features?.length > 0;
    });

    return intersectedFeatures;
  }

  splitLine(tentativeFeature: any, selectedfeature: any, props: any): any {
    const modeConfig: any = props.modeConfig || {};
    let { colorMap, view, filteredtakeOffTypes } = modeConfig;

    tentativeFeature = updateGeometryWithPadding(PADDING, tentativeFeature);
    selectedfeature = updateGeometryWithPadding(PADDING, selectedfeature);

    const lineSplit = turfLineSplit(selectedfeature, tentativeFeature);
    if (lineSplit?.features?.length <= 1) return;

    const newFeatures: Feature<Polygon>[] = [];
    lineSplit?.features.forEach((feature: any) => {
      const updatedFeature = updateGeometryWithPadding(1 / PADDING, feature);

      newFeatures.push({
        type: GEOJSON_TYPES.Feature,
        properties: {},
        geometry: updatedFeature.geometry,
      });
    });

    newFeatures.forEach((fe) => {
      const newFeatureId = generateId();

      const newFeature = makeFeature({
        geometry: fe.geometry,
        id: newFeatureId,
        name: selectedfeature.properties.name,
        view,
        type:
          filteredtakeOffTypes.length === 1 ? filteredtakeOffTypes[0] : null,
        className: selectedfeature.properties.className,
        featureAccessKey: props.featureAccessKey,
        clMap: colorMap,
      });

      const cleandFeatures = updateFeatureArcs(
        [newFeature],
        selectedfeature,
        view,
        colorMap
      );
      this.addFeatures(cleandFeatures);
    });

    if (newFeatures.length > 0) {
      this.deleteFeatures([selectedfeature]);
    }
    return;
  }

  splitPolygon(
    tentativeFeature: Feature<Geometry>,
    selectedfeature: any,
    props: any
  ): any {
    const modeConfig: any = props.modeConfig || {};
    let {
      colorMap,
      gap = 0.1,
      units = "centimeters",
      view,
      filteredtakeOffTypes,
    } = modeConfig;

    if (gap === 0) {
      gap = 0.1;
      units = "centimeters";
    }

    let selectedGeometry = selectedfeature.geometry;
    selectedGeometry = {
      ...selectedGeometry,
      coordinates: selectedGeometry.coordinates.map(
        getRecursivePaddingFunction(PADDING)
      ),
    };

    tentativeFeature = updateGeometryWithPadding(PADDING, tentativeFeature);

    const buffer = turfBuffer(tentativeFeature, gap, { units });
    let updatedGeometry = turfDifference(selectedGeometry, buffer);
    if (!updatedGeometry) {
      console.warn("Canceling edit. Split Polygon erased");
      return null;
    }

    updatedGeometry = updateGeometryWithPadding(1 / PADDING, updatedGeometry);

    const newFeatures: Feature<Polygon>[] = [];

    const { coordinates } = updatedGeometry.geometry;

    coordinates.forEach((coord: any) => {
      newFeatures.push({
        type: GEOJSON_TYPES.Feature,
        properties: {},
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates: coord,
        },
      });
    });

    let isNewFeaturesValid = true;

    newFeatures.forEach((fe) => {
      const isValidFe = validateGeometry(
        fe.geometry.type,
        fe.geometry.coordinates
      );
      if (!isValidFe) {
        isNewFeaturesValid = false;
      }
    });

    if (!isNewFeaturesValid) return;

    newFeatures.forEach((fe) => {
      const newFeatureId = generateId();

      const newFeature = makeFeature({
        geometry: fe.geometry,
        id: newFeatureId,
        name: selectedfeature.properties.name,
        view,
        type:
          filteredtakeOffTypes.length === 1 ? filteredtakeOffTypes[0] : null,
        className: selectedfeature.properties.className,
        featureAccessKey: props.featureAccessKey,
        clMap: colorMap,
      });

      const cleandFeatures = updateFeatureArcs(
        [newFeature],
        selectedfeature,
        view,
        colorMap
      );
      this.addFeatures(cleandFeatures);
    });

    if (newFeatures?.length > 0) {
      this.deleteFeatures([selectedfeature]);
    }

    return;
  }

  splitMultiLine(tentativeFeature: any, selectedfeature: any, props: any): any {
    const modeConfig: any = props.modeConfig || {};
    let { colorMap, view, filteredtakeOffTypes } = modeConfig;

    tentativeFeature = updateGeometryWithPadding(PADDING, tentativeFeature);
    selectedfeature = updateGeometryWithPadding(PADDING, selectedfeature);

    const selectedFeatureAsLines = new ImmutableLayersData([selectedfeature])
      .makeIntoLines(view, colorMap)
      .getLayers();

    let newLines: any = [];
    const splitLines: any = [];

    for (const line of selectedFeatureAsLines) {
      const lineSplit = turfLineSplit(line, tentativeFeature);

      if (lineSplit?.features?.length > 1) {
        lineSplit?.features.forEach((feature: any, idx: number) => {
          const updatedFeature = updateGeometryWithPadding(
            1 / PADDING,
            feature
          );
          const updatedLine = {
            type: GEOJSON_TYPES.Feature,
            properties: {},
            geometry: updatedFeature.geometry,
          };
          if (idx === 0) {
            splitLines.push([...newLines, updatedLine]);
            newLines = [];
          }
          if (idx === 1) {
            newLines.push(updatedLine);
          }
        });
      } else {
        const updatedLine = updateGeometryWithPadding(1 / PADDING, line);
        newLines.push(updatedLine);
      }
    }
    if (newLines.length > 0) {
      splitLines.push(newLines);
    }

    if (splitLines?.length > 1) {
      splitLines.forEach((lines: any) => {
        if (lines.length > 0) {
          const newFeatureId = generateId();

          const newMultiLines = new ImmutableLayersData(lines)
            .makeIntoMultiLine(view, colorMap)
            .getLayers();

          if (newMultiLines.length > 0) {
            const newFeature = makeFeature({
              geometry: newMultiLines[0].geometry,
              id: newFeatureId,
              name: selectedfeature.properties.name,
              view,
              type:
                filteredtakeOffTypes.length === 1
                  ? filteredtakeOffTypes[0]
                  : null,
              className: selectedfeature.properties.className,
              featureAccessKey: props.featureAccessKey,
              clMap: colorMap,
            });

            this.addFeatures([newFeature]);
          }
        }
      });

      if (splitLines?.length > 0) {
        this.deleteFeatures([selectedfeature]);
      }

      return;
    }

    return;
  }
}
